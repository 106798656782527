import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import ArrowDown from "@mui/icons-material/ArrowDownward";
import PDF from "@mui/icons-material/PictureAsPdfOutlined";

export const PackageProductBulletin = () => {
  const { bulletinsPdf } = useSelector((state) => state.CommonStore);
  const downloadPDF = (pdfIndx) => {
    const pdfName = bulletinsPdf.find((pdf, index) => index === pdfIndx);
    const pdfUrl = pdfName.resources_path;
    window.open(pdfUrl, "_blank", "noopener,noreferrer");
  };
  if (!bulletinsPdf.length) {
    return null;
  }

  return (
    <>
      {/* Second Row */}
      <Box
         className="productBulletin_main"
        sx={{
          // width: "678px",
          width: "50%",
          height:'auto',
          maxHeight: "570px",
        }}
      >
        {/* <Box
          sx={{
            borderRadius: "13px",
            border: "1px solid #ccc",
            // padding: "20px",
            backgroundColor: "#EAEAEA",
          }}
        >
          {/* Header Section /}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: "10px",
              marginLeft: "2%",
            }}
          >
            <Typography
              sx={{
                fontFamily: "DM Sans",
                fontWeight: 700,
                fontSize: "16px",
                color: "#55120B",
                paddingTop: "20px",
              }}
            >
              Product Bulletin
            </Typography>
          </Box> */}

        {/* Table Section */}
        <Box
          sx={{
            borderRadius: "18px",
            border: "1px solid #ccc",
            backgroundColor: "#EAEAEA",
            width: "100%",
            height:'auto',
            maxHeight: "500px",
            marginBottom:'10px',
            // marginTop: "20px",
            overflowX: "auto",
            overflowX: "hidden",
            "&::-webkit-scrollbar": {
              width: "3px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#EC1B24",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#D3D3D3",
            },
          }}
        >
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              tableLayout: "fixed",
            }}
          >
            <thead>
              {" "}
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 700,
                  fontSize: "16px",
                  color: "#55120B",
                  // paddingTop: "20px",
                  padding:'15px'
                }}
              >
                Product Bulletin
              </Typography>
            </thead>
            <tbody>
              {bulletinsPdf.map((pdf, index) => {
                const fileName = pdf.resources_path.split("/").pop();

                return (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#FAFAFA" : "#EAEAEA",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "10px",
                    }}
                  >
                    <td
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        fontFamily: "DM Sans",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#252525",
                      }}
                    >
                      <PDF
                        sx={{
                          width: "18.42px",
                          height: "17px",
                          color: "#EC1B24",
                          fontFamily: "DM Sans",
                        }}
                      />
                      {fileName}
                    </td>

                    {/* Dropdown Arrow */}
                    <td
                      style={{
                        textAlign: "right",
                        fontFamily: "DM Sans",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "20px",
                      }}
                    >
                      <ArrowDown
                        onClick={() => downloadPDF(index)}
                        sx={{
                          width: "18px",
                          height: "16px",
                          cursor: "pointer",
                          color: "#EC1B24",
                          "&:hover": {color:'#e30000', width:'25px', height:'22px' },

                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Box>
        {/* </Box> */}
      </Box>
      {/*  </Box> */}
    </>
  );
};
