import React, { useState } from "react";
import LayoutComponent from "../../Layout/LayoutComponent";
import CommonFooter from "../../Pages/Footer/CommonFooter";
import {
  Box,
  FormControl,
  FormLabel,
  Select,
  Typography,
  MenuItem,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import "../Post/Post.css";

export const Bulletins = () => {
  const [partNo, setPartNo] = useState("");
  const [selectedPartNoFile, setSelectedPartNoFile] = useState(null);
  const handleImageFileUpload = (event) => {
    const file = event.target.files[0];
    selectedPartNoFile(file ? file.name : null);
  };
  return (
    <Box
      className="bulletins-main-cont"
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 3,
        // justifyContent: "center",
        // alignItems: "center",
        // marginTop: "2%",
      }}
    >
      <Box
        className="part-no-tech-bulletins"
        sx={{ display: "flex", flexDirection: "row", width: "100%", gap: 6 }}
      >
        <Typography
          className="part-no-tech-bulletins-typo"
          sx={{
            width: "30%",
            fontSize: "16px",
            fontWeight: 500,
            fontFamily: "DM Sans",
          }}
        >
          Part No. To Technical Bulletins
        </Typography>
        <FormControl
          className="tech-bulletins-form-control"
          sx={{
            width: "50%",
            flexDirection: "row",
            gap: 6,
            alignItems: "center",
          }}
        >
          <Select
            className="tech-bulletins-select"
            value={partNo}
            onChange={(event) => setPartNo(event.target.value)}
            displayEmpty
            sx={{ width: "40%", height: "40px" }}
          >
            <MenuItem value="" disabled>
              --Select Part Number--
            </MenuItem>
            <MenuItem value="2019">2019</MenuItem>
            <MenuItem value="2018">2018</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* upload */}
      <Box
        className="part-no-file-upload"
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: 18,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 3,
          }}
        >
          {/* File Input */}
          <Button
            className="create-upload-btn"
            variant="contained"
            component="label"
            sx={{
              width: "120px",
              height: "30px !important",
              textTransform: "none",
              backgroundColor: "#d9d9d9",
              padding: "20px",
              color: "#000",
              fontFamily: "DM Sans",
              "&:hover": {
                backgroundColor: "darkgray",
              },
            }}
          >
            Browse
            <input type="file" hidden onChange={handleImageFileUpload} />
          </Button>
          {/* Message */}
          <Typography
            sx={{
              fontFamily: "DM Sans",
              fontSize: "18px",
              color: "#888",
            }}
          >
            {selectedPartNoFile ? selectedPartNoFile : "No file selected"}
          </Typography>
        </Box>
        <Button variant="contained" color="primary" sx={{ width: "80px", textTransform:'none' }}>
          Upload
        </Button>
      </Box>

      {/*  */}
      <Box
        className="bulletins-table-main-box"
        sx={{
          width: "96%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <TableContainer
          className="bulletins-table-container"
          component={Paper}
          sx={{ width: "70%", border: "2px solid #ccc" }}
        >
          <Table sx={{ minWidth: "90%" }} aria-label="bulletins-table">
            <TableHead>
              <TableRow>
                <TableCell className="bulletins-table-header-cells">
                  Resource
                </TableCell>
                <TableCell className="bulletins-table-header-cells">
                 Part Number
                </TableCell>
                <TableCell className="bulletins-table-header-cells">
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className="bulletins-table-row-cells" sx={{color:'red', textDecoration:'underline'}}>
                  42-23233
                </TableCell>
                <TableCell className="bulletins-table-row-cells">
                  428686-25078
                </TableCell>

                <TableCell>
                  <Button variant="contained" color="error" sx={{ textTransform:'none'}}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
