import { createSlice } from "@reduxjs/toolkit";

const CommonSliceInitialState = {
  selectedProduct: {},
  quallifierCollection: {},
  subModel: {},
  classicYearList: [],
  productList: [],
  Applicationcontent:[],
  radioValue:null,
  activeArticle:{},
  scrollAutomatic:false,
  scrollSamePage:false,
  faqScroll:false,
  selectedPart:{},
  productlistmessage:false,
  
  //product display
  application:{},
  productImages:{},
  interchanges:{},
  specification:{},
  bulletinsPdf:{},

  //year, make , model
  classicYearList:[],
  classicMakeList:[],
  classicModelList:[],
  classicEngineList:[],

  blog:[],

  //to store current selected values
  selectYear:"",
  selectMake:"",
  selectModel:"",
  selectEngine:"",
  selectSubModelVal:null,
  //common loader 
  isLoading:false,
};
const CommonSlice = createSlice({
  name: "Common",
  initialState: CommonSliceInitialState,
  reducers: {
    setAbortController(state, action) {
      state.abortController = action.payload;
    },
    setSelectedProduct(state, action) {
      state.selectedProduct = action.payload;
    },
    setQuallifierCollection(state, action) {
      state.quallifierCollection = action.payload;
    },
    setSubModel(state, action) {
      state.subModel = action.payload;
    },
    setClassicYearList(state, action) {
      state.classicYearList = action.payload;
    },
    setProductList(state, action) {
      state.productList = action.payload;
    },
    setApplicationcontent(state,action){
      state.Applicationcontent=action.payload
    },
    setRadioValue(state,action){
      state.radioValue=action.payload
    },
    
    //product display
    setApplication(state, action){
      state.application=action.payload;
    },
    setProductImages(state, action){
      state.productImages=action.payload;
    },
    setInterchange(state,action){
      state.interchanges=action.payload;
    },
    setSpecification(state, action){
      state.specification=action.payload;
    },
    setBulletinsPdf(state, action){
      state.bulletinsPdf=action.payload;
    },

    //set year make model
    setClassicYearList(state, action) {
      state.classicYearList = action.payload;
    },
    setClassicMakeList(state, action){
      state.classicMakeList=action.payload;
    },
    setClassicModelList(state, action){
      state.classicModelList=action.payload
    },
    setClassicEngineList(state,action){
      state.classicEngineList=action.payload
    },
    setCurrentSelectedYear(state, action){
      state.selectYear= action.payload;
    },
    setCurrentSelectedMake(state, action){
      state.selectMake= action.payload;
    },
    setCurrentSelectedModel(state, action){
      state.selectModel= action.payload;
    },
    setCurrentSelectedEngine(state, action){
      state.selectEngine= action.payload;
    },
    setCurrentSelectedSubModelValue(state, action){
      state.selectSubModelVal=action.payload;
    },
    setActiveArticle(state,action){
      state.activeArticle=action.payload
    },
    SetscrollAutomatic(state,action){
      state.scrollAutomatic=action.payload
    },
    SetscrollSameFaq(state,action){
      state.faqScroll=action.payload
    }, SetscrollSamepage(state,action){
      state.scrollSamePage=action.payload
    },
    setSelectedPart(state,action){
      state.selectedPart=action.payload
    },

    setBlog(state,action){
      state.blog=action.payload
    },
    setProductlistMessage(state,action){
     state.productlistmessage=action.payload
    },
    
    //loader
    showLoader: (state) => {
      state.isLoading = true;
    },
    hideLoader: (state) => {
      state.isLoading = false;
    },
    resetState: () => {
      return CommonSliceInitialState; // Returning the initial state resets the entire slice
    },
  },
});

export const CommonSetupActions = CommonSlice.actions;
export default CommonSlice.reducer;
