import React, { useState } from "react";
import LayoutComponent from "../../Layout/LayoutComponent";
import CommonFooter from "../../Pages/Footer/CommonFooter";
import { CommonSidebar } from "../CommonSidebarComp/CommonSidebar";
import {
  Box,
  FormControl,
  OutlinedInput,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "../Post/Post.css";
import { useTheme } from "@mui/material/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}
export const ReleasedItems = () => {
  const navigate = useNavigate();
  const [selectedImageFile, setSelectedImageFile] = useState(null);

  const theme = useTheme();
  const [category, setCategory] = React.useState([]);
  const [checked, setChecked] = useState(false);

  const[steerPartNo, setSteerPartNo]=useState();
  const[osPartNo,, setOsPartNo]= useState();
  const[desc, setDesc]= useState();
  const[availDate, setAvailDate]= useState();
  const[primaryApplication, setPrimaryApplication]= useState();
  const[image, setImage]=useState();
  // const[category,setCategory]=useState();
  const[installationReady, setInstallationReady]=useState();


  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCategory(typeof value === "string" ? value.split(",") : value);
  };
  const handleImageFileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImageFile(file ? file.name : null);
  };
  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };
  return (
    <LayoutComponent>
      <Box
        // className="new-released-main-container"
        className="post-main-container"

        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          //   gap: 5,
          marginTop: "2.8%",
        }}
      >
        <CommonSidebar />

        <Box           className="post-sub-container"
 sx={{ width: "85%", display: "flex", flexDirection: "column", padding:'3%', gap:'20px' }}>
          {/* MAin new-released create-box */}
          <Box
            className="new-released-create-main-container"
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 1,
              // padding: { xs: "10px", sm: "20px", md: "60px" },
              // padding: "50px 10px 20px 30px",
              // marginTop:'30px'

              // paddingRight: "0 !important",
            }}
          >
            {/* Post Title */}
            {/* <Box
              className="post-title-box"
              sx={{
                width: "100%",
              }}
            >
              <Typography
                className="post-head-typo"
                sx={{
                  fontSize: { xs: "24px", sm: "30px", md: "30px" },
                  fontWeight: 700,
                  fontFamily: "DM Sans",
                  textDecoration: "underline",
                  textDecorationColor: "#E71B2C",
                  textUnderlineOffset: "10px",
                  textDecorationThickness: "3.5px",
                }}
              >
                Newly Released Items
              </Typography>
            </Box> */}
             <Box className="epost-title-box" sx={{width:"100%",padding:'0px',backgroundColor:"#232121", cursor:'pointer'}}>
                                        <Typography className="post-head-typo" sx={{color:'#fff',fontSize: { xs: "20px", sm: "20px", md: "23px" },
                              fontWeight: 700, fontFamily:'DM Sans',textAlign:'center', padding:'5px' }}>NEWLY RELEASED ITEMS</Typography>
                                    </Box>

            {/* newly release items container */}
            <Box
              className="create-post-main-container"
              sx={{
                // width: "100%",
                height: "auto",
                border: "1px solid #ccc",
                marginTop: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 4,
                padding: "30px 0 30px 0",
              }}
            >
              {/* steer and OE part no box */}
              <Box
                className="steer-oe-part-no-main-box"
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                }}
              >
                <Box
                  className="steer-part-no-box"
                  sx={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                  }}
                >
                  <Box
                    className="released-items-left-title-box"
                    sx={{ width: "20%" }}
                  >
                    <Typography
                      className="released-items-left-title"
                      sx={{ fontFamily: "DM Sans" }}
                    >
                      PWR Steer Part No
                    </Typography>
                  </Box>
                  <Box
                    className="create-post-right-content-box"
                    sx={{ width: "80%" }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <TextField
                        type="text"
                        placeholder="Example : 60-5022PR"
                        sx={{
                          height: "45px",
                          "& .MuiInputBase-input::placeholder": {
                            color: "black",
                            fontSize: "16px",
                            fontWeight: 700,
                          },
                        }}
                      />
                    </FormControl>
                  </Box>
                </Box>
                <Box
                  className="oe-part-no-box"
                  sx={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                  }}
                >
                  <Box
                    className="released-items-left-title-box"
                    sx={{ width: "20%" }}
                  >
                    <Typography
                      className="released-items-left-title"
                      sx={{ fontFamily: "DM Sans" }}
                    >
                      OE Part No
                    </Typography>
                  </Box>
                  <Box
                    className="create-post-right-content-box"
                    sx={{ width: "80%" }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <TextField
                        type="text"
                        placeholder="Example : A 278 180 00 09 (278 180 00 09)"
                        sx={{
                          height: "45px",
                          "& .MuiInputBase-input::placeholder": {
                            color: "black",
                            fontSize: "16px",
                            fontWeight: 700,
                          },
                        }}
                      />
                    </FormControl>
                  </Box>
                </Box>
              </Box>
              {/* description and available date box */}
              <Box
                className="steer-oe-part-no-main-box"
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                }}
              >
                <Box
                  className="steer-part-no-box"
                  sx={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                  }}
                >
                  <Box
                    className="released-items-left-title-box"
                    sx={{ width: "20%" }}
                  >
                    <Typography
                      className="released-items-left-title"
                      sx={{ fontFamily: "DM Sans" }}
                    >
                      Description
                    </Typography>
                  </Box>
                  <Box
                    className="create-post-right-content-box"
                    sx={{ width: "80%" }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <TextField
                        type="text"
                        placeholder="Example : Power Steering Pump"
                        sx={{
                          height: "45px",
                          "& .MuiInputBase-input::placeholder": {
                            color: "black",
                            fontSize: "16px",
                            fontWeight: 700,
                          },
                        }}
                      />
                    </FormControl>
                  </Box>
                </Box>
                <Box
                  className="oe-part-no-box"
                  sx={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                  }}
                >
                  <Box
                    className="released-items-left-title-box"
                    sx={{ width: "20%" }}
                  >
                    <Typography
                      className="released-items-left-title"
                      sx={{ fontFamily: "DM Sans" }}
                    >
                      Available Date
                    </Typography>
                  </Box>
                  <Box
                    className="create-post-right-content-box"
                    sx={{ width: "80%" }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <TextField
                        type="date"
                        variant="outlined"
                        sx={{
                          height: "45px",
                          "& .MuiInputBase-root": {
                            height: "53px",
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </Box>
                </Box>
              </Box>
              {/* primary application box */}
              <Box
                className="steer-oe-part-no-main-box"
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: 1.5,
                  marginTop: "7px",
                }}
              >
                <Box
                  className="released-items-left-title-box"
                  sx={{ width: "10%" }}
                >
                  <Typography
                    className="released-items-left-title"
                    sx={{ fontFamily: "DM Sans" }}
                  >
                    Primary Application
                  </Typography>
                </Box>
                <Box
                  className="create-post-right-content-box"
                  sx={{ width: "90%" }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      type="text"
                      placeholder="Example : Mercedes AMG GT(16-17), C300(12-16)"
                      sx={{
                        height: "45px",
                        "& .MuiInputBase-input::placeholder": {
                          color: "black",
                          fontSize: "16px",
                          fontWeight: 700,
                        },
                      }}
                    />
                  </FormControl>
                </Box>
              </Box>
              {/* upload image and category */}
              <Box
                className="steer-oe-part-no-main-box"
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                }}
              >
                <Box
                  className="steer-part-no-box"
                  sx={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                  }}
                >
                  <Box
                    className="released-items-left-title-box"
                    sx={{ width: "20%" }}
                  >
                    <Typography
                      className="released-items-left-title"
                      sx={{ fontFamily: "DM Sans" }}
                    >
                      Upload Image
                    </Typography>
                  </Box>
                  <Box
                    className="create-post-right-content-box"
                    sx={{
                      width: "80%",
                      display: "flex",
                      alignItems: "center",
                      gap: 3,
                    }}
                  >
                    {/* File Input */}
                    <Button
                      className="create-upload-btn"
                      variant="contained"
                      component="label"
                      sx={{
                        width: "120px",
                        height: "30px !important",
                        textTransform: "none",
                        backgroundColor: "#d9d9d9",
                        padding: "20px",
                        color: "#000",
                        fontFamily: "DM Sans",
                        "&:hover": {
                          backgroundColor: "darkgray",
                        },
                      }}
                    >
                      Browse
                      <input
                        type="file"
                        hidden
                        onChange={handleImageFileUpload}
                      />
                    </Button>
                    {/* Message */}
                    <Typography
                      sx={{
                        fontFamily: "DM Sans",
                        fontSize: "18px",
                        color: "#888",
                      }}
                    >
                      {selectedImageFile
                        ? selectedImageFile
                        : "No file selected"}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  className="oe-part-no-box"
                  sx={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                  }}
                >
                  <Box
                    className="released-items-left-title-box"
                    sx={{ width: "20%" }}
                  >
                    <Typography
                      className="released-items-left-title"
                      sx={{ fontFamily: "DM Sans" }}
                    >
                      Category
                    </Typography>
                  </Box>
                  <Box
                    className="create-post-right-content-box"
                    sx={{ width: "80%" }}
                  >
                    <FormControl
                      className="select-category-form-control"
                      sx={{ width: "100%" }}
                    >
                      <Select
                        displayEmpty
                        value={category}
                        onChange={handleChange}
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return (
                              <em style={{ fontFamily: "DM Sans" }}>
                                Select Category
                              </em>
                            );
                          }

                          return selected.join(", ");
                        }}
                        MenuProps={MenuProps}
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{ height: "53px" }}
                      >
                        {names.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, category, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
              {/* product installation */}
              <Box
                className="steer-oe-part-no-main-box installation-ready-checkbox"
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: 1.5,
                  marginTop: "7px",
                }}
              >
                <Box
                  className="released-items-left-title-box"
                  sx={{ width: "30%" }}
                >
                  <Typography
                    className="released-items-left-title"
                    sx={{ fontFamily: "DM Sans" }}
                  >
                    Is this Product Installation Ready?
                  </Typography>
                </Box>
                <Box
                  className="create-post-right-content-box"
                  sx={{ width: "70%" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleCheckboxChange}
                      />
                    }
                    sx={{ marginLeft: "10px" }}
                  />
                </Box>
              </Box>
              {/* buttons */}
              <Box className="new-rel-save-cancel-btn-box">
                <Button
                  variant="contained"
                  sx={{ marginRight: "5px", width: "80px", textTransform:'none' }}
                >
                  Save
                </Button>
                <Button variant="contained" sx={{ width: "80px", textTransform:'none' }}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            className="new-released-hr-sub-container"
            sx={{
              width: "100%",
              paddingRight: "0 !important",
            }}
          >
            {" "}
            <hr style={{ width: "100%", margin: 0, color:'#ccc' }} />
          </Box>
          {/* all new-released data */}
          <Box
            className="new-released-all-data-sub-container"
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 1,
              // padding: { xs: "10px", sm: "20px", md: "60px" },
              // padding: "20px 10px 20px 30px",
              // paddingRight: "0 !important",
            }}
          >
            <TableContainer
              component={Paper}
              sx={{ width: "100%", border: "2px solid #ccc" }}
            >
              <Table sx={{ minWidth: "90%" }} aria-label="New-release table">
                <TableHead>
                  <TableRow>
                    <TableCell className="new-released-table-header-cells">
                      PG Part No
                    </TableCell>
                    <TableCell className="new-released-table-header-cells">
                      OE Part No
                    </TableCell>
                    <TableCell className="new-released-table-header-cells">
                      Description
                    </TableCell>
                    <TableCell className="new-released-table-header-cells">
                      Available Date
                    </TableCell>
                    <TableCell className="new-released-table-header-cells">
                      Application
                    </TableCell>
                    <TableCell className="new-released-table-header-cells">
                      Edit/Delete
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className="new-released-table-row-cells">
                      42-23233
                    </TableCell>
                    <TableCell className="new-released-table-row-cells">
                      428686-25078
                    </TableCell>
                    <TableCell className="new-released-table-row-cells">
                      RACK & PINION
                    </TableCell>
                    <TableCell className="new-released-table-row-cells">
                      October 23, 2023
                    </TableCell>
                    <TableCell className="new-released-table-row-cells">
                      2005-2007 TOYOTA-AVALON
                    </TableCell>
                    <TableCell sx={{display:'flex', flexWrap:'wrap', gap:1}}>
                      {" "}
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{textTransform:'none' }}
                      >
                        Edit
                      </Button>
                      <Button variant="contained" color="error" sx={{ textTransform:'none'}}>
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
      <CommonFooter />
    </LayoutComponent>
  );
};
