import React, { useState } from "react";
import LayoutComponent from "../../Layout/LayoutComponent";
import CommonFooter from "../../Pages/Footer/CommonFooter";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { CommonSidebar } from "../CommonSidebarComp/CommonSidebar";
import "../Post/Post.css";
export const Export = () => {
  const [year, setYear] = useState("");

  return (
    <LayoutComponent>
      <Box
        // className="export-main-container"
        className="post-main-container"
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          //   gap: 5,
          marginTop: "2.8%",
        }}
      >
        <CommonSidebar />

        <Box 
                  className="post-sub-container"
        // className="export-main-cont" 
        sx={{ width: "85%", display: "flex", flexDirection: "column", padding:'50px' }}>
          <Box
            className="new-released-create-main-container"
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 1,
              // padding: "50px 50px 50px 40px",
            }}
          >
            {/* export heading */}
            {/* <Box
              className="export-heading-box"
              sx={{
                width: "100%",
              }}
            >
              <Typography
                className="export-heading-typo"
                sx={{
                  fontSize: { xs: "24px", sm: "30px", md: "30px" },
                  fontWeight: 700,
                  fontFamily: "DM Sans",
                  textDecoration: "underline",
                  textDecorationColor: "#E71B2C",
                  textUnderlineOffset: "10px",
                  textDecorationThickness: "3.5px",
                }}
              >
                Export
              </Typography>
            </Box> */}
             <Box className="export-heading-box" sx={{width:"100%",backgroundColor:"#232121", cursor:'pointer'}}>
                            <Typography className="export-heading-typo" sx={{color:'#fff',fontSize: { xs: "20px", sm: "20px", md: "23px" },
                  fontWeight: 700, fontFamily:'DM Sans',textAlign:'center',padding:'5px', }}>EXPORT</Typography>
                        </Box>
            {/* export PWR box */}
            <Box
              className="export-pwr-main-box"
              sx={{
                width: "98%",
                height: "auto",
                display: "flex",
                flexDirection: "row",
                gap: 9,
                boxShadow: "0 2px 2px rgba(0,0,0,0.1)",
                marginTop: "30px",
                padding: "20px 0 20px 20px",
              }}
            >
              <Typography className="export-pwr-typo" sx={{                              fontFamily: "DM Sans",
}}>
                EXPORT PWR#
              </Typography>
              <Button
              className="export-btn"
                variant="contained"
                color="error"
                sx={{
                  width: "13%",
                  padding: "10px",
                  fontFamily: "DM Sans",
                  fontWeight: 600,
                  fontSize: "15px",
                  textTransform:'none'
                }}
              >
                Export PWR#
              </Button>
            </Box>
            {/* export applications in range format */}
            <Box
              className="export-pwr-main-box"
              sx={{
                width: "98%",
                height: "auto",
                display: "flex",
                flexDirection: "row",
                gap: 9,
                boxShadow: "0 2px 2px rgba(0,0,0,0.1)",
                marginTop: "30px",
                padding: "20px 0 20px 20px",
              }}
            >
              <Typography className="export-pwr-typo" sx={{fontFamily: "DM Sans",
}}>
                APPLICATIONS IN RANGE FORMAT
              </Typography>
              <Button
              className="export-btn"
                variant="contained"
                color="error"
                sx={{
                  width: "18%",
                  padding: "10px",
                  fontFamily: "DM Sans",
                  fontWeight: 600,
                  fontSize: "15px",
                   textTransform:'none'
                }}
              >
                Export Applications
              </Button>
            </Box>
            {/* export part images */}
            <Box
              className="export-pwr-main-box"
              sx={{
                width: "98%",
                height: "auto",
                display: "flex",
                flexDirection: "column",
                gap: 3,
                boxShadow: "0 2px 2px rgba(0,0,0,0.1)",
                marginTop: "30px",
                padding: "20px 0 20px 20px",
              }}
            >
              <Typography className="export-pwr-typo" sx={{fontFamily: "DM Sans",}}>PART IMAGES</Typography>
              <Box
                className="export-part-images-select"
                sx={{ display: "flex", flexDirection: "row", gap: 9 }}
              >
                <FormControl
                  className="part-images-form-control"
                  sx={{ width: "50%", flexDirection: "row", gap: 6 }}
                >
                  <FormLabel
                    className="row-radio-button-group-label"
                    sx={{
                      padding: "11px",
                      color: "#000",
                      "&.Mui-focused": {
                        color: "#000",
                      },
                    }}
                  >
                    Select Export Type
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    sx={{ gap: 8 }}
                    defaultValue="horizontal"
                  >
                    <FormControlLabel
                      value="horizontal"
                      control={<Radio />}
                      label="Horizontal"
                    />
                    <FormControlLabel
                      value="vertical"
                      control={<Radio />}
                      label="Vertical"
                    />
                  </RadioGroup>
                </FormControl>
                <Button
                className="export-btn"
                  variant="contained"
                  color="error"
                  sx={{
                    width: "17%",
                    padding: "10px",
                    fontFamily: "DM Sans",
                    fontWeight: 600,
                    fontSize: "15px",
                     textTransform:'none'
                  }}
                >
                  Export Part Images
                </Button>
              </Box>
            </Box>
            {/* missing coverage */}
            <Box
              className="export-pwr-main-box"
              sx={{
                width: "98%",
                height: "auto",
                display: "flex",
                flexDirection: "column",
                gap: 3,
                boxShadow: "0 2px 2px rgba(0,0,0,0.1)",
                marginTop: "30px",
                padding: "20px 0 20px 20px",
              }}
            >
              <Typography className="export-pwr-typo" sx={{fontFamily: "DM Sans",}}>
                MISSING COVERAGE
              </Typography>
              <Box
                className="export-part-images-select"
                sx={{ display: "flex", flexDirection: "row", gap: 9 }}
              >
                <FormControl
                  className="part-images-form-control"
                  sx={{
                    width: "50%",
                    flexDirection: "row",
                    gap: 6,
                    alignItems: "center",
                  }}
                >
                  <FormLabel
                    className="row-radio-button-group-label"
                    sx={{
                      padding: "11px",
                      fontFamily: "DM Sans",
                      color: "#000",
                      "&.Mui-focused": {
                        color: "#000",
                      },
                    }}
                  >
                    Select Year:
                  </FormLabel>
                  <Select
                    value={year}
                    onChange={(event) => setYear(event.target.value)}
                    displayEmpty
                    sx={{ width: "70%", height: "40px" }}
                  >
                    <MenuItem value="" disabled sx={{fontFamily: "DM Sans",}}>
                      Select Year
                    </MenuItem>
                    <MenuItem value="2019">2019</MenuItem>
                    <MenuItem value="2018">2018</MenuItem>
                  </Select>
                </FormControl>
                <Button
                className="export-btn"
                  variant="contained"
                  color="error"
                  sx={{
                    width: "22%",
                    padding: "10px",
                    fontFamily: "DM Sans",
                    fontWeight: 600,
                    fontSize: "15px",
                     textTransform:'none'
                  }}
                >
                  Export Missing Coverage
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <CommonFooter />
    </LayoutComponent>
  );
};
