import React, { useMemo, useRef, useState } from "react";
import LayoutComponent from "../../Layout/LayoutComponent";
import CommonFooter from "../../Pages/Footer/CommonFooter";
import { Box, Typography, Button, Select } from "@mui/material";
import { CommonSidebar } from "../CommonSidebarComp/CommonSidebar";
import "./Post.css";
import { useNavigate } from "react-router-dom";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import { Editor } from '@tinymce/tinymce-react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}
export const CreatePost = ({placeholder}) => {
  const theme = useTheme();
  const [category, setCategory] = React.useState([]);
  const [content, setContent] = useState("");

  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file ? file.name : null);
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCategory(typeof value === "string" ? value.split(",") : value);
  };

  const handleEditorChange = (content, editor) => {
    setContent(content);
  };
  
  return (
    <LayoutComponent>
      <Box
        className="post-main-container"
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          // gap: 5,
          marginTop: "2.8%",
        }}
      >
        {/* Sidebar */}
        {/* <Box className="post-sidebar-box"
          sx={{
            width: { xs: "100%", md: "20%" },
            height: "auto",
          }}
        > */}
          <CommonSidebar />
        {/* </Box> */}

        {/* Main box */}
        <Box
          className="post-sub-container"
          sx={{
            width: "85%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 1,
            // padding: { xs: "10px", sm: "20px", md: "30px" },
            padding:'50px'
          }}
        >
          {/* back link */}
          <Box
            className="post_backlink_box"
            sx={{
              width: "10%",
              border: "1px",
            }}
          >
            <Typography
              onClick={() => {
                sessionStorage.clear();
                navigate(-1);
              }}
              sx={{
                fontFamily: "DM Sans",
                textAlign: "left",
                fontWeight: 400,
                lineHeight: "27px",
                color: "#565656",
                textDecoration: "none",
                cursor: "pointer",
              }}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  sessionStorage.clear();
                  navigate(-1);
                }
              }}
            >
              {"< BACK"}
            </Typography>
          </Box>
          {/*create Post Title */}
          {/* <Box
            className="post-title-box"
            sx={{
              width: "100%",
            }}
          >
            <Typography
              className="post-head-typo"
              sx={{
                fontSize: "20px",
                fontWeight: 700,
                fontFamily: "DM Sans",
                textDecoration: "underline",
                textDecorationColor: "#E71B2C",
                textUnderlineOffset: "10px",
                textDecorationThickness: "3.5px",
                textAlign: "center",
              }}
            >
              CREATE POST
            </Typography>
          </Box> */}
          <Box className="post-title-box" sx={{width:"100%",backgroundColor:"#232121", cursor:'pointer'}}>
                                      <Typography className="post-head-typo" sx={{color:'#fff',fontSize: { xs: "20px", sm: "20px", md: "23px" },
                            fontWeight: 700, fontFamily:'DM Sans',textAlign:'center',padding:'5px', }}>CREATE POST</Typography>
                                  </Box>
          {/* create post container */}
          <Box
            className="create-post-main-container"
            sx={{
              width: "100%",
              height: "auto",
              border: "1px solid #ccc",
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
              padding:'20px 0 20px 0'
            }}
          >
            {/* post title */}
            <Box className="create-post-title" sx={{}}>
              {/* title */}
              <Box className="create-post-left-title-box" sx={{ width: "20%" }}>
                <Typography className="create-post-left-title" sx={{fontFamily: "DM Sans"}}>
                  Post Title
                </Typography>
              </Box>
              <Box
                className="create-post-right-content-box"
                sx={{ width: "80%" }}
              >
                <FormControl sx={{ width: "100%" }}>
                  <OutlinedInput
                    sx={{ height: "45px" }}
                  />
                </FormControl>
              </Box>
            </Box>

            {/* post content */}
            <Box
              className="create-post-title"

              // className="create-post-content"
            >
              <Box className="create-post-left-title-box" sx={{ width: "20%" }}>
                <Typography className="create-post-left-title" sx={{fontFamily: "DM Sans"}}>
                  Post Content
                </Typography>
              </Box>
              <Box
                className="create-post-right-content-box"
                sx={{ width: "80%" }}
              >
                <Editor 
                  apiKey="n7szhskco0ga2ezrmkwtib99nk8eno9elye8m8csg215wmhn"
                  init={{
                    plugins:[
                      // Core editing features
          'anchor', 'autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists', 'media', 'searchreplace', 'table', 'visualblocks', 'wordcount',
          // Your account includes a free trial of TinyMCE premium features
          // Try the most popular premium features until Jan 29, 2025:
          'checklist', 'mediaembed', 'casechange', 'export', 'formatpainter', 'pageembed', 'a11ychecker', 'tinymcespellchecker', 'permanentpen', 'powerpaste', 'advtable', 'advcode', 'editimage', 'advtemplate', 'ai', 'mentions', 'tinycomments', 'tableofcontents', 'footnotes', 'mergetags', 'autocorrect', 'typography', 'inlinecss', 'markdown','importword', 'exportword', 'exportpdf'

                    ],
                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
        tinycomments_mode: 'embedded',
        tinycomments_author: 'Author name',
        mergetags_list: [
          { value: 'First.Name', title: 'First Name' },
          { value: 'Email', title: 'Email' },
        ],
        ai_request: (request, respondWith) => respondWith.string(() => Promise.reject('See docs to implement AI Assistant')),

                  }}
                  onEditorChange={handleEditorChange}
                />
              </Box>
            </Box>

            {/* upload image*/}
            <Box
              className="create-post-title"
              // className="create-post-image"
            >
              <Box className="create-post-left-title-box" sx={{ width: "20%" }}>
                <Typography className="create-post-left-title" sx={{fontFamily: "DM Sans"}}>
                  Upload Image
                </Typography>
              </Box>
              {/* Upload Section */}
              <Box
                className="create-post-right-content-box"
                sx={{
                  width: "80%",
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                {/* File Input */}
                <Button
                  className="create-upload-btn"
                  variant="contained"
                  component="label"
                  sx={{
                    width: "90px",
                    height: "30px !important",
                    textTransform: "none",
                    backgroundColor: "#d9d9d9",
                    padding: "20px",
                    color: "#000",
                    fontFamily: "DM Sans",
                    "&:hover": {
                      backgroundColor: "darkgray",
                    },
                  }}
                >
                  Browse
                  <input type="file" hidden onChange={handleFileChange} />
                </Button>

                {/* Message */}
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontSize: "14px",
                    color: "#888",
                  }}
                >
                  {selectedFile ? selectedFile : "No file selected"}
                </Typography>
              </Box>
            </Box>
            {/* category */}
            <Box
              className="create-post-title"
              //  className="create-post-category"
            >
              <Box className="create-post-left-title-box" sx={{ width: "20%" }}>
                <Typography className="create-post-left-title" sx={{fontFamily: "DM Sans"}}>
                  Category{" "}
                </Typography>
              </Box>
              <Box
                className="create-post-right-content-box"
                sx={{ width: "80%" }}
              >
                <FormControl
                  className="select-category-form-control"
                  sx={{ m: 1, width: 500, mt: 3 }}
                >
                  <Select
                    displayEmpty
                    value={category}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return (
                          <em style={{ fontFamily: "DM Sans" }}>
                            Select Category
                          </em>
                        );
                      }

                      return selected.join(", ");
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ height: "40px" }}
                  >
                    {names.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        style={getStyles(name, category, theme)}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>

            {/* post buttons */}
            <Box
              className="create-post-title"
              //  className="create-post-buttons"
            >
              <Box
                className="button-box"
                sx={{
                  display: "flex",
                  gap: 1,
                  flexDirection: "row",
                  justifyContent: "",
                }}
              >
                <Button
                  variant="contained"
                  color="error"
                  sx={{ textTransform: "none", fontFamily: "DM Sans" }}
                >
                  Submit
                </Button>
                <Button
                type="reset"
                  variant="contained"
                  color="primary"
                  sx={{ textTransform: "none", fontFamily: "DM Sans" }}
                >
                  Reset
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <CommonFooter />
    </LayoutComponent>
  );
};
