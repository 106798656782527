import React, { useEffect, useState, useRef } from "react";
import LayoutComponent from "../../Layout/LayoutComponent";
import { useDispatch, useSelector } from "react-redux";
import CommonFooter from "../Footer/CommonFooter";
import {
  Box,
  Breadcrumbs,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import routes from "../../AppRoutes/routes.json";
import { CommonSetupActions } from "../../Slices/Commonslice";
import { ApiLink } from "../Utils/Apilink";
import CommonLoader from "../CommonComponent/CommonLoader";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ArticleDetailed = () => {
  const { activeArticle } = useSelector((state) => state.CommonStore);
  const { postTitle } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [activeBlog, setActiveBlog] = useState({});
  const contollerRef = useRef(false);
  const [otherBlog, setOtherBlog] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!sessionStorage.getItem("hasRefreshed")) {
      sessionStorage.setItem("hasRefreshed", "true");
      window.location.reload();
    }
  }, []);

  const fetchBlog = () => {
    setLoading(true);
    setActiveBlog(null);

    const link2 = `${ApiLink}articles`;
    fetch(link2, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    })
      .then((resp) => resp.json())
      .then((response) => {
        console.log('article res: ', response);
        const matchingBlog = response.find(
          (article) => article.post_slug === postTitle
        );
        const otherBlogs = response.filter(
          (article) => article.post_slug !== postTitle
        );

        if (matchingBlog) {
          setActiveBlog(matchingBlog);
          dispatch(CommonSetupActions.setActiveArticle(matchingBlog));
        }

        setOtherBlog(otherBlogs);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (
      !contollerRef.current &&
      (!activeArticle || activeArticle.slug !== postTitle)
    ) {
      fetchBlog();
      contollerRef.current = true;
    } else {
      setActiveBlog(activeArticle);
    }
  }, [activeArticle, postTitle]);
  const parseHtml = (content) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content;
    const firstP = tempDiv.querySelector("p");
    return firstP.innerHTML;
  };
  const handleImageClick = (selectedBlog) => {
    navigate(`/articles/${selectedBlog.post_slug}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setActiveBlog(selectedBlog);
    dispatch(CommonSetupActions.setActiveArticle(selectedBlog));

    setOtherBlog((prevOtherBlogs) => {
      const updatedOtherBlogs = prevOtherBlogs.filter(
        (blog) => blog.post_slug !== selectedBlog.post_slug
      );

      if (activeBlog && Object.keys(activeBlog).length > 0) {
        return [activeBlog, ...updatedOtherBlogs];
      }
      return updatedOtherBlogs;
    });
  };

  const breadcrumbs = [
    <Link
      className="breadcumb-link"
      key="1"
      style={{
        color: "#000000",
        textDecoration: "none",
        fontFamily: "DM Sans",
      }}
      to={routes.Home}
      // onClick={handleClick}
    >
      Home
    </Link>,
    <Link
      className="breadcumb-link"
      key="2"
      style={{
        color: "#000000",
        textDecoration: "none",
        fontFamily: "DM Sans",
      }}
      to={routes.Articles}
    >
      Articles
    </Link>,
    <Typography
      className="breadcumb-link"
      key="3"
      sx={{ color: "text.primary", fontWeight: 700, fontFamily: "DM Sans" }}
    >
      {activeArticle.post_title}
    </Typography>,
  ];

  return (
    <LayoutComponent>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          paddingTop: 2,
          alignItems: "center",
          marginBottom: "7%",
        }}
      >
        <Box
          className="article-details-nav-main-box"
          sx={{
            // width: "90%",
            width: "90%",
            padding: "5%",
            paddingBottom:'1%',
            // marginTop: "4%",
            // marginBottom: "10px",
          }}
        >
          <Breadcrumbs
            separator="›"
            aria-label="breadcrumb"
            sx={{ ".MuiBreadcrumbs-separator": { color: "red" } }}
          >
            {breadcrumbs}
          </Breadcrumbs>
          {/* <Box>
            <Button
              className="lay-dropdown-btn"
              tabIndex={0}
              variant="contained"
              sx={{
                backgroundColor: "#EC1B24",
                width: "auto",
                height: "42px",
                borderRadius: "100px",
                fontFamily: "DM Sans",
                padding: 2,
                textTransform: "none",
                "&:hover": { backgroundColor: "#a51219" },
              }}
              onClick={() => navigate(routes.Articles)}
            >
              BACK TO ARTICLES
            </Button>
          </Box> */}
        </Box>

        {loading ? (
          // Loader
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <CommonLoader
              open={loading}
              handleClose={() => setLoading(false)}
            />
          </Box>
        ) : activeBlog ? (
          // Article Content
          <>
            {/* <Box
              className="html_content"
              sx={{
                width: "75%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent:'flex-start',
                marginTop: "10px",
              }}
            >
              <Typography
                className="active-blog-post-title-typo"
                sx={{
                  fontSize: { xs: "24px", sm: "30px", md: "34px" },
                  fontWeight: { xs: 600, sm: 700, md: 700 },
                  fontFamily: "DM Sans",
                  lineHeight: { xs: "40px", sm: "45px", md: "55px" },
                  textDecoration: "underline",
                  textUnderlineOffset: "10px",
                  textDecorationColor: "#E30000",
                  textDecorationThickness: "3.5px",
                  marginBottom: "20px",
                }}
              >
                {activeBlog.post_title}
              </Typography>
            </Box>
            <Box
              className="active-art-det-img-main-box"
              sx={{
                width: "75%",
                // height:'100%',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "12px",
                marginTop: "1%",
              }}
            >
              <LazyLoadImage
                src={activeBlog.post_image}
                width="100%"
                height="auto"
                alt="blog"
                loading="lazy"
                style={{ borderRadius: "12px" }}
              />
            </Box> */}

<Box
  className="article-hero"
  sx={{
    width: "100%",
    height: "450px", 
    // height: "80vh", 

    backgroundImage: `url(${activeBlog.post_image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }}
>
  {/* Dark Overlay */}
  <Box
    sx={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.6)", 
    }}
  />

  {/* Title */}
  <Typography
    className="html_content active-blog-post-title-typo"
    sx={{
      width:"75%",
      position: "relative",
      zIndex: 1,
      color: "#fff",
      fontWeight: 700,
      fontSize: "45px",
      lineHeight: "50px",
      // fontSize: { xs: "24px", sm: "30px", md: "34px" },
      //             fontWeight: { xs: 600, sm: 700, md: 700 },
      //             lineHeight: { xs: "40px", sm: "35px", md: "50px" },

      fontFamily: "DM Sans",
      textAlign: "left",
      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)",
      // padding: "0 20px",
    }}
  >
    {activeBlog.post_title}
  </Typography>
</Box>

            <Box
              className="html_content"
              sx={{
                width: "75%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "30px",
              }}
            >
              {/* <Typography
                className="active-blog-post-title-typo"
                sx={{
                  fontSize: { xs: "24px", sm: "30px", md: "34px" },
                  fontWeight: { xs: 600, sm: 700, md: 700 },
                  fontFamily: "DM Sans",
                  lineHeight: { xs: "40px", sm: "45px", md: "55px" },
                  textDecoration: "underline",
                  textUnderlineOffset: "10px",
                  textDecorationColor: "#E30000",
                  textDecorationThickness: "3.5px",
                  marginBottom: "20px",
                }}
              >
                {activeBlog.post_title}
              </Typography> */}
              <div
                className="active-blog-post-content1"
                dangerouslySetInnerHTML={{ __html: activeBlog.post_content }}
              />
            </Box>
            <Box
              className="art-det-other-main-comp"
              sx={{
                width: "75%",
                display: "flex",
                flexDirection: "column",
                gap: 4,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 700,
                    fontSize: "34px",
                    color: "#000000",
                    lineHeight: "47px",
                    textDecoration: "underline",
                    textDecorationColor: "#E30000",
                    textUnderlineOffset: "10px",
                    textDecorationThickness: "3px",
                  }}
                >
                  Other Articles
                </Typography>
              </Box>

              <Box
                className="article-det-other-blog-section"
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: "30px",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {/* <Box className="article-det-blog-section1">

                  </Box> */}
                {otherBlog &&
                  otherBlog.length > 0 &&
                  otherBlog
                    .filter((data) => data.post_title !== activeBlog.post_title)
                    .map((blog, index) => {
                      return (
                        <Box
                          className="article_other_blog1_section art-det"
                          key={index}
                          sx={{
                            width: "48%",
                            height: "auto",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: 2,
                            marginBottom: "20px",
                            cursor: "pointer",
                            padding: "10px",
                            border: "0.7px solid #808080",
                            borderRadius: "15px",
                            backgroundColor: "#F9F9F9",
                          }}
                          onClick={() => handleImageClick(blog)}
                        >
                          {/* Image */}
                          <Box
                            className="Article_blog_list_image_Container"
                            sx={{
                              width: "231px",
                              height: "171px",
                              borderRadius: "10px",
                              overflow: "hidden",
                              flexShrink: 0,
                            }}
                          >
                            <LazyLoadImage
                              src={blog.post_image}
                              alt={blog && blog.post_title}
                              loading="lazy"

                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "10px",
                              }}
                            />
                          </Box>

                          {/* Title and Description */}
                          <Box
                            className="Article_blog_list_image_Container"
                            sx={{
                              width: "calc(75% - 140px)",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "flex-start",
                              alignItems: "flex-start",
                              gap: "5px",
                            }}
                          >
                            <Typography
                              className="article-blog-list-title-typo"
                              sx={{
                                fontWeight: 700,
                                fontSize: "18px",
                                fontFamily: "DM Sans",
                                lineHeight: "27px",
                                color: "#0E1415",
                                maxHeight: "74px",
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                                textOverflow: "ellipsis",

                                // whiteSpace: "normal",
                                // overflowWrap: "break-word",
                                // wordBreak: "break-word",
                              }}
                            >
                              {blog.post_title}
                            </Typography>
                            <Typography
                              className="article-blog-list-body-typo"
                              sx={{
                                fontFamily: "DM Sans",
                                fontSize: "16px",
                                fontWeight: 500,
                                lineHeight: "25px",
                                color: "#0B0F11",
                                // marginTop: "10px",
                                maxHeight: "74px",
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp: 3,
                                WebkitBoxOrient: "vertical",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {parseHtml(blog.post_content)}
                            </Typography>

                            {/* Blog Metadata */}

                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                gap: 1.5,
                                marginTop: "10px",
                                width: "100%",
                              }}
                            ></Box>
                          </Box>
                        </Box>
                      );
                    })}
              </Box>
            </Box>
          </>
        ) : (
          <Typography sx={{ fontFamily: "DM Sans" }}>Data Not Found</Typography>
        )}
      </Box>
      <CommonFooter />
    </LayoutComponent>
  );
};

export default ArticleDetailed;
