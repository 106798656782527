import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { GlassMagnifier } from "react-image-magnifiers";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
};

export const ImagesModal = ({ open, handleClose, currentImg, setCurrentImg }) => {
  
  const { productImages, selectedPart } = useSelector(
    (state) => state.CommonStore
  );
  const [isZoomEnabled, setIsZoomEnabled] = useState(window.innerWidth > 768);

  // State for the current image index
  const [currentIndex, setCurrentIndex] = useState(
    productImages.length > 0 && productImages.findIndex((img) => img.fileName === currentImg?.fileName) || 0
  );

  // Handle navigating to the previous image
  const handlePrev = () => {
    const prevIndex = (currentIndex - 1 + productImages.length) % productImages.length;
    setCurrentIndex(prevIndex);
    setCurrentImg(productImages[prevIndex]);
  };

  // Handle navigating to the next image
  const handleNext = () => {
    const nextIndex = (currentIndex + 1) % productImages.length;
    setCurrentIndex(nextIndex);
    setCurrentImg(productImages[nextIndex]);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock={true}
      disablePortal={true}
      sx={{
        height: "820px",
        padding: "20px",
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        top: "10%",
        "& .MuiBackdrop-root": {
          backdropFilter: "blur(5px)",
        },
      }}
    >
      <Box className="main-modal-cont" sx={style}>
        {/* Header */}
        <Box
          className="modal-head"
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#F0F3F3",
            textAlign: "left",
            justifyContent:'space-between'
          }}
        >
          <Typography
            sx={{
              fontFamily: "DM Sans",
              fontSize: "30px",
              fontWeight: 500,
              padding: "5px",
              paddingLeft: "2%",
            }}
          >
            {selectedPart?.partNumber || "Part Number"}
          </Typography>
          <IconButton
            onClick={handleClose}
            sx={{
              color: "#000",
              // marginLeft:'78%',
            }}
          >
            <CloseIcon />
          </IconButton>        </Box>

        {/* Content */}
        <Box
                  className="modal-body-main"

          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            width: "95%",
            height: "90%",
            padding: "20px",
          }}
        >
          {/* Thumbnails */}
          <Box
            className="thumbNails"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "7%",
              paddingLeft: "5px",
              paddingTop: "10px",
              flexWrap: "wrap",
              height: "90%",
            }}
          >
            {productImages.length > 0 && productImages.map((img, index) => {
              const isActive = currentIndex === index;
              return (
                <Box
                  key={img.img_id}
                  sx={{
                    width: "64.84px",
                    height: "49.28px",
                    border: isActive
                      ? "0.5px solid #EC1B24"
                      : "0.5px solid #808080",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "2px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setCurrentIndex(index);
                    setCurrentImg(img);
                  }}
                  onMouseEnter={() => {
                    setCurrentImg(img);
                  }}
                  onMouseLeave={() => {
                    setCurrentImg(productImages[currentIndex]); 
                  }}
                >
                  <img
                    src={img.fileName}
                    alt={img.partNumber}
                    style={{
                      width: "42.3px",
                      height: "42.3px",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              );
            })}
          </Box>

          {/* Centered Image with Carousel Arrows */}
          <Box  className="center-image-main-div"
            sx={{
              width: "90%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            {/* Left Arrow */}
            <IconButton
              onClick={handlePrev}
              sx={{
                position: "absolute",
                left: 0,
                zIndex: 10,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                color: "#fff",
                "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
              }}
            >
              <ArrowBackIosIcon />
            </IconButton>

            {/* Centered Image */}
            <Box
              className="centeredImage"
              sx={{
                width: "50%",
                borderRadius: "10px",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
          {currentImg && currentImg.fileName ? (
                          <Box>
              {isZoomEnabled ? (
                <GlassMagnifier
                imageSrc={currentImg.fileName}

                  // imageSrc={productImages[currentIndex].fileName}
                  imageAlt="Zoomed Image"
                  zoomWidth={200}
                  zoomHeight={200}
                  magnifierSize="25%"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <img
                  src={currentImg.fileName}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                  alt={currentImg.partNumber || "Image"}
                />
              )}
            </Box>
              ) : (
                <Typography
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 600,
                    fontSize: "18px",
                    color: "#808080",
                    textAlign: "center",
                  }}
                >
                  Photo Coming Soon
                </Typography>
              )}
            </Box>

            {/* Right Arrow */}
            <IconButton
              onClick={handleNext}
              sx={{
                position: "absolute",
                right: 0,
                zIndex: 10,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                color: "#fff",
                "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
              }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
