import React, { useState } from "react";
import LayoutComponent from "../../Layout/LayoutComponent";
import CommonFooter from "../../Pages/Footer/CommonFooter";
import { Box, Typography, TextField, Button } from "@mui/material";
import "../../Admin/Login/AdminLogin.css";

export const AdminLogin = () => {
  const [formFields, setFormFields] = useState({
    username: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    username: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { username: "", password: "" };

    if (!formFields.username) {
      newErrors.username = "Please enter Username";
      isValid = false;
    }

    if (!formFields.password) {
      newErrors.password = "Please enter Password";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
    }
  };

  return (
    <LayoutComponent>
      <Box
        className="login_main_container"
        sx={{width: "100%",
            //  height: "100vh"
             }}
      >
        <Box
          className="Login_sub_container"
          sx={{
            position: "relative",
            width: "100%",
            // height: "80vh",
            // height:'auto',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop:'10%',
            marginBottom:'5%'
            //   padding: "20px",
          }}
        >
          <Box
            className="Login_card"
            sx={{
              width: "20%",
              padding: "40px",
              border: "2px solid #D9D9D9",
              borderRadius: "8px",
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.2)",
                        }}
          >
            <Box className="login-heading" sx={{ marginBottom: "20px" }}>
              <Typography variant="h4" align="center" sx={{ fontWeight: 700, fontFamily:'DM Sans', textDecoration: 'underline',
                    textDecorationColor: '#E71B2C',
                    textUnderlineOffset: '10px',
                    textDecorationThickness:'3.5px', }}>
                Login
              </Typography>
            </Box>
            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              {/* Username */}
              <Box className="username" sx={{ marginBottom: "15px" }}>
                <Typography variant="body1" sx={{ fontWeight: 700,fontSize:"18px",  fontFamily:'DM Sans' }}>
                  Username
                </Typography>
                <TextField
                  name="username"
                  value={formFields.username}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "7px",
                      height: "45px",
                      backgroundColor: "#FFFFFF",
                      fontFamily: "DM Sans",
                    },
                    fontSize: "14px",
                    width: "100%",
                    height: "45px",
                    marginTop:'5px'
                  }}
                />
                {errors.username && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginTop: "5px",fontFamily:'DM Sans', }}
                  >
                    {errors.username}
                  </Typography>
                )}
              </Box>

              {/* Password */}
              <Box className="password" sx={{ marginBottom: "20px" }}>
                <Typography variant="body1" sx={{ fontWeight: 700, fontSize:"18px",  fontFamily:'DM Sans', }}>
                  Password
                </Typography>
                <TextField
                  name="password"
                  type="password"
                  value={formFields.password}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "7px",
                      height: "45px",
                      backgroundColor: "#FFFFFF",
                      fontFamily: "DM Sans",
                    },
                    fontSize: "14px",
                    width: "100%",
                    height: "45px",
                    marginTop:'5px'

                  }}
                />
                {errors.password && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ marginTop: "5px",fontFamily:'DM Sans', }}
                  >
                    {errors.password}
                  </Typography>
                )}
              </Box>

              {/* Login Button */}
              <Box className="login-btn-box" sx={{ textAlign: "center" }}>
                <Button
                  className="lgn-btn"
                  variant="contained"
                  color="error"
                  type="submit"
                  sx={{ width: "100px", textTransform:'none', fontSize:'16px',fontFamily:'DM Sans' }}
                >
                  Log In
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
      <CommonFooter />
    </LayoutComponent>
  );
};
