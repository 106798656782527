import React, { useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import "./PartNoModal.css";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Paper } from "@mui/material";

const useStyles = {
  customScrollbar: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "89%",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      // backgroundColor: "#f1f1f1",
      backgroundColor: "#fff",
    },
    "&::-webkit-scrollbar-thumb": {
      // backgroundColor: "#ec1b24",
      backgroundColor: "#fff",

      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#d1191f",
    },
    "&::-webkit-scrollbar-button": {
      display: "none",
      width: "0",
      height: "0",
    },
  },
};

export const PartNoModal = ({ open, handleClose, scrollTop }) => {
  const modalRef = useRef(null);

  const parts = [
    {
      partNo: "60-5023",
      partNOSpan: "P",
      desc: "Pump Supplied With ",
      descSpan: "Pulley",
    },
    {
      partNo: "60-5009",
      partNOSpan: "R",
      desc: "Pump Supplied With ",
      descSpan: "Reservoir + Cap",
    },
    {
      partNo: "60-6900",
      partNOSpan: "PR",
      desc: "Pump Supplied With ",
      descSpan: "Pulley & Reservoir + Cap",
    },
    {
      partNo: "70-5017",
      partNOSpan: "PXR",
      desc: "Pump Supplied With ",
      descSpan: "Pulley & Remote Reservoir + Cap",
    },
    {
      partNo: "61-5041",
      partNOSpan: "",
      desc: "Gear Box ",
      descSpan: "",
    },
    {
      partNo: "42-2760",
      partNOSpan: "",
      desc: "Rack & Pinions Hydraulic ",
      descSpan: "",
    },
    {
      partNo: "42-2993",
      partNOSpan: "T",
      desc: "Rack & Pinions Hydraulic supplied with ",
      descSpan: "Outer Tie Rods",
    },
    {
      partNo: "45-1252",
      partNOSpan: "",
      desc: "Rack & Pinions, Manual",
      descSpan: "",
    },
    {
      partNo: "45-4013",
      partNOSpan: "T",
      desc: "Rack & Pinions Manual Supplied with ",
      descSpan: "Outer Tie Rods",
    },
    {
      partNo: "47-8001",
      partNOSpan: "T",
      desc: "Rack & Pinions EPS All Supplied with ",
      descSpan: "Outer Tie Rods",
    },
  ];

  // useEffect(() => {
  //   if (modalRef.current) {
  //     modalRef.current.scrollTop = scrollTop;
  //   }
  // }, [scrollTop]);

  return (
    <Modal
      className="modal-comp"
      // component={Paper}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      disableScrollLock={true}
      disablePortal={true}
      sx={{
        height: "900px",
        padding: "20px",
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        top: "20%",
        "& .MuiBackdrop-root": {
          backdropFilter: "blur(5px)",
        },
      }}
    >
      <Box className="modal-container" ref={modalRef}>
        {/* Header Section */}
        <Box className="modal-header" sx={{ position: "relative" }}>
          <Typography
            id="modal-title"
            className="modal-title"
            sx={{
              textDecoration: "underline",
              textDecorationColor: "red",
              textUnderlineOffset: "13px",
              textDecorationThickness: "3px",
              fontFamily: "DM Sans",
            }}
          >
            Part Numbering Highlight
          </Typography>
          <IconButton
            className="icon-btn"
            sx={{ position: "absolute", right: 1, top: 0 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box className="modal-content-box">
          {parts.map((part, index) => (
            <React.Fragment key={index}>
              {/* Part Section */}
              <Box className="part-container">
                <Box className="part-number-box">
                  <Typography className="part-number" sx={{fontFamily: "DM Sans",}}>
                    {part.partNo}{" "}
                    <span style={{ color: "#EC1B24", fontFamily: "DM Sans", }}>{part.partNOSpan}</span>
                  </Typography>
                </Box>

                <Typography className="part-description" sx={{fontFamily: "DM Sans",}}>
                  {part.desc}
                  <span className="part-highlight" style={{fontFamily: "DM Sans",}}>{part.descSpan}</span>
                </Typography>
              </Box>

              {/* Divider */}
              <hr className="part-divider" />
            </React.Fragment>
          ))}
        </Box>
      </Box>
    </Modal>
  );
};
