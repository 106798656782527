import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import logo from "../Assets/pwr-logo.svg";
import youtube from "../Assets/HeaderIcon/youTubeN.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import routes from "../AppRoutes/routes.json";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getNewRealses } from "../Pages/ProductPages/ClassicSearchAction";
import { CommonSetupActions } from "../Slices/Commonslice";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import "./style.css";

const drawerWidth = 250;

const LayoutComponent = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [expandedMenu, setExpandedMenu] = useState(null);

  const toggleMenu = (menuName) => {
    setExpandedMenu((prevMenu) => (prevMenu === menuName ? null : menuName));
  };
  const handleFaqAutoscrolling=()=>{
    if (location.pathname !== routes.Home) {
      dispatch(CommonSetupActions.SetscrollSameFaq(true));
      sessionStorage.clear()
      navigate(routes.Home);
      handleDrawerToggle();
      // setTimeout(() => {
      //   props.scrollToGetInTouch();
      // }, 600); 
    } else {
      dispatch(CommonSetupActions.SetscrollSameFaq(true));
      props.scrollToGetInTouch();
      handleDrawerToggle();
    }

  }
  const handleAutoscrolling = () => {
    if (location.pathname !== routes.Home) {
      dispatch(CommonSetupActions.SetscrollAutomatic(true));
      dispatch(CommonSetupActions.SetscrollSamepage(false))
      sessionStorage.clear()
      navigate(routes.Home);
      handleDrawerToggle();
      // setTimeout(() => {
      //   props.scrollToGetInTouch();
      // }, 600); 
    } else {
      dispatch(CommonSetupActions.SetscrollAutomatic(true));
      dispatch(CommonSetupActions.SetscrollSamepage(true))

      props.scrollToGetInTouch();
      handleDrawerToggle();
    }
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
 const [focusIndex, setFocusIndex] = useState(0);
  // Handle mouse enter for parent element

  // Handle mouse leave for both parent and menu
  const handleMouseLeave = () => {
    setAnchorEl(null); // Close the menu
  };

  const handleClick = (option, route) => {
    dispatch(CommonSetupActions.SetscrollAutomatic(false));
    setAnchorEl(null);
    if (
      option.label === "POWER STEERING PUMP" ||
      option.label === "POWER STEERING RACK" ||
      option.label === "STEERING GEAR BOX"
    ) {
      dispatch(getNewRealses(option.id));
      dispatch(CommonSetupActions.setRadioValue(option.id));
      sessionStorage.clear()
      navigate(route);
      window.scrollTo({ top: 0 });
    } else {
      sessionStorage.clear()
      navigate(route);
      window.scrollTo({ top: 0 });
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "md"));

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <List >
        {/* E-Catlog */}

        {/* Products */}
        <ListItem
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <ListItemButton onClick={() => toggleMenu("products")}>
            <ListItemText sx={{ color: "#ffffff", fontSize: "1rem" }}>
              Products
            </ListItemText>
            {expandedMenu === "products" ? (
              <ExpandLessIcon sx={{ color: "#ffffff" }} />
            ) : (
              <ExpandMoreIcon sx={{ color: "#ffffff" }} />
            )}
          </ListItemButton>
        </ListItem>
        {expandedMenu === "products" && (
          <Box sx={{ pl: 4 }}>
            <ListItemButton
              onClick={() => {
                handleDrawerToggle();
                sessionStorage.clear()
                navigate(routes.SteeringPump);
                window.scrollTo({ top: 0 });
                dispatch(CommonSetupActions.resetState());
                dispatch(CommonSetupActions.SetscrollAutomatic(false));
              }}
            >
              <ListItemText sx={{ color: "#ffffff" }}>Pump</ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                handleDrawerToggle();
                sessionStorage.clear()
                navigate(routes.RackPinion);
                window.scrollTo({ top: 0 });
                dispatch(CommonSetupActions.resetState());
                dispatch(CommonSetupActions.SetscrollAutomatic(false));
              }}
            >
              <ListItemText sx={{ color: "#ffffff" }}>
                Rack & Pinion
              </ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                handleDrawerToggle();
                sessionStorage.clear()
                navigate(routes.SteeringGearBox);
                window.scrollTo({ top: 0 });
                dispatch(CommonSetupActions.resetState());
                dispatch(CommonSetupActions.SetscrollAutomatic(false));
              }}
            >
              <ListItemText sx={{ color: "#ffffff" }}>Gear box</ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                handleDrawerToggle();
                sessionStorage.clear()
                navigate(routes.Reservoirs);
                window.scrollTo({ top: 0 });
                dispatch(CommonSetupActions.resetState());
                dispatch(CommonSetupActions.SetscrollAutomatic(false));
              }}
            >
              <ListItemText sx={{ color: "#ffffff" }}>
              Reservoirs
              </ListItemText>
            </ListItemButton>
          </Box>
        )}

        {/* Resources */}
        <ListItem>
          <ListItemButton onClick={() => toggleMenu("resources")}>
            <ListItemText sx={{ color: "#ffffff", fontSize: "1rem" }}>
              Resources
            </ListItemText>
            {expandedMenu === "resources" ? (
              <ExpandLessIcon sx={{ color: "#ffffff" }} />
            ) : (
              <ExpandMoreIcon sx={{ color: "#ffffff" }} />
            )}
          </ListItemButton>
        </ListItem>
        {expandedMenu === "resources" && (
          <Box sx={{ pl: 4 }}>
            <ListItemButton
              onClick={() => {
                handleDrawerToggle();
                sessionStorage.clear()
                navigate(routes.Articles);
                window.scrollTo({ top: 0 });
                dispatch(CommonSetupActions.resetState());
                dispatch(CommonSetupActions.SetscrollAutomatic(false));
              }}
            >
              <ListItemText sx={{ color: "#ffffff" }}>Articles</ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                handleDrawerToggle();
                sessionStorage.clear()
                navigate(routes.Videos);
                window.scrollTo({ top: 0 });
                dispatch(CommonSetupActions.resetState());
                dispatch(CommonSetupActions.SetscrollAutomatic(false));
              }}
            >
              <ListItemText sx={{ color: "#ffffff" }}>Videos</ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                handleDrawerToggle();
                sessionStorage.clear()
                navigate(routes.Warranty);
                window.scrollTo({ top: 0 });
                dispatch(CommonSetupActions.resetState());
                dispatch(CommonSetupActions.SetscrollAutomatic(false));
              }}
            >
              <ListItemText sx={{ color: "#ffffff" }}>Warranty</ListItemText>
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                handleDrawerToggle();
                sessionStorage.clear()
                navigate(routes.TechnicalInfo);
                window.scrollTo({ top: 0 });
                dispatch(CommonSetupActions.resetState());
                dispatch(CommonSetupActions.SetscrollAutomatic(false));
              }}
            >
              <ListItemText sx={{ color: "#ffffff" }}>Technical Resources </ListItemText>
            </ListItemButton>
          </Box>
        )}

        {/* New Releases */}
        <ListItem>
          <ListItemButton
            onClick={() => {
              handleDrawerToggle();
              sessionStorage.clear()
              navigate(routes.Ecatlog);
              window.scrollTo({ top: 0 });

              dispatch(CommonSetupActions.SetscrollAutomatic(false));
            }}
          >
            <ListItemText sx={{ color: "#ffffff", fontSize: "1rem" }}>
              E-Catalog
            </ListItemText>
          </ListItemButton>
        </ListItem>

        {/* About Us */}
        <ListItem>
          <ListItemButton
            onClick={() => {
              sessionStorage.clear()

              navigate(routes.About);
              window.scrollTo({ top: 0 });
              dispatch(CommonSetupActions.resetState());
            }}
          >
            <ListItemText sx={{ color: "#ffffff", fontSize: "1rem" }}>
              About Us
            </ListItemText>
          </ListItemButton>
        </ListItem>

        {/* Contact Us */}
        <ListItem>
          <ListItemButton
            onClick={() => {
             
              handleAutoscrolling();
              // dispatch(CommonSetupActions.resetState());
            }}
          >
            <ListItemText sx={{ color: "#ffffff", fontSize: "1rem" }}>
              Contact Us
            </ListItemText>
          </ListItemButton>
        </ListItem>

        {/* FAQ */}
        <ListItem>
          <ListItemButton
            onClick={() => {
              handleFaqAutoscrolling();
            }}
          >
            <Box sx={{ display: "flex", gap: 1 }}>
              <HelpOutlineIcon style={{ fill: "#EC1B24" }} />
              <Typography sx={{ fontSize: "14px", color: "#8A8A8A",fontFamily: "DM Sans", }}>
                FAQ's
              </Typography>
            </Box>
          </ListItemButton>
        </ListItem>

        {/* Support */}
        <ListItem>
          <ListItemButton
            onClick={() => {
              handleAutoscrolling();
            }}
          >
            <ListItemText sx={{ color: "#ffffff",fontFamily: "DM Sans", fontSize: "1rem" }}>
              Support
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  

  const scrollToGetInTouch = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth", 
    });
  };
  
  const handleKeyDown = (e) => {
    // Handle arrow down key to open dropdown
    if (e.key === "ArrowDown") {
      setDropdownOpen(true); // Open the dropdown
      setFocusIndex(0); // Set focus to the first item when dropdown opens
    }

    // Handle arrow up to move focus up
    if (e.key === "ArrowUp" && focusIndex > 0) {
      setFocusIndex(focusIndex - 1);
    }

    // Handle arrow down to move focus down
    if (e.key === "ArrowDown" && focusIndex < 2) { // 2 is the number of items in this example
      setFocusIndex(focusIndex + 1);
    }

    // Handle enter key to navigate
    if (e.key === "Enter") {
      if (dropdownOpen) {
        if (focusIndex === 0) {
          sessionStorage.clear()
          navigate(routes.SteeringPump);
        } else if (focusIndex === 1) {
          sessionStorage.clear()
          navigate(routes.RackPinion);
        } else if (focusIndex === 2) {
          sessionStorage.clear()
          navigate(routes.SteeringGearBox);
        }
        window.scrollTo({ top: 0 });
      } else {
        setDropdownOpen(true); // Open dropdown if it's not open
        setFocusIndex(0); // Focus on the first item
      }
    }
  };

  return (
    <>
      {/* <CssBaseline /> */}
      <AppBar
        component="nav"
        position="fixed"
        sx={{
          backgroundColor: "black",
          // opacity:0.6,
          width: "100%",
          // display: { xs: "block", sm: "block" },
          // zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar sx={{ width: "100%" }}>
          <IconButton 
          className="menu-icon"
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              color: "#ffffff",
              display: { xs: "block", sm: "block", md: "none" },
            }}
            tabIndex={0}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box className="pwr-logo-icon-box"
              sx={{ width: "20%" }}
              onClick={() => {
                dispatch(CommonSetupActions.SetscrollAutomatic(false));
                sessionStorage.clear()
                navigate(routes.Home);
                window.scrollTo({ top: 0 });
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  sessionStorage.clear()
                  dispatch(CommonSetupActions.SetscrollAutomatic(false));
                  navigate(routes.Home);
                  window.scrollTo({ top: 0 });
                    }
              }}

              tabIndex={0} // Make focusable
              role="button"
              aria-label="Navigate to Home"
            >
              <img
                src={logo}
                alt="logo"
                style={{
                  cursor: "pointer",
                  width: "100%",
                  height: "auto",
                  objectFit: "contain",
                }}
              />
            </Box>
            {/* desktop */}
            <Box
              sx={{
                width: "80%",
                // display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                // gap: 5,
                // marginLeft: 1,
                display: { xs: "none", sm: "none", md: "flex" }, // Hide on mobile
              }}
            >
              <Box
                className="navbar-items"
                sx={{
                  width: "60%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 5,
                }}
              >
                {/* {["Products", "Resources"].map((item) => (
                  <div
                    key={item}
                    // onMouseEnter={(e) => handleMouseEnter(e, item)}
                    onMouseLeave={handleMouseLeave}
                    onMouseOver={(e) => handleMouseEnter(e, item)}
                    // onBlur={handleBlur} // Close menu on blur
                        tabIndex={0} // Make the div focusable
                    
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 1,
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "#8A8A8A",
                       
                      }}
                    >
                      {item}
                    </Typography>
                    <KeyboardArrowDownIcon style={{ fill: "#EC1B24" }} />
                  </div>
                ))} */}

                {/* Products */}

               <Box
      className="dropdown"
      tabIndex={0}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
      // onKeyDown={handleKeyDown}
    >
      <Typography className="dropbtn lay-dropdown-btn" sx={{ textTransform: "none",
                      fontWeight: 500,
                      fontFamily: "DM Sans",
                      fontSize: "16px",
                      color: "#8A8A8A",
                      fontFamily:'DM Sans',
                      "&:hover": {
                        color: "red",
                      },}} tabIndex={0}>
        Products
        <KeyboardArrowDownIcon style={{ fill: "#EC1B24", marginLeft: 5 }} />
      </Typography>

      {/* {dropdownOpen && ( */}
        <Box className="dropdown-content" tabIndex={0}>
          <Box
            sx={{
              width: "100%",
              height: "2px",
              backgroundColor: "red",
            }}
          ></Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: 2,
              gap: 1,
            }}
          >
            <Typography
            className="lay-dropdown-btn"
              tabIndex={0}
              onClick={() => {
                sessionStorage.clear()
                navigate(routes.SteeringPump);
                window.scrollTo({ top: 0 });
              }}
              sx={{
                textTransform: "none",
                fontFamily: "DM Sans",
                fontWeight: 500,
                fontSize: "15px",
                color: "#ffffff", // highlight focused item
                "&:hover": {
                  color: "red",
                },
              }}
            >
              Pump
            </Typography>
            <Typography
            className="lay-dropdown-btn"
              tabIndex={0}
              onClick={() => {
                sessionStorage.clear()
                navigate(routes.RackPinion);
                window.scrollTo({ top: 0 });
              }}
              sx={{
                textTransform: "none",
                fontWeight: 500,
                fontFamily: "DM Sans",
                fontSize: "15px",
                color: focusIndex === 1 ? "red" : "#ffffff", // highlight focused item
                "&:hover": {
                  color: "red",
                },
              }}
            >
              Rack & Pinion
            </Typography>
            <Typography
            className="lay-dropdown-btn"
              tabIndex={0}
              onClick={() => {
                sessionStorage.clear()
                navigate(routes.SteeringGearBox);
                window.scrollTo({ top: 0 });
              }}
              sx={{
                textTransform: "none",
                fontWeight: 500,
                fontFamily: "DM Sans",
                fontSize: "15px",
                color: focusIndex === 2 ? "red" : "#ffffff", // highlight focused item
                "&:hover": {
                  color: "red",
                },
              }}
            >
              Gearbox
            </Typography>

            <Typography
            className="lay-dropdown-btn"
              tabIndex={0}
              onClick={() => {
                sessionStorage.clear()
                navigate(routes.Reservoirs);
                window.scrollTo({ top: 0 });
              }}
              sx={{
                textTransform: "none",
                fontWeight: 500,
                fontFamily: "DM Sans",
                fontSize: "15px",
                color: focusIndex === 2 ? "red" : "#ffffff", 
                "&:hover": {
                  color: "red",
                },
              }}
            >
              Reservoirs
            </Typography>
          </Box>
        </Box>
      {/* )} */}
    </Box>

                {/* Resources */}

                <Box
                  tabIndex={0}
                  className="dropdown2"
                  sx={{
                    display: "flex",
                    alignItems: "center", // Align vertically
                    justifyContent: "center", // Align horizontally
                    cursor: "pointer",
                  }}
                >
                  <Typography className="dropbtn2 lay-dropdown-btn"  sx={{ textTransform: "none",
                      fontWeight: 500,
                      fontSize: "16px",
                      color: "#8A8A8A",
                      fontFamily:'DM Sans',
                      "&:hover": {
                        color: "red",
                      },}}>
                    Resources
                    <KeyboardArrowDownIcon
                      style={{ fill: "#EC1B24", marginLeft: 5 }}
                    />
                  </Typography>
                  <Box className="dropdown-content2">
                    <Box
                      sx={{
                        width: "100%",
                        height: "2px",
                        backgroundColor: "red",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        padding: 2,
                        gap: 1,
                      }}
                    >
                      <Typography className="lay-dropdown-btn"
                        onClick={() => {
                          sessionStorage.clear()
                          navigate(routes.Articles);
                          window.scrollTo({ top: 0 });
                        }}
                        sx={{
                          textTransform: "none",
                          fontWeight: 500,
                          fontSize: "15px",
                          fontFamily: "DM Sans",
                          color: "#ffffff",
                          "&:hover": {
                            color: "red",
                          },
                        }}
                      >
                        Articles
                      </Typography>
                      <Typography className="lay-dropdown-btn"
                        onClick={() => {
                          sessionStorage.clear()
                          navigate(routes.Videos);
                          window.scrollTo({ top: 0 });
                        }}
                        sx={{
                          textTransform: "none",
                          fontWeight: 500,
                          fontFamily: "DM Sans",
                          fontSize: "15px",
                          color: "#ffffff",
                          "&:hover": {
                            color: "red",
                          },
                        }}
                      >
                        Videos
                      </Typography>
                      <Typography className="lay-dropdown-btn"
                        onClick={() => {
                          sessionStorage.clear()
                          navigate(routes.Warranty);
                          window.scrollTo({ top: 0 });
                        }}
                        sx={{
                          textTransform: "none",
                          fontWeight: 500,
                          fontFamily: "DM Sans",
                          fontSize: "15px",
                          color: "#fffffff",
                          "&:hover": {
                            color: "red",
                          },
                        }}
                      >
                        Warranty
                      </Typography>
                      <Typography 
                      className="lay-dropdown-btn"
                        onClick={() => {
                          sessionStorage.clear()
                          navigate(routes.TechnicalInfo);
                          window.scrollTo({ top: 0 });
                        }}
                        sx={{
                          textTransform: "none",
                          fontWeight: 500,
                          fontFamily: "DM Sans",
                          fontSize: "15px",
                          color: "#fffffff",
                          "&:hover": {
                            color: "red",
                          },
                        }}
                      >
                        Technical Resources 
                      </Typography>
                    </Box>
                  </Box>
                </Box>

{/* e-catalog */}
                <div
                  tabIndex={0}
                  id="e-catlog"
                  onClick={() => {
                    sessionStorage.clear()
                    navigate(routes.Ecatlog);
                    window.scrollTo({ top: 0 });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      sessionStorage.clear()
                      e.preventDefault(); 
                      sessionStorage.clear()
                      navigate(routes.Ecatlog);
                    }
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    cursor: "pointer",
                  }}
                >
                  <Typography
                  className="headerText lay-dropdown-btn"
                    sx={{
                      textTransform: "none",
                      fontWeight: 500,
                      fontFamily:'DM Sans',
                      fontSize: "16px",
                      color: "#8A8A8A",
                      "&:hover": {
                        color: "red",
                      },
                    }}
                  >
                    E-Catalog
                  </Typography>
                </div>
                {/* about */}
                <div
                  tabIndex={0}
                  id="about"
                  onClick={() => {
                    localStorage.setItem("path", location.pathname);
                    sessionStorage.clear()
                
                    navigate(routes.About);
                    window.scrollTo({ top: 0 });
                    dispatch(CommonSetupActions.resetState());
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      localStorage.setItem("path", location.pathname);
                      sessionStorage.clear()
                      navigate(routes.About);
                      window.scrollTo({ top: 0 });
                      dispatch(CommonSetupActions.resetState());
                    }
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    cursor: "pointer",
                  }}
                >
                  <Typography
                     className="headerText lay-dropdown-btn"
                    sx={{
                      textTransform: "none",
                      fontWeight: 500,
                      fontSize: "16px",
                      fontFamily:'DM Sans',
                      color: "#8A8A8A",
                      "&:hover": {
                        color: "red",
                      },
                    }}
                  >
                    About Us
                  </Typography>
                </div>

                {/* <Link style={{ textTransform:'none', fontWeight: 500,
                      fontSize: "14px",
                      color: "#8A8A8A",
                      textDecoration:'none',
                      "&:hover": {
                        color: "red",
                      },}} to={{pathname:routes.About,}}  >About Us </Link> */}
                <div
                  tabIndex={0}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleAutoscrolling();
                    // dispatch(CommonSetupActions.resetState());
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      handleAutoscrolling();
                      // dispatch(CommonSetupActions.resetState());
                      }
                  }}

                >
                  <Typography
                     className="headerText lay-dropdown-btn"
                    sx={{
                      fontWeight: 500,
                      fontSize: "16px",
                      fontFamily:'DM Sans',
                      color: "#8A8A8A",
                      "&:hover": {
                        color: "red",
                      },
                    }}
                  >
                    Contact Us
                  </Typography>
                </div>
              </Box>
              <Box
                sx={{
                  width: "40%",
                  flexDirection: "row",
                  gap: 3,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  
                }}
              >
                <Box sx={{  "& img": {
      transition: "opacity 0.3s ease-in-out",
    },
    "&:hover img": {
      opacity: 0.7, // Slight fade effect on hover
    },}}>
                  <a
                    href="https://www.youtube.com/@PWRSteer"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={youtube} alt="youtube" />
                  </a>
                </Box>
                <Box
                  tabIndex={0}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    handleFaqAutoscrolling();
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      handleFaqAutoscrolling();

                    }
                  }}

                >
                  <HelpOutlineIcon style={{ fill: "#EC1B24" }} />
                  <Typography
                     className="headerText lay-dropdown-btn"
                    sx={{
                      fontWeight: 500,
                      fontSize: "14px",
                      fontFamily: "DM Sans",
                      color: "#8A8A8A",
                      "&:hover": {
                        color: "red",
                      },
                    }}
                  >
                    FAQ's
                  </Typography>
                </Box>
                <Box>
                  <Button className="lay-dropdown-btn"
                    tabIndex={0}
                    variant="contained"
                    startIcon={<PermIdentityIcon />}
                       
                    sx={{
                      backgroundColor: "#EC1B24",
                      width: "112px",
                      height: "42px",
                      borderRadius: "100px",
                      fontFamily: "DM Sans",
                      padding: 2,
                      textTransform: "none",
                      "&:hover": {backgroundColor:'#a51219' },

                    }}
                    onClick={() => {
                      handleAutoscrolling();
                    }}
                  >
                    Support
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      {isMobile && (
        <nav>
          <Drawer
            // container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                backgroundColor: "black",
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      )}
      {/* Dropdown Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMouseLeave}
        onMouseEnter={() => setAnchorEl(anchorEl)} // Keep menu open when hovering over it
        onMouseLeave={handleMouseLeave} // Close menu on mouse leave
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{
          "& .MuiPaper-root": {
            marginTop: 1,
            backgroundColor: "#000000", // Change background to black
            padding: 1,
            //  boxShadow: "0px 4px 15px rgba(255, 0, 0, 0.4)",
            // position: "relative", // Required for the pseudo-element
            // Add the red line at the top of the menu
            "&:before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "4px", // Thickness of the red line
              backgroundColor: "red", // Color of the line
            },
          },
        }}
      >
        {menuItems.map((option, index) => (
          <MenuItem
            key={index}
            onClick={() => handleClick(option, option.route)}
            sx={{
              color: "white", // MenuItem text color
              padding: "4px 8px",

              "&:hover": {
                backgroundColor: "#333333", // Optional: Add hover effect for the menu item
              },
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>

     
      {/* <div> */}
        <Toolbar />
       
        {props.children}
      {/* </div> */}
    </>
  );
};

export default LayoutComponent;
