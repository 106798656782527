import { Box, Typography } from "@mui/material";
import React from "react";
import PwrSteer from "../../Assets/PwrSteer.png";
import "../Home/Css/Home.css";
import pwrHomeimg from "../../Assets/BackgroudImages/homePageBg.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ReliabilityAndQuality = () => {
  return (
    <Box>
      <Box
        className="box_relibility"
        sx={{
          width: "100%",
          height: "430px",
          background:
            "radial-gradient(344.26% 169.01% at 50% 50.17%, #C61119 0%, #860F14 99.65%)",
          display: "flex",
          // alignItems: "center",

          flexDirection: "row",
          position: "relative",
        }}
      >
        <Box
          className="main_ralibilty_box"
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            flexWrap:'wrap'
          }}
        >
          <Box className="relibility_text" sx={{ width: "50%" ,display:'flex',justifyContent:'center',alignItems:'center'}}>
           <Box className="relibility_text_sub_comp" sx={{width:'75%'}}>
           <Typography
              className="Realibility_main_text"
              sx={{
                fontWeight: 700,
                fontSize: "50px",
                color: "#FFFFFF",
                fontFamily: "DM Sans",
                lineHeight:'50px'
              }}
            >
              Built for Reliability, Backed by Quality
            </Typography>
           </Box>
          </Box>

          <Box
          className="reilaibility_main_content"
          sx={{ width: "50%", display: "flex", flexDirection: "column" }}
        >
         <Box sx={{width:'85%'}}           className="reilaibility_sub_content"
         >
         <Typography className="reliability-main-typo"
            sx={{
              fontWeight: 500,
              fontSize: "16px",
              lineHeight:"25px",
              color: "#FFFFFF",
              fontFamily: "DM Sans",
              textAlign:'left'
            }}
          >
            At PWR Steer, we pride ourselves on producing 100% new,
            installation-ready power steering systems designed to withstand the
            demands of today’s vehicles. Our warranty rates are consistently
            lower than the industry average, ensuring you receive a product
            built for longevity and reliability*. Unlike many aftermarket
            competitors, whose products often fail within the first 30 days, PWR
            Steer parts are crafted to exceed expectations right out of the box.
            Our commitment to quality and durability sets us apart in the
            industry, delivering peace of mind to every customer. When your
            reputation is on the line, choose PWR Steer.
          </Typography>

          <Typography
          className="reliability-sub-typo"
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                color: "#FFFFFF",
                fontFamily: "DM Sans",
                marginTop:3,
                lineHeight:'25px',
                textAlign:'left'

              }}
            >
              *Based on internal data, indicating a significantly lower failure
              rate compared to industry standards.*
            </Typography>
         </Box>
        
        </Box>

        </Box>


       
      </Box>




      <Box className="image_relibility" sx={{ width: "100%", height: "497px" }}>
        <Box
          className="image_relibilty_container"
          sx={{
            position: "absolute",
            marginTop: "-50px",
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <LazyLoadImage
            className="relibility_img"
            // src={PwrSteer}
            src={pwrHomeimg}
            alt="steer"
            // style={{ width: "80%", height: "500px" }}
          />
        </Box>
      </Box>


    </Box>
  );
};

export default ReliabilityAndQuality;
