import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, IconButton, Typography } from "@mui/material";

import Download from "../../../Assets/download.png";
import "../ProductDisplay/Productpage.css";
import { ImagesModal } from "./ImagesModal";
import { SideBySideMagnifier } from "react-image-magnifiers";
import rotatingicon from "../../../Assets/ProductImages/360icon.jpg";

import { ApiLink } from "../../Utils/Apilink";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useLocation } from "react-router-dom";
import { getProductAppData2 } from "./GearBoxAction";
import CommonLoader from "../../CommonComponent/CommonLoader";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const ProductSpecification = () => {
  const [open, setOpen] = useState(false);
  const [rotatingImageArray, setRotatingImageArray] = useState([]);
  const [threeSixtyView, setThreeSixtyView] = useState(false);
  const loc = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();

  const url = loc.pathname;
  const regex = /\/product-page\/([^\/]+)/;
  const match = url.match(regex);

  // Extracted part number
  const partNumber = match ? match[1] : null;
  console.log("part no: ", partNumber);
  console.log(partNumber);

  const handleClosee = () => {
    setOpenModal(false);
  };
  const handleOpen = () => {
    setOpenModal(true);
  };
  useEffect(() => {
    if (partNumber) {
      dispatch(getProductAppData2(partNumber,setOpenModal));
    }
  }, [dispatch, partNumber]);
  const handleClick = () => {
    if (threeSixtyView === false) {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (window.Magic360) {
      window.Magic360.start(); // Initialize Magic 360
    }
  }, [threeSixtyView]);

  // useEffect(() => {
  //   if (!threeSixtyView) {
  //     // Cleanup Magic360 when not in use
  //     if (window.Magic360) {
  //       window.Magic360.stop();
  //     }
  //   }
  // }, [threeSixtyView]);

  const { productImages } = useSelector((state) => state.CommonStore);
  const {
    specification,
    selectedProduct,
    selectedPart,
    selectYear,
    selectMake,
    selectModel,
  } = useSelector((state) => state.CommonStore);

  console.log("productimage",productImages)
  const [currentImg, setCurrentImg] = useState( productImages[0]);

  useEffect(() => {
    try {
      fetch(`${ApiLink}images360/${selectedPart.partNumber}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .then((respimages) => respimages.json())
        .then((imagesRes) => {
          setRotatingImageArray(imagesRes);
        });
      // }
    } catch (err) {
      console.log("err", err);
    }
  }, []);
  console.log("location;;;;;", loc.pathname, selectedProduct);

  const getProductName = () => {
    const formatNewPartTypeName = (name) =>
      name
        .replace(/-/g, " ") // Replace hyphens with spaces
        .toLowerCase() // Convert to lowercase
        .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word

    if (selectedProduct.type === "Part Numbers") {
      return `${selectedProduct.label} - ${
        selectedProduct.value.split("===")[0]
      }`;
    } else if (
      selectedProduct.type === "Applications" ||
      selectedProduct.type === "VIN"
    ) {
      if (selectedProduct.type === "VIN") {
        return `${selectedPart.partNumber} - ${formatNewPartTypeName(
          selectedPart.newparttypename
        )}`;
      } else {
        return `${selectedPart.partNumber} - ${
          selectedProduct.label.split(" ")[0]
        }  ${selectedProduct.label.split(" ")[1]}  ${
          selectedProduct.label.split(" ")[2]
        }  ${formatNewPartTypeName(selectedPart.newparttypename)}`;
      }
    } else {
      if (selectedPart.hasOwnProperty("ymm")) {
        return `${selectedPart.partNumber} - ${selectedPart.ymm.replace(
          /-/g,
          " "
        )}  ${formatNewPartTypeName(selectedPart.newparttypename)}`;
      } else {
        return `${selectedPart.partNumber} - ${selectYear}  ${
          selectMake.label
        } ${selectModel.label}  ${formatNewPartTypeName(
          selectedPart.newparttypename
        )}`;
      }
    }
  };

  console.log("selected", selectedProduct);

  const getProductDescription = () => {
    if (
      selectedPart.newparttypename === "Rack and Pinion Assembly" ||
      selectedPart.newparttypename === "rack-and-pinion-assembly" ||
      loc.pathname.split("/")[5] === "rack-and-pinion-assembly"
    ) {
      return `At PWR Steer, we’re committed to delivering power steering products that exceed expectations. Engineered for precision and built for longevity, our 100% new Rack and Pinion systems eliminate the risks of remanufactured parts. Every unit is designed to optimize installation efficiency, reduce failures, and enhance customer satisfaction.
`;
    } else if (
      selectedPart.newparttypename === "power-steering-pump" ||
      selectedPart.newparttypename === "power steering pump" ||
      loc.pathname.split("/")[5] === "power-steering-pump"
    ) {
      return `PWR Power Steering Pumps are engineered to deliver unmatched durability, precision, and reliability. Every pump is designed to enhance installation efficiency and provide long-lasting performance under real-world conditions. From advanced testing to innovative design, PWR pumps deliver the confidence technicians and drivers demand.
`;
    } else if (
      selectedPart.newparttypename === "steering-gear" ||
      selectedPart.newparttypename === "steering gear" ||
      loc.pathname.split("/")[5] === "steering-gear"
    ) {
      return `PWR Gear Boxes are engineered to deliver the durability and precision required for seamless operation. Every unit is 100% new, eliminating the challenges associated with remanufactured products, such as inconsistent performance and early failure rates. PWR delivers the confidence technicians and drivers demand.
`;
    }
  };

  const [isZoomEnabled, setIsZoomEnabled] = useState(window.innerWidth > 850);

  return (
    <Box
      className="productspecification_main_container"
      sx={{
        width: "100%",
        display: "flex",
        // flexWrap: "wrap",
        gap: "45px",
        justifyContent: "center",
      }}
    >
      {/* First Div */}
      <Box
        className="product_description_box"
        sx={{
          width: "25%",
          // width: specification?.length > 0 ? "30%" : "45%",
          // maxWidth: "356px",
          height: "auto",
          color: "#252525",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            width: "100%",
            // width: "360px",
            fontFamily: "DM Sans",
            fontWeight: 800,
            fontSize: "23px",
            lineHeight: "30px",
            color: "#d1191f",
          }}
        >
          {(selectedPart && selectedPart.hasOwnProperty("ymm")) ||
          (selectedProduct && selectedProduct.hasOwnProperty("type"))
            ? getProductName()
            : `${loc.pathname.split('/')[3]}-${loc.pathname.split('/')[4].replace(/-/g, ' ')} ${loc.pathname.split('/')[5].replace(/-/g, ' ')}`}
        </Typography>

        <hr
          style={{
            width: "100%",
            // marginTop: "25px",
            color: "#3F3F3F",
          }}
        />

        <Typography className="prod-desc-typo"
          sx={{
            fontFamily: "DM Sans",
            fontWeight: 700,
            fontSize: "16px",
          }}
        >
          Product Description
        </Typography>
        <Typography
          sx={{
            fontFamily: "DM Sans",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "25px",
            marginTop: "10px",
          }}
        >
          {getProductDescription()}
        </Typography>
        <hr
          style={{
            width: "100%",
            marginTop: "25px",
            color: "#3F3F3F",
          }}
        />
        <Typography className="to-know-more-typo"
          sx={{
            width: "111px",
            height: "11px",
            fontFamily: "DM Sans",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "27px",
            color: "#252525",
          }}
        >
          To Know More
        </Typography>
        <Box
          sx={{
            width: "349px",
            height: "50px",
            border: "1px solid #EC1B24",
            borderRadius: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
          }}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              window.open(
                "https://www.pwrsteer.com/uploads/PWRBrochureSinglePage1124.pdf",
                "_blank",
                "noopener,noreferrer"
              );
            }
          }}
        >
          <Button
            onClick={() => {
              window.open(
                "https://www.pwrsteer.com/uploads/PWRBrochureSinglePage1124.pdf",
                "_blank",
                "noopener,noreferrer"
              );
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              width: "100%",
              height: "100%",
              border: "none",
              padding: "0",
              textTransform: "none",
              color: "#FFFFFF",
            }}
          >
            <img
              alt="download"
              src={Download}
              sx={{
                width: "22px",
                height: "18.33px",
                color: "#FFFFFF",
              }}
            />
            <span
              style={{
                fontFamily: "DM Sans",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "22px",
                color: "#EC1B24",
              }}
            >
              Download Brochure
            </span>
          </Button>
        </Box>
      </Box>

      {/* Image Section .. second div */}
      <Box
        className="image-specification-common-div"
        style={{
          width: "70%",
          // width: specification?.length > 0  ? "70%" : "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: "40px",
        }}
      >
        <Box
          className="product_second_div"
          style={{
            width: "50%",
            // height: "100%",
            // height: "500px",

            // position: 'relative',
            // border: '1px solid #ccc',
          }}
        >
          <Box
            sx={{
              border: "1px solid #ccc",
              height:productImages && productImages.length>0 && productImages[0].fileName  ? "auto" : "400px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={currentImg?.fileName ? handleClick : undefined}
            //  onWheel={handleWheel}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                handleClick();
              }
            }}
          >
            {productImages && productImages.length>0 && productImages[0].fileName ? (
              <>
                <Box
                  sx={{
                    display: threeSixtyView ? "none" : "block",
                    height: "100%",
                  }}
                >
                  {isZoomEnabled ? (
                    <SideBySideMagnifier
                      className="original-side-zoom-img"
                      imageSrc={currentImg?.fileName || productImages[0].fileName}
                      imageAlt="Magnified Example"
                      largeImageSrc={currentImg?.fileName || productImages[0].fileName}
                      zoomScale={2}
                      zoomWidth={900}
                      zoomHeight={200}
                      cursorStyle="crosshair"
                      fillAvailableSpace={true}
                      fillGapTop={170}
                      overlayBoxOpacity={0.6}
                      overlayBoxColor="#fff"
                      overlayBackgroundColor="#000"
                    />
                  ) : (
                    <LazyLoadImage
                      src={currentImg.fileName}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                      alt={currentImg.partNumber || "Image"}
                    />
                  )}
                </Box>

                <Box sx={{ display: threeSixtyView ? "block" : "none" }}>
                  <div
                    id="spin"
                    href={`https://www.pwrsteer.com/uploads/assets/360-images/${selectedPart.partNumber}/${selectedPart.partNumber}-01-01.jpg`}
                    // style={{ display: threeSixtyView ? "block" : "none" }}
                    class="Magic360"
                    data-options={`rows:3;columns:24;hint:false;fullscreen:true;magnify: false;`}
                  >
                    <img
                      src={`https://www.pwrsteer.com/uploads/assets/360-images/${selectedPart.partNumber}/${selectedPart.partNumber}-01-01.jpg`}
                      alt="360viewer"
                    />
                  </div>
                  {threeSixtyView && (
                    <Box
                      sx={{
                        width: "100%",
                        paddingBottom: 2,
                        justifyContent: "center",
                        alignItems: "center",

                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                      }}
                    >
                      <Box
                        sx={{
                          width: "64.84px",
                          height: "49.28px",
                          border: "0.5px solid #808080",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "2px",
                        }}
                      >
                        {" "}
                        <IconButton
                          sx={{
                            padding: 0,
                            "&:hover": {
                              backgroundColor: "#ffffff",
                              padding: 0,
                            },
                          }}
                          onClick={() => {
                            window.Magic360.pause("spin");
                            window.Magic360.spin("spin", 1);
                          }}
                        >
                          <ArrowLeftIcon
                            sx={{ color: "#000000", fontSize: "50px" }}
                          />
                        </IconButton>
                      </Box>
                      <Box
                        sx={{
                          width: "64.84px",
                          height: "49.28px",
                          border: "0.5px solid #808080",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "2px",
                        }}
                      >
                        {" "}
                        <IconButton
                          sx={{
                            padding: 0,
                            "&:hover": {
                              backgroundColor: "#ffffff",
                              padding: 0,
                            },
                          }}
                          onClick={() => {
                            window.Magic360.pause("spin");
                            window.Magic360.spin("spin", -1);
                          }}
                        >
                          <ArrowRightIcon
                            sx={{ color: "#000000", fontSize: "50px" }}
                          />
                        </IconButton>
                      </Box>
                      <Box
                        sx={{
                          width: "64.84px",
                          height: "49.28px",
                          border: "0.5px solid #808080",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "2px",
                        }}
                      >
                        {" "}
                        <IconButton
                          sx={{
                            padding: 0,
                            "&:hover": {
                              backgroundColor: "#ffffff",
                              padding: 0,
                            },
                          }}
                          onClick={() => {
                            window.Magic360.pause("spin");
                            window.Magic360.jump("spin", 1);
                          }}
                        >
                          <ArrowDropUpIcon
                            sx={{ color: "#000000", fontSize: "50px" }}
                          />
                        </IconButton>
                      </Box>
                      <Box
                        sx={{
                          width: "64.84px",
                          height: "49.28px",
                          border: "0.5px solid #808080",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "2px",
                        }}
                      >
                        {" "}
                        <IconButton
                          sx={{
                            padding: 0,
                            "&:hover": {
                              backgroundColor: "#ffffff",
                              padding: 0,
                            },
                          }}
                          onClick={() => {
                            window.Magic360.pause("spin");
                            window.Magic360.jump("spin", -1);
                          }}
                        >
                          <ArrowDropDownIcon
                            sx={{ color: "#000000", fontSize: "50px" }}
                          />
                        </IconButton>{" "}
                      </Box>
                    </Box>
                  )}
                </Box>
              </>
            ) : (
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 600,
                  fontSize: "18px",
                  color: "#808080",
                }}
              >
                Photo Coming Soon
              </Typography>
            )}
          </Box>
          {/* Thumbnails */}
          <Box
            sx={{
              display: "flex",
              gap: "6px",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              flexDirection: "row",
              flexWrap: "wrap",
              marginTop: "10px",
            }}
          >
            {productImages && productImages.length > 1
              ? productImages.map((img) => {
                  const isActive =
                    currentImg && currentImg.fileName === img.fileName;
                  return (
                    <Box
                      key={img.img_id}
                      sx={{
                        width: "64.84px",
                        height: "49.28px",
                        border: isActive
                          ? "0.5px solid #EC1B24"
                          : "0.5px solid #808080",
                        borderRadius: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "2px",
                      }}
                      onClick={() => {
                        setCurrentImg(img);
                        setThreeSixtyView(false);
                      }}
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          setCurrentImg(img);
                        }
                      }}
                    >
                      <LazyLoadImage
                        src={img.fileName}
                        alt={img.partNumber}
                        style={{
                          width: "42.3px",
                          height: "42.3px",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  );
                })
              : null}

            {rotatingImageArray.length > 0 && (
              <Box
                sx={{
                  width: "64.84px",
                  height: "49.28px",
                  border: "0.5px solid #808080",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "2px",
                }}
              >
                <IconButton
                  sx={{ padding: 0, width: "100%", height: "auto" }}
                  onClick={() => {
                    setThreeSixtyView(!threeSixtyView);
                    threeSixtyView
                      ? window.Magic360.spin("spin")
                      : window.Magic360.start();
                  }}
                >
                  <img
                    src={rotatingicon}
                    style={{ width: "80%" }}
                    alt="360 rotation"
                  />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>

        {/* </Box> */}
        {/* {specification?.length > 0 && !isHovered && ( */}
        {specification?.length > 0 && (
          <Box
            className="product-third-div"
            sx={{
              width: "50%",
              height: "auto",
              // position: 'relative',
              //  width: "30%",
              //  height: "auto",
              //  maxHeight: "500px",
              //  overflow: "hidden",
            }}
          >
            <Box className="product-third-sub-box"
              sx={{
                borderRadius: "13px",
                border: "1px solid #ccc",
                backgroundColor: "#EAEAEA",
                                  width:'100%',

              }}
            >
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 700,
                  fontSize: "16px",
                  color: "#55120B",
                  padding: "20px",
                }}
              >
                Product Specification
              </Typography>
              <Box
                sx={{
                  // maxHeight: "415px",
                  maxHeight: "500px",
                  marginBottom: "10px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <tbody>
                    {specification.slice(0, 9).map((spec, index) => (
                      <tr
                        key={spec.PAID}
                        style={{
                          backgroundColor:
                            index % 2 === 0 ? "#FFFFFF" : " #EAEAEA",
                        }}
                      >
                        <td style={{ textAlign: "left", padding: "20px", fontFamily: "DM Sans", }}>
                          {spec.product_specs_name}
                        </td>
                        <td style={{ textAlign: "center", padding: "20px", fontFamily: "DM Sans", }}>
                          {spec.product_specs_value}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <CommonLoader
        open={openModal}
        handleOpen={handleOpen}
        handleClose={handleClosee}
      />

      <ImagesModal
        open={open}
        handleClose={handleClose}
        currentImg={currentImg}
        setCurrentImg={setCurrentImg}
      />
    </Box>
  );
};
