import {
  Box,
  
  Container,
  
  IconButton,
  
  Typography,
} from "@mui/material";
import React from "react";
import youtube from "../../Assets/HeaderIcon/youTubeN.svg";

import linkedIn from "../../Assets/HeaderIcon/linkedinN.svg";
import Pdf from "../../Assets/Home/pdfN.svg";
import { useLocation, useNavigate } from "react-router-dom";

import business from "../../Assets/HeaderIcon/businessN.svg";
import norton from "../../Assets/HeaderIcon/nortonN.svg";
import strip from "../../Assets/HeaderIcon/stripN.svg";
import routes from "../../AppRoutes/routes.json";
import "../Footer/Footer.css";
import { useDispatch } from "react-redux";
import { CommonSetupActions } from "../../Slices/Commonslice";
import FileDownloadIcon from '@mui/icons-material/FileDownload';


const CommonFooter = ({ scrollToGetInTouch }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
const currentYear = new Date().getFullYear();
  const handleAutoscrolling = () => {
    if (location.pathname !== routes.Home) {
            dispatch(CommonSetupActions.SetscrollSamepage(false))
      
      dispatch(CommonSetupActions.SetscrollAutomatic(true));
      sessionStorage.clear();
      navigate(routes.Home);
    } else {
            dispatch(CommonSetupActions.SetscrollSamepage(true))
      
      scrollToGetInTouch();
    }
  };

  
  return (
    <Box
      className="footer_container"
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        // marginTop: 5,
      }}
    >
      <Box
        sx={{ width: "100%", height: "11px", backgroundColor: "#EA1B24" }}
      ></Box>
         

      <Box
        className="footer_maincontainer"
        sx={{
          width: "100%",
          height: "auto",
          backgroundColor: "#000000",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          paddingTop: 5,

          //   paddingLeft: 6,
        }}
      >
         <Container>
        <Box
          className="footer_first_container"
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            gap: "30px",
            justifyContent: "center",
          }}
        >
          <Box
            className="footer_first_container_sub"
            sx={{
              width: "40%",
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <Box className="signup_footer-cont" sx={{width:'100%',display:'flex',flexDirection:'column',gap:1}}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "18px",
                color: "#ffffff",
                fontFamily: "DM Sans",
              }}
            >
              SIGN UP FOR UPDATES
            </Typography>
          
            <Box className="footer_constant_contact">
              {" "}
              <div
                className="ctct-inline-form"
                data-form-id="2183ca42-9806-4ec4-9a1b-126d44072385"
              ></div>
              </Box>
            </Box>
            {/* <div></div> */}
            <Box
              className="footer_brochure_container"
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: 4,
                // justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box>
                {" "}
                <IconButton
                 sx={{padding:0,"& img": {
      transition: "opacity 0.3s ease-in-out",
    },
    "&:hover img": {
      opacity: 0.7, // Slight fade effect on hover
    },}}
                  tabIndex={0}
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/@PWRSteer",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  <img src={youtube} alt="youtube" />
                </IconButton>
              </Box>
              <Box>
                {" "}
                <IconButton
                sx={{padding:0,"& img": {
      transition: "opacity 0.3s ease-in-out",
    },
    "&:hover img": {
      opacity: 0.7, 
    },}} 
                  tabIndex={0}
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/showcase/pwr-steer/",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                >
                  <img src={linkedIn} alt="linkedin" />
                </IconButton>
              </Box>
              {/* <Box>
                {" "}
                <IconButton onClick={()=>window.open(pdfUrl, "_blank", "noopener,noreferrer")}>
                  <img src={insta} alt="youtube" />
                </IconButton>
              </Box> */}
            </Box>
            <Box
              className="pwr_brochure_footer"
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems:'center',
                gap: '12px',
                marginTop:2,
                
              }}
            >
              <img src={Pdf} alt="pdf" style={{ objectFit:'contain'}} />
              <Box sx={{ display: "flex", flexDirection: "row", gap:'10px', justifyContent:'center', alignItems:'center'}}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "#ffffff",
                    fontFamily: "DM Sans",
                  }}
                >
                  PWR Brochure
                </Typography>
                {/* <Typography
                  tabIndex={0}
                  onClick={() =>
                    window.open(
                      "https://www.pwrsteer.com/uploads/PWRBrochureSinglePage1124.pdf",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      window.open(
                        "https://www.pwrsteer.com/uploads/PWRBrochureSinglePage1124.pdf",
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }
                  }}
                  // href="https://www.pwrsteer.com/uploads/PWRBrochureSinglePage1124.pdf"
                  // download="PWR_Brochure.pdf"
                  style={{
                    color: "#FF0000",
                    fontSize: "10px",
                    fontWeight: 400,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Download Now
                </Typography> */}
                
                <Box  onClick={() =>
                    window.open(
                      "https://www.pwrsteer.com/uploads/PWRBrochureSinglePage1124.pdf",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      window.open(
                        "https://www.pwrsteer.com/uploads/PWRBrochureSinglePage1124.pdf",
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }
                  }}><FileDownloadIcon sx={{color:'#ffffff',cursor:'pointer',"&:hover":{color:'red'}}}/></Box>
              </Box>
            </Box>
          </Box>
          <Box
            className="footer_legal_contains"
            sx={{ width: "50%", gap: "80px" }}
          >
            <Box
              className="footer_information"
              sx={{
                width: "28%",
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  fontWeight: 500,
                  fontSize: "18px",
                  fontFamily: "DM Sans",
                }}
              >
                INFORMATION
              </Typography>
              <Typography
                onClick={() => {
                  sessionStorage.clear();
                  navigate(routes.About);
                  window.scrollTo({ top: 0 });
                }}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    sessionStorage.clear();
                    navigate(routes.About);
                    window.scrollTo({ top: 0 });
                  }
                }}
                className="footer_sub_text"
                sx={{
                  color: "#ffffff",
                  fontWeight: 400,
                  fontSize: "16px",
                  fontFamily: "DM Sans",
                  cursor: "pointer",
                  "&:hover": { textDecoration: "underline",color:'#E30000' },
                }}
              >
                About
              </Typography>
              <Typography
                className="footer_sub_text"
                onClick={() => {
                  sessionStorage.clear();
                  navigate(routes.Accessibility);
                  window.scrollTo({ top: 0 });
                }}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    sessionStorage.clear();
                    navigate(routes.Accessibility);
                    window.scrollTo({ top: 0 });
                  }
                }}
                sx={{
                  color: "#ffffff",
                  fontWeight: 400,
                  fontSize: "16px",
                  fontFamily: "DM Sans",
                  cursor: "pointer",
                  "&:hover": { textDecoration: "underline",color:'#E30000' },
                }}
              >
                Accessibility
              </Typography>

              <Typography
                className="footer_sub_text"
                onClick={handleAutoscrolling}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    handleAutoscrolling();
                  }
                }}
                sx={{
                  color: "#ffffff",
                  fontWeight: 400,
                  fontSize: "16px",
                  fontFamily: "DM Sans",
                  cursor: "pointer",
                  "&:hover": { textDecoration: "underline",color:'#E30000'  },
                }}
              >
                Contact
              </Typography>
              <Typography
                className="footer_sub_text"
                onClick={handleAutoscrolling}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    handleAutoscrolling();
                  }
                }}
                sx={{
                  color: "#ffffff",
                  fontWeight: 400,
                  fontSize: "16px",
                  fontFamily: "DM Sans",
                  cursor: "pointer",
                  "&:hover": { textDecoration: "underline",color:'#E30000'  },
                }}
              >
                Help
              </Typography>
              <Typography
                className="footer_sub_text"
                onClick={() => {
                  sessionStorage.clear();
                  navigate(routes.SiteMap);
                  window.scrollTo({ top: 0 });
                }}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    sessionStorage.clear();
                    navigate(routes.SiteMap);
                    window.scrollTo({ top: 0 });
                  }
                }}
                sx={{
                  color: "#ffffff",
                  fontWeight: 400,
                  fontSize: "16px",
                  fontFamily: "DM Sans",
                  cursor: "pointer",
                  "&:hover": { textDecoration: "underline" ,color:'#E30000' },
                }}
              >
                Site Map
              </Typography>
              <Typography
                className="footer_sub_text"
                sx={{
                  color: "#ffffff",
                  fontWeight: 400,
                  fontSize: "16px",
                  fontFamily: "DM Sans",
                  cursor: "pointer",
                  "&:hover": { textDecoration: "underline",color:'#E30000'  },
                }}
                onClick={() => {
                  sessionStorage.clear();
                  navigate(routes.Warranty);
                  window.scrollTo({ top: 0 });
                }}
              >
                Warranty
              </Typography>
              <Typography
                className="footer_sub_text"
                onClick={() =>
                  window.open(
                    "https://premiumguard.com/",
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
                sx={{
                  color: "#ffffff",
                  fontWeight: 400,
                  fontSize: "16px",
                  fontFamily: "DM Sans",
                  cursor: "pointer",
                  "&:hover": { textDecoration: "underline",color:'#E30000'  },
                }}
                //   onClick={() => {
                //   navigate(routes.Warranty);
                //   window.scrollTo({top:0})

                // }}
              >
                {/* <a
                  style={{
                    color: "#ffffff",
                    fontWeight: 400,
                    fontSize: "16px",
                    fontFamily: "DM Sans",
                    cursor: "pointer",
                  }}
                  href="https://premiumguard.com/"
                > */}
                Premium Guard Inc.
                {/* </a> */}
              </Typography>
            </Box>
            <Box
              className="footer_legal"
              sx={{
                width: "30%",
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Typography
                sx={{
                  color: "#ffffff",
                  fontWeight: 500,
                  fontSize: "18px",
                  fontFamily: "DM Sans",
                }}
              >
                LEGAL
              </Typography>

              <Typography
                className="footer_sub_text"
                onClick={() => {
                  sessionStorage.clear();
                  navigate(routes.PrivacyPolicy);
                  window.scrollTo({ top: 0 });
                }}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    sessionStorage.clear();
                    navigate(routes.PrivacyPolicy);
                    window.scrollTo({ top: 0 });
                  }
                }}
                sx={{
                  color: "#ffffff",
                  fontWeight: 400,
                  fontSize: "16px",
                  fontFamily: "DM Sans",
                  cursor: "pointer",
                  "&:hover": { textDecoration: "underline",color:'#E30000'  },
                }}
              >
                Privacy Policy
              </Typography>

              <Typography
                className="footer_sub_text"
                onClick={() => {
                  sessionStorage.clear();
                  navigate(routes.TermsOfService);
                  window.scrollTo({ top: 0 });
                }}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    sessionStorage.clear();
                    navigate(routes.TermsOfService);
                    window.scrollTo({ top: 0 });
                  }
                }}
                sx={{
                  color: "#ffffff",
                  fontWeight: 400,
                  fontSize: "16px",
                  fontFamily: "DM Sans",
                  cursor: "pointer",
                  "&:hover": { textDecoration: "underline" ,color:'#E30000' },
                }}
              >
                Terms of Use{" "}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
        className="footer-copyright-cont"
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            className="footer_copyright"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              width: "93%",
              gap:'19%',
              alignItems: "center",
              marginTop: "3%",
              marginBottom: 2,
            }}
          >
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                color: "#B4B4B4",
                fontFamily: "DM Sans",
              }}
            >
              Copyright © {currentYear} PWR STEER. All Rights Reserved.
            </Typography>
            <Box
              className="footerImg"
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={business} alt="Accredited Business" />
              <img src={norton} alt="Northon" />
              <img src={strip} alt="Stripe" />
            </Box>
          </Box>
        </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default CommonFooter;
