import React, { useEffect, useState } from "react";
import LayoutComponent from "../../Layout/LayoutComponent";
import CommonFooter from "../Footer/CommonFooter";
import { Box, Button, Typography } from "@mui/material";
import EnglishComp from "../Warranty/EnglishWarrantyStat";
import FrenchComp from "../Warranty/FrenchWarrantyStat";
import SpanishComp from "../Warranty/SpanishWarrantyStat";
import "../Warranty/warranty.css";
export const WarrantyStatement = () => {

   useEffect(()=>{
        if (!sessionStorage.getItem("hasRefreshed")) {
          sessionStorage.setItem("hasRefreshed", "true");
          window.location.reload();
        }
      },[])
  const [language, setLanguage] = useState([
    {
      title: "English",
      active: true,
    },
    {
      title: "French",
      active: false,
    },
    {
      title: "Spanish",
      active: false,
    },
  ]);

  const handleClick = (index) => {
    setLanguage((prevLang) =>
      prevLang.map((lang, indx) => ({
        ...lang,
        active: indx === index,
      }))
    );
  };

  const activeLang = language.find((lang) => lang.active)?.title;

  return (
    <LayoutComponent>
      <Box
        sx={{
          width: "100%",
          height:'auto',
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          className="main-war-state-box"
          sx={{
            width:'100%',
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            boxSizing: "border-box",
            padding: "5%",
            flexWrap: "wrap",
          }}
        >
          {/* warranty statement heading */}
          <Box
            className="war-head-box"

            sx={{
               marginTop:'2%'
            }}
          >
            <Typography
              className="head-typo-access"
              sx={{
                fontFamily: "DM Sans",
                fontSize: "60px",
                fontWeight: 800,
                lineHeight: "50px",
                color: "#000000",
                textDecoration: "underline",
                  textUnderlineOffset: "14px",
                  textDecorationColor: "#E30000",
                  textDecorationThickness: "3.5px",
              }}
            >
              Warranty Statement
            </Typography>
            <hr
              className="war-hr-line"
              style={{
                width: "100%",
                marginTop: "2%",
              }}
            />
          </Box>

          {/* lang selection */}
          <Box
            className="lang-sel-main"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              marginTop:'3%',
              gap: 5,
            }}
          >
            <Box
              className="lang-sel-sub"
              sx={{
                width: "40%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              {language.map((lang, index) => {
                return (
                  <Box className="sel-btn-box" key={index} sx={{}}>
                    <Button
                      variant="contained"
                      className="sel-btn"
                      sx={{
                        width:'70%',
                        height: "62px",
                        borderRadius: "9px",
                        borderLeft: lang.active
                          ? "9px solid #EC1B24"
                          : "9px solid transparent",
                        justifyContent: "flex-start",
                        marginBottom: "15px",
                        backgroundColor: "#FAFAFA",
                        color: "#252525",
                        fontSize: "16px",
                        fontFamily: "DM Sans",
                        fontWeight: 700,
                        lineHeight: "27px",
                        textTransform: "none",
                      }}
                      onClick={() => handleClick(index)}
                    >
                      {lang.title}
                    </Button>
                  </Box>
                );
              })}
            </Box>
            <Box className="war-stat-main-section"
              sx={{
                width: "60%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              {activeLang === "English" && <EnglishComp /> }
              {activeLang === "French" && <FrenchComp /> }
              {activeLang === "Spanish" && <SpanishComp /> }

            </Box>
          </Box>
        </Box>
      </Box>
      {/* </Box> */}
      <CommonFooter />
    </LayoutComponent>
  );
};
