import React, { useEffect } from "react";
import LayoutComponent from "../../Layout/LayoutComponent";
import CommonFooter from "../Footer/CommonFooter";
import { Box, Typography } from "@mui/material";
import CommonVideos from "../CommonComponent/CommonVideos";
import "../Videos/Videos.css";

export const Videos = () => {
  useEffect(() => {
    if (!sessionStorage.getItem("hasRefreshed")) {
      sessionStorage.setItem("hasRefreshed", "true"); 
      window.location.reload();
    }
  }, []);
  return (
    <LayoutComponent>
      <Box
        className="video-main-section"
        sx={{
          position: "relative",
          width: "100%",
          height: "auto",
          padding: "5%",
          // paddingBottom:0,
          // marginBottom:'7%',
          boxSizing: "border-box",
        }}
      >
        <Box
          className="video-sub-secion"
          sx={{
            marginTop: "2%",
          }}
        >
          <Typography
            className="videosHead"
            sx={{
              fontFamily: "DM Sans",
              fontSize: "60px",
              fontWeight: 800,
              lineHeight: "50px",
              color: "#000000",
              textDecoration: "underline",
                textUnderlineOffset: "14px",
                textDecorationColor: "#E30000",
                textDecorationThickness: "3.5px",
                  
            }}
          >
            Videos
          </Typography>

    <hr className="war-hr-line" style={{
        width:'100%',
        color:"#D9D9D9",
        marginTop:"2%",
        // marginLeft:'40px'

    }} />

          <CommonVideos />
        </Box>
      </Box>
      <CommonFooter />
    </LayoutComponent>
  );
};
