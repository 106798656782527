import React from "react";
import { Box, Button, Typography } from "@mui/material";
import PDF from "../../Assets/Warranty/pdfdownload.png";

const SpanishWarrantyStat = () => {
  return (
    <Box className="war-stat-sub-section">
      <Typography
        className="state-head-typo"
        sx={{
          width: "100%",
          height: "auto",
          fontFamily: "DM Sans",
          fontWeight: 600,
          fontSize: "25px",
          lineHeight: "27px",
          color: "#55120B",
        }}
      >
        Declaración de garantía de la dirección asistida
      </Typography>
      <Box
        className="content-main-box"
        sx={{
          width: "100%",
          height: "auto",
          marginTop: "2%",
          fontFamily: "DM Sans",
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: "27px",
          color: "#000000",
        }}
      >
        <Box
          className="content-first-second-row-box"
        >
          <Typography
            className="content-first-second-row-typo"
            sx={{
              fontFamily: "DM Sans",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight:'25px',
              color: "#000000",
            }}
          >
            PWR Steer garantiza que sus productos de dirección asistida son
            gratuitos por defectos en materiales o mano de obra cuando se
            utiliza en vehículos de propiedad privada y no comerciales durante
            el tiempo que dicho comprador original es propietario del vehículo
            en el que se encuentra la pieza. fue instalado originalmente.
            <br /> <br />
            La garantía comenzará en la fecha del primer instalación en el
            vehículo original. Sujeto a la limitaciones específicas enumeradas
            en este documento, el producto, cuando se instala de acuerdo con PWR
            Steer instrucciones de instalación, se cambiarán si se devuelven al
            vendedor que está autorizado a representar este garantía. Es posible
            que se requiera prueba de compra. Dirección PWR Las instrucciones
            aprobadas se incluyen con el PWR Steer. producto, disponible a
            través de PWR Steer autorizado distribuidores, o puede encontrarlo
            en www.pwrsteer.com.
            <br />
            <br />
            Esta garantía puede excluir fallas que sean directas resultado de,
            pero no limitado a:
          </Typography>
        </Box>
        <br />
        <Box>
          <ol
            className="stat-list-ol"
            style={{
              width: "100%",
              fontFamily: "DM Sans",
              fontWeight: 400,
              fontSize: "16px",
                              lineHeight:'25px',
              color: "#000000",
              paddingLeft: "10px",
              marginTop: "10px",
            }}
          >
            <li>
              Alteraciones en el sistema de dirección y suspensión OEM. diseño
            </li>
            <br />
            <li>
              Alteraciones del tamaño de ruedas y neumáticos o de la altura del
              vehículo. que exceden las especificaciones del vehículo OEM • Uso
              de productos no Fluidos aprobados por OE
            </li>
            <br />
            <li>
              Daños internos por exceso de residuos en el sistema o daños
              prolongados. funcionamiento del vehículo cuando el nivel de
              líquido está por debajo del Especificación de capacidad mínima OEM
            </li>
            <br />
            <li>
              Incumplimiento de las instrucciones de instalación de PWR Steer
            </li>
            <br />
            <li>
              Exceder el peso bruto vehicular específico del OEM o limitaciones
              de remolque • Instalación en un vehículo que no especificado en
              los datos de aplicación del catálogo PWR Steer
            </li>
            <br />
            <li>
              Incendio, inundación, explosiones, colisiones, daños externos,
              alteraciones físicas, desmontaje o componentes faltantes
            </li>
            <br />
            <li
            >
              Uso en vehículos comerciales durante más de 18 meses desde
              instalación. Esta garantía reemplaza a todas las demás garantías
              expresas o implícitas, incluida la garantía implícita de
              comerciabilidad e idoneidad para finalidades expresamente
              excluidas. La dirección PWR deberá no será responsable de daños
              consecuentes o incidentales resultante o causado por el uso,
              operación, falla, o defecto de cualquier producto PWR Steer. Esta
              garantía otorga usted tiene derechos legales específicos y también
              puede tener otros derechos que varían de un estado a otro. Oct
            </li>
          </ol>

          <Box
           className="pdf-icon-text-main-box"
            sx={{ 
              width: "100%",
              height: "auto",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 3,
              marginTop: "70px",
            }}
          >
            {/* PDF Icon Section */}
            <Box className="pdf-icon-down-box">
              <a
                download
                aria-label="Download PDF"
                style={{ display: "flex", alignItems: "center" }}
                onClick={() =>
                  window.open(
                    "https://www.pwrsteer.com/docs/Lifetime_Warranty_Spanish_v2.pdf",
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
              >
                <img
                  src={PDF}
                  alt="Download PDF Icon"
                  style={{ cursor: "pointer" }}
                />
              </a>
            </Box>
            <Box className="pdf-download-text-cont"
              sx={{
                width: "100%",
                height: "74px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Box className="ps-war-box"
                sx={{
                  width: "191px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  className="ps-war-stat-typo"
                  sx={{
                    fontFamily: "DM Sans",
                    fontWeight: 800,
                    fontSize: "16px",
                    lineHeight: "20px",
                    color: "#252525",
                    marginTop: "5px",
                  }}
                >
                  Declaración de garantía de la dirección asistida
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <Typography
                  onClick={() =>
                    window.open(
                      "https://www.pwrsteer.com/docs/Lifetime_Warranty_Spanish_v2.pdf",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  sx={{
                    cursor: "pointer",
                    fontFamily: "DM Sans",
                    color: "#EC1B24",
                    fontSize: "16px",
                    fontWeight: 600,
                    "&:hover": {color:'#e30000' },
                  }}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      window.open(
                        "https://www.pwrsteer.com/docs/Lifetime_Warranty_Spanish_v2.pdf",
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }
                  }}
                >
                  Descargar ahora
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SpanishWarrantyStat;
