import React from "react";
import LayoutComponent from "../../Layout/LayoutComponent";
import CommonFooter from "../../Pages/Footer/CommonFooter";
import { Box, Typography, Button,TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody, } from "@mui/material";
import { CommonSidebar } from "../CommonSidebarComp/CommonSidebar";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import "./Post.css";
import { useNavigate } from "react-router-dom";
import routes from "../../AppRoutes/routes.json";

export const Post = () => {
  const navigate = useNavigate();

  return (
    <LayoutComponent>
      <Box
        // className="export-main-container"
        className="post-main-container"
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          //   gap: 5,
          marginTop: "2.8%",
        }}
      >
        <CommonSidebar />

        <Box
          // className="export-main-cont"
           className="post-sub-container"
          sx={{
            width: "85%",
            display: "flex",
            flexDirection: "column",
            padding: "50px",
          }}
        >
          <Box
            className="post-create-main-container"
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 1,
              // padding: "50px 50px 50px 40px",
            }}
          >
            {/* Post Title */}
            {/* <Box
            className="post-title-box"
            sx={{
              width: "100%",
            }}
          >
            <Typography
              className="post-head-typo"
              sx={{
                fontSize: { xs: "24px", sm: "30px", md: "30px" },
                fontWeight: 700,
                fontFamily: "DM Sans",
                textDecoration: "underline",
                textDecorationColor: "#E71B2C",
                textUnderlineOffset: "10px",
                textDecorationThickness: "3.5px",
              }}
            >
              Posts
            </Typography>
          </Box> */}
            <Box
              className="post-title-box"
              sx={{
                width: "100%",
                backgroundColor: "#232121",
                cursor: "pointer",
              }}
            >
              <Typography
                className="post-head-typo"
                sx={{
                  color: "#fff",
                  fontSize: { xs: "20px", sm: "20px", md: "23px" },
                  fontWeight: 700,
                  fontFamily: "DM Sans",
                  textAlign: "center",
                  padding:'5px'
                }}
              >
                POSTS
              </Typography>
            </Box>

            {/* Add Post Button */}
            <Box
              className="add_post_btn_box"
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
                padding: "10px 0",
              }}
            >
              <Button
                variant="contained"
                color="success"
                onClick={() => navigate(routes.CreatePost)}
                sx={{ textTransform: "none", height: "40px", width: "150px" }}
              >
                <AddIcon sx={{ height: "20px" }} />
                Add New Post
              </Button>
            </Box>

            {/* All Posts */}
            <Box className="all_post-main_box">
              
                <TableContainer
              component={Paper}
              sx={{ width: "100%", border: "2px solid #ccc" }}
            >
              <Table sx={{ minWidth: "90%" }} aria-label="New-release table">
                <TableHead>
                  <TableRow>
                    <TableCell className="post-table-header-cells">
                     ID
                    </TableCell>
                    <TableCell className="post-table-header-cells">
                    Title
                    </TableCell>
                    <TableCell className="post-table-header-cells">
                    Date
                    </TableCell>
                    <TableCell className="post-table-header-cells">
                    Edit/Delete
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className="post-table-row-cells">
                     1
                    </TableCell>
                    <TableCell className="post-table-row-cells">
                    Remanufactured vs Power steering rack
                    </TableCell>
                    <TableCell className="post-table-row-cells">
                    1 Aug 2024
                    </TableCell>
                    <TableCell sx={{display:'flex', flexWrap:'wrap', gap:1}}>
                      {" "}
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ textTransform:'none' }}
                      >
                        Edit
                      </Button>
                      <Button variant="contained" color="error" sx={{ textTransform:'none'}}>
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
              
            </Box>
          </Box>
        </Box>
      </Box>
      <CommonFooter />
    </LayoutComponent>
  );
};
