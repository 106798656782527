import { Box, Button, Typography, TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody, } from '@mui/material'
import React, { useState } from "react";

export const Instructions = () => {
    const [selectedPartNoFile, setSelectedPartNoFile] = useState(null);
      const handleImageFileUpload = (event) => {
        const file = event.target.files[0];
        selectedPartNoFile(file ? file.name : null);
      };
  return (
    <Box
    className="bulletins-main-cont"
    sx={{
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: 3,
      // justifyContent: "center",
      // alignItems: "center",
    }}
  >
       {/* upload */}
       <Box
        className="part-no-file-upload"
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: 18,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 3,
          }}
        >
          {/* File Input */}
          <Button
            className="create-upload-btn"
            variant="contained"
            component="label"
            sx={{
              width: "120px",
              height: "30px !important",
              textTransform: "none",
              backgroundColor: "#d9d9d9",
              padding: "20px",
              color: "#000",
              fontFamily: "DM Sans",
              "&:hover": {
                backgroundColor: "darkgray",
              },
            }}
          >
            Browse
            <input type="file" hidden  />
          </Button>
          {/* Message */}
          <Typography
            sx={{
              fontFamily: "DM Sans",
              fontSize: "18px",
              color: "#888",
            }}
          >
            {selectedPartNoFile ? selectedPartNoFile : "No file selected"}
          </Typography>
        </Box>
        <Button variant="contained" color="error" sx={{ width: "80px", textTransform:'none' }} onClick={handleImageFileUpload}>
          Upload
        </Button>
      </Box>
      <Box
        className="bulletins-table-main-box"
        sx={{
          width: "96%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <TableContainer
          className="bulletins-table-container"
          component={Paper}
          sx={{ width: "90%", border: "2px solid #ccc" }}
        >
          <Table sx={{ minWidth: "90%" }} aria-label="bulletins-table">
            <TableHead>
              <TableRow>
                <TableCell className="bulletins-table-header-cells">
                  Resource
                </TableCell>
                <TableCell className="bulletins-table-header-cells">
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className="bulletins-table-row-cells" sx={{color:'red', textDecoration:'underline'}}>
                  42-23233
                </TableCell>
                <TableCell>
                  <Button variant="contained" color="error" sx={{ textTransform:'none'}}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}
