import React, { useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../AppRoutes/routes.json";
import GroupIcon from '@mui/icons-material/Group';
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import InfoIcon from "@mui/icons-material/Info";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import BarChartIcon from "@mui/icons-material/BarChart";
import LogoutIcon from '@mui/icons-material/Logout';
import ImageIcon from "@mui/icons-material/Image";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { FaBars, FaTimes } from "react-icons/fa"; 
// import "./CommonSidebar.css";
import "../Post/Post.css";

export const CommonSidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Track if sidebar is open
  const navigate = useNavigate();
  const location = useLocation();

  // Toggle function to open/close the sidebar
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    // <div className="sidebar-container">
      <Sidebar
        className={`sidebar-main ${isSidebarOpen ? "open" : "closed"}`}
        sx={{ width: isSidebarOpen ? "20%" : "5%", height: "100%", backgroundColor: "#000" }}
      >
        <div className="toggle-button" onClick={toggleSidebar}>
          {isSidebarOpen ? <FaTimes sx={{color:'red'}} /> : <FaBars sx={{color:'red'}} />} 
        </div>
        <Menu
          menuItemStyles={{
            button: {
              [`&.active`]: {
                backgroundColor: "#13395e", // Highlight color
                color: "#fff", // Text color for active item
              },
            },
          }}
        >
          
          <MenuItem className="cs-menu-item"
            icon={<CalendarMonthIcon className="sidebar-icons" sx={{ color: "white" }} />}
            onClick={() => navigate(routes.TechnicalResources)}
            active={location.pathname === "/technicalResources"}
          >
            {isSidebarOpen && "Technical Resources"}
          </MenuItem>
          <MenuItem className="cs-menu-item"
            icon={<NewReleasesIcon className="sidebar-icons" sx={{ color: "white" }} />}
            onClick={() => navigate(routes.NewReleasedItems)}
            active={location.pathname === routes.NewReleasedItems}
          >
            {isSidebarOpen && "Newly Released Items"}
          </MenuItem>
          {/* <MenuItem className="cs-menu-item"
            icon={<InfoIcon className="sidebar-icons" sx={{ color: "white" }} />}
            onClick={() => navigate("/documentation")}
            active={location.pathname === "/documentation"}
          >
            {isSidebarOpen && "Missing Information"}
          </MenuItem> */}
          <MenuItem className="cs-menu-item"
            icon={<FileDownloadIcon className="sidebar-icons" sx={{ color: "white" }} />}
            onClick={() => navigate(routes.Export)}
            active={location.pathname === "/admin/export"}
          >
            {isSidebarOpen && "Export"}
          </MenuItem>
          {/* <MenuItem className="cs-menu-item"
            icon={<BarChartIcon className="sidebar-icons" sx={{ color: "white" }} />}
            onClick={() => navigate("/statistics")}
            active={location.pathname === "/statistics"}
          >
            {isSidebarOpen && "Statistics"}
          </MenuItem>
          <MenuItem className="cs-menu-item"
            icon={<ImageIcon className="sidebar-icons" sx={{ color: "white" }} />}
            onClick={() => navigate("/part-images")}
            active={location.pathname === "/part-images"}
          >
            {isSidebarOpen && "Part Images"}
          </MenuItem> */}
          <MenuItem className="cs-menu-item"
            icon={<PostAddIcon className="sidebar-icons" sx={{ color: "white" }} />}
            onClick={() => navigate(routes.Post)}
            active={location.pathname.startsWith(routes.Post)}
          >
            {isSidebarOpen && "Posts"}
          </MenuItem>
          <MenuItem className="cs-menu-item"
            icon={<LogoutIcon className="sidebar-icons" sx={{ color: "white" }} />}
            onClick={() => navigate("/documentation")}
            active={location.pathname === "/documentation"}
          >
            {isSidebarOpen && "Logout"}
          </MenuItem>
        </Menu>
      </Sidebar>
    // </div>
  );
};
