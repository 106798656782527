import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const headerCellStyles = {
  fontWeight: 600,
  fontFamily: "DM Sans",
  fontSize: "16px",
  lineHeight: "20px",
  backgroundColor: "#EAEAEA",
  color: "#252525",
  borderRight: "0.8px solid #A9A9A9",

    
  
};

const cellStyles = {
  fontFamily: "DM Sans",
  fontSize: "14px",
  lineHeight: "18px",
  color: "#252525",
  borderRight: "0.8px solid #A9A9A9",
};

export const Application = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [searchItem, setSearchItem] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const { application } = useSelector((state) => state.CommonStore);

  useEffect(() => {
    setFilteredData(application);
  }, [application]);

  const searchData = (value) => {
    if (value.trim() === "") {
      setFilteredData(application);
    } else {
      const lowerCaseSearch = value.toLowerCase();
      const filtered = application.filter(
        (row) =>
          row.make.toLowerCase().includes(lowerCaseSearch) ||
          row.model.toLowerCase().includes(lowerCaseSearch) ||
          row.engine.toLowerCase().includes(lowerCaseSearch) ||
          row.year.toString().includes(lowerCaseSearch)
      );
      setFilteredData(filtered);
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchItem(value);
    searchData(value);
  };

  const handleSearchClick = () => {
    searchData(searchItem);
  };

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });

    const sortedData = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setFilteredData(sortedData);
  };

  if (!application.length) {
    return null;
  }

  return (
    <Box className="application-main-section"
      sx={{
        width: "100%",
        height: "auto",
        maxHeight: "730px",
        border: "1px solid #ccc",
        borderRadius: "13px",
        backgroundColor: "#FAFAFA",
        display: "flex",
        flexDirection: "column",
        // marginTop:"-1%"
      }}
    >
      {/* Header Section */}
      <Box
        className="application_header_section"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
          padding: "20px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "DM Sans",
            fontWeight: 700,
            fontSize: "16px",
            color: "#55120B",
          }}
        >
          Application
        </Typography>

        <Box
          className="application_searchbox"
          sx={{
            width: "359px",
            height: "30px",
            display: "flex",
            alignItems: "center",
            border: "1px solid #747474",
            backgroundColor: "#FFFFFF",
            borderRadius: "44px",
            padding: "5px 10px",
          }}
        >
          {/* Input Field */}
          <InputBase
            placeholder="Search by Make, Model, Engine"
            sx={{
              fontFamily: "DM Sans",
              fontWeight: 500,
              fontSize: "12px",
              lineHeight: "12px",
              width: "100%",
            }}
            value={searchItem}
            onChange={handleSearchChange}
          />

          {/* Search Icon */}
          <Box
            sx={{
              width: "32px",
              height: "32px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              backgroundColor: "#EC1B24",
              marginLeft: "8px",
              cursor: "pointer",
            }}
            onClick={handleSearchClick}
          >
            <SearchIcon
              sx={{
                color: "#FFFFFF",
                width: "16px",
                height: "16px",
              }}
            />
          </Box>
        </Box>
      </Box>

      {/* Table Section */}
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: "660px",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: "3px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#EC1B24",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#D3D3D3",
          },
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={headerCellStyles}>
                <TableSortLabel
                  active={sortConfig.key === "year"}
                  direction={sortConfig.key === "year" ? sortConfig.direction : "asc"}
                  onClick={() => handleSort("year")}
                  hideSortIcon={false}
                  sx={{
                     display:'flex',
                     flexDirection:'row',
                     fontFamily: "DM Sans",
                     justifyContent:'space-between',
                    "& .MuiTableSortLabel-icon": {
                      opacity: 1, // Make sure the icon is always visible
                    },
                  }}
                >
                  Year
                </TableSortLabel>
              </TableCell>
              <TableCell sx={headerCellStyles}>
                <TableSortLabel
                
                  active={sortConfig.key === "make"}
                  direction={sortConfig.key === "make" ? sortConfig.direction : "asc"}
                  onClick={() => handleSort("make")}
                  hideSortIcon={false}
                  sx={{
                    display:'flex',
                     flexDirection:'row',
                     fontFamily: "DM Sans",
                     justifyContent:'space-between',
                    "& .MuiTableSortLabel-icon": {
                      opacity: 1, // Make sure the icon is always visible
                    },
                  }}
                >
                  Make
                </TableSortLabel>
              </TableCell>
              <TableCell sx={headerCellStyles}>
                <TableSortLabel
                  active={sortConfig.key === "model"}
                  direction={sortConfig.key === "model" ? sortConfig.direction : "asc"}
                  onClick={() => handleSort("model")}
                  hideSortIcon={false}
                  sx={{
                    display:'flex',
                    flexDirection:'row',
                    fontFamily: "DM Sans",
                    justifyContent:'space-between',
                    "& .MuiTableSortLabel-icon": {
                      opacity: 1, // Make sure the icon is always visible
                    },
                  }}
                >
                  Model
                </TableSortLabel>
              </TableCell>
              <TableCell sx={headerCellStyles}>
                <TableSortLabel

                  active={sortConfig.key === "engine"}
                  direction={sortConfig.key === "engine" ? sortConfig.direction : "asc"}
                  onClick={() => handleSort("engine")}
                  hideSortIcon={false}
                  sx={{
                    display:'flex',
                    flexDirection:'row',
                    fontFamily: "DM Sans",
                    justifyContent:'space-between',
                    "& .MuiTableSortLabel-icon": {
                      opacity: 1, // Make sure the icon is always visible
                    },
                  }}
                  
                >
                  Engine
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.length > 0 ? (
              filteredData.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    backgroundColor: index % 2 === 0 ? "#FAFAFA" : "#EAEAEA",
                  }}
                >
                  <TableCell sx={cellStyles}>{row.year}</TableCell>
                  <TableCell sx={cellStyles}>{row.make}</TableCell>
                  <TableCell sx={cellStyles}>{row.model}</TableCell>
                  <TableCell sx={cellStyles}>{row.engine}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Typography
                    sx={{
                      fontFamily: "DM Sans",
                      fontWeight: 600,
                      fontSize: "16px",
                      color: "#ccc",
                    }}
                  >
                    No matching records found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
