import React, { useState } from 'react'

import LayoutComponent from "../../Layout/LayoutComponent";
import CommonFooter from "../../Pages/Footer/CommonFooter";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { CommonSidebar } from "../CommonSidebarComp/CommonSidebar";
import "../Post/Post.css";
import { Bulletins } from './Bulletins';
import { Instructions } from './Instructions';
import { ResVideos } from './ResVideos';
export const TechnicalRes = () => {
    const[activeBox, setActiveBox]= useState("bulletins");
    const handleBoxClick = (box) => {
        setActiveBox(box); 
      };
  return (
    <LayoutComponent>
    <Box
      // className="export-main-container"
      className="post-main-container"
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        //   gap: 5,
        marginTop: "2.8%",
      }}
    >
      <CommonSidebar />

      <Box
                className="post-sub-container" 
      // className="export-main-cont"
       sx={{ width: "85%", display: "flex", flexDirection: "column",padding:'3%' }}>
        <Box
          className="new-released-create-main-container"
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 4,
            // padding: "50px 10px 50px 40px",
          }}
        >
         {/* technical resources heading */}
         <Box className="tech-res-head-cont" sx={{width:'100%', height:'auto',backgroundColor:"#232121", display:'flex', flexDirection:'row'}}>
            <Box className="tech-bulletins-box" sx={{width:"15%",padding:'15px',backgroundColor: activeBox === "bulletins" ? "#b0151c" : "#232121", cursor:'pointer', borderRight:'1px solid #5e5b5b',textAlign:'center'}} onClick={() => handleBoxClick("bulletins")}>
                <Typography className="tech-bulletins-typo" sx={{color:'#fff', fontFamily:'DM Sans', }}>Technical Bulletins</Typography>
            </Box>
            <Box className="installation-inst-box"  sx={{width:"20%",padding:'15px',backgroundColor: activeBox === "instructions" ? "#b0151c" : "#232121", cursor:'pointer', borderRight:'1px solid #5e5b5b',textAlign:'center'}} onClick={() => handleBoxClick("instructions")}>
                <Typography className="installation-inst-typo" sx={{color:'#fff',fontFamily:'DM Sans'}}>Installation Instructions</Typography>
            </Box> <Box className="videos-box"  sx={{width:"15%",padding:'15px',backgroundColor: activeBox === "videos" ? "#b0151c" : "#232121", cursor:'pointer', borderRight:'1px solid #5e5b5b',textAlign:'center'}} onClick={() => handleBoxClick("videos")}>
                <Typography className="videos-typo" sx={{color:'#fff',fontFamily:'DM Sans'}}>Videos</Typography>
            </Box>
         </Box>
         {/* render component */}
         {activeBox === "bulletins" && <Bulletins />}
            {activeBox === "instructions" && <Instructions />}
            {activeBox === "videos" && <ResVideos />}
        </Box>
      </Box>
    </Box>
    <CommonFooter />
  </LayoutComponent>
  )
}
