import React, { useEffect, useState, useRef } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import routes from "../../AppRoutes/routes.json";
import "../CommonComponent/CommonVideos.css";
import "../Videos/Videos.css";
import videoPlayerIcon from "../../Assets/Rack&Pinions/videoPlayerIcon.png";
import { ApiLink } from "../Utils/Apilink";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CommonLoader from "../CommonComponent/CommonLoader";

const useStyles = {
  customScrollbar: {
    overflowY: "auto",
    height: "581px",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ec1b24",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#d1191f",
    },
    "&::-webkit-scrollbar-button": {
      display: "none",
      width: "0",
      height: "0",
    },
  },
};

const CommonVideos = () => {
  const [videoArr, setVideoArr] = useState();
  const [activeBlog, setActiveBlog] = useState();
  const titleRef = useRef(null);
  const [hrWidth, setHrWidth] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const [loading, setLoading] = useState(false);
  const descRef = useRef(null);

  const location = useLocation();
  const pathName = location.pathname;
  // const path = pathName.split("/").pop();
  let productId;
  if (pathName === routes.SteeringPump) {
    productId = 1;
  } else if (pathName === routes.RackPinion) {
    productId = 2;
  } else if (pathName === routes.SteeringGearBox) {
    productId = 3;
  }else if(pathName === routes.Reservoirs){
    productId=4;
  }

  const link =
    pathName === routes.Videos
      ? `${ApiLink}videos`
      : `${ApiLink}videos/${productId}`;


   
  useEffect(() => {
    setLoading(true)
    try {
      fetch(link, {
        method: "GET",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .then((resp) => resp.json())
        .then((resp) => {
          const formattedData = resp.map((item) => ({
            videoId: item.video_id,
            productId: item.product_video_id,
            videoName: item.video_name,
            videoLink: item.video_link,
            videoDesc: item.videos_description,
            thumbnail: item.videothumbnail,
            createdDate: item.created_date,
            newDate: item.new_date,
          }));
          setVideoArr(formattedData);
          setActiveBlog(formattedData[0]);
          setLoading(false)
        });
    } catch (err) {
      setLoading(false)
      console.error("Error fetching videos:", err);
    }
  }, [link]);

  useEffect(() => {
    if (titleRef.current) {
      setHrWidth(titleRef.current.offsetWidth);
    }

    if (descRef.current) {
      const lineHeight = 22;
      const maxHeight = lineHeight * 2;
      const textHeight = descRef.current.scrollHeight;

      setIsTruncated(textHeight > maxHeight);
    }
  }, [activeBlog]);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  return (
  // return loading ? (
  //   <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
  //     <CommonLoader open={loading} handleClose={() => setLoading(false)} />
  //   </Box>
  // ) : videoArr && (
    <Box
      className="common-container"
      sx={{
        width: "100%",
        // height: "750px",
        height: "auto",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        border:
          pathName === routes.Videos
            ? "0.5px solid #FFFFFF"
            : "0.5px solid #ccc",
        backgroundColor: pathName === routes.Videos ? "#FFFFFF" : "#F9F9F9",
        borderRadius: "12px",
        gap: 2,
        paddingBottom: '20px',
      }}
    >
      {/* Left Box */}
      <Box
        className="left-box"
        sx={{
          width: "50%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          // paddingLeft: "40px",
          paddingLeft: pathName === routes.Videos ? 0 : "40px",

          paddingRight: "30px",
          gap:'30px'
        }}
      >
        <Typography
          className="watchVideos"
          sx={{
            width: "100%",
            marginBottom: pathName === routes.Videos ? "5px" : "25px",
            fontFamily: "DM Sans",
            fontWeight: 700,
            fontSize: pathName === routes.Videos ? "34px" : "25px",
            lineHeight: "27px",
            color: "#000000",
            marginTop: "30px",
            textDecoration: pathName === routes.Videos ? 'none' : 'underline',
            textDecorationColor: '#E30000',
            textUnderlineOffset: '12px',
            textDecorationThickness:'4px',
          
          }}
        >
          {pathName === routes.Videos ? "Recent Videos" : "Watch Video"}
        </Typography>

        <Box
          className="activeVideoMain"
          sx={{
            width: "100%",
            height: "60%",
            borderRadius: "13px",
            // marginTop: "50px"
          }}
        >
          <iframe
            className="iframe"
            width="100%"
            height="400px"
            style={{ borderRadius: "12px" }}
            src={activeBlog && activeBlog.videoLink}
            alt={activeBlog && activeBlog.videoName}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Box>
        {/* {pathName === routes.Videos ? (
          <Typography
            className="activeDate"
            sx={{
              width: '100%',
              fontWeight: 400,
              fontSize: "14px",
              fontFamily: "DM Sans",
              lineHeight: "22px",
              color: "#000000",
              textAlign: "right",
              marginTop: "20px",
            }}
          >
            {activeBlog && activeBlog.newDate}
          </Typography>
        ) : null} */}
       
        <Typography
          className="activeBlogName"
          ref={titleRef}
          sx={{
            fontSize: "30px",
            fontFamily: "DM Sans",
            lineHeight: "27px",
            color: "#000000",
            fontWeight:600,
            // marginTop: "20px",
            // marginBottom:'8px',
            textDecoration: 'underline',
            textDecorationColor: '#E30000',
            textUnderlineOffset: '10px',
            textDecorationThickness:'3.5px',
          }}
        >
          {activeBlog && activeBlog.videoName}
          {/* {pathName !== routes.Videos && (
        <hr
          className="hrLine"
          style={{
            // marginTop: "10px",
            width:'100%',
            height: "4px",
            backgroundColor: "#E30000",
            border: "none",
            borderRadius: "5px",
          }}
        />
        )} */}
        </Typography>
        
       
        <Typography
          className="activeBlogDesc"
          ref={descRef}
          sx={{
            width: "100%",
            fontFamily: "DM Sans",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "25px",
            color: "#252525",
            display: "-webkit-box",
            WebkitLineClamp: isExpanded ? "unset" : 1,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            wordWrap: "break-word",
            // marginTop:'10px'
          }}
        >
          {activeBlog && activeBlog.videoDesc}
        </Typography>
        {isTruncated && !isExpanded && (
          <span className="readMoreSpan"
            style={{
              fontWeight: 700,
              fontSize: "16px",
              color: "#EF3840",
              cursor: "pointer",
              marginTop:'-30px',
              lineHeight:'25px'
            }}
            onClick={handleToggle}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                handleToggle()
                                      }
            }}
          >
            Read More
          </span>
        )}
        {isExpanded && (
          <span className="readMoreSpan"
            style={{
              fontWeight: 700,
              fontSize: "16px",
              color: "#EF3840",
              cursor: "pointer",
              marginTop:'-30px'
            }}
            onClick={handleToggle}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                handleToggle()
                                      }
            }}
          >
            Show Less
          </span>
        )}
      </Box>
   {(pathName===routes.Videos)?<></> :<Divider orientation="vertical"   sx={{
    height: "auto", // Let it automatically adjust to the parent container height
    alignSelf: "stretch", // Ensure it stretches vertically within the flex container
    backgroundColor: "#808080", // Optional: Set a visible color for testing
    width: "0.2px",
    opacity:0.4
  }}/>}
      {/* Right Box */}
      <Box
        className="right-video-box right-box"
        sx={{
          width: "50%",
          height: "90%",
          boxSizing: "border-box",
          marginTop: "50px",
          marginLeft: "5px",
          marginRight: "5px",
        }}
      >
        <Box
          className="otherVideoMainComp"
          sx={{
            ...useStyles.customScrollbar,
            width: "100%",
            height: "600px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            marginTop: "30px",
          }}
        >
          {videoArr &&
                        videoArr.reverse().map((video, index) => (

            // videoArr.slice().reverse().map((video, index) => (
              <Box
                className="otherVideo"
                key={index}
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: 4,
                  marginBottom: "40px",
                  cursor: "pointer",
                }}
                onClick={() => setActiveBlog(video)}
                
              >
                {/* Thumbnail with video player icon */}
                <Box
                  className="otherVideosMain"
                  sx={{
                    width: "231px",
                    height: "171px",
                    borderRadius: "10px",
                    overflow: "hidden",
                    flexShrink: 0,
                    position: "relative",
                  }}
                >
                  <LazyLoadImage
                    className="otherVideoImg"
                    src={video.thumbnail}
                    alt={video.videoName}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                  />
                  <img
                    src={videoPlayerIcon}
                    alt="play icon"
                    tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    setActiveBlog(video)
                              }
                }}
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "42px",
                      height: "42px",
                      pointerEvents: "none",
                    }}
                  />
                </Box>

                <Box
                  className="otherVideoNameSection"
                  sx={{
                    width: "calc(100% - 140px)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    className="inactiveVideoname"
                    sx={{
                      fontWeight: 700,
                      fontSize: "20px",
                      fontFamily: "DM Sans",
                      lineHeight: "27px",
                      color: "#0E1415",
                      // wordBreak: "break-word",
                      maxHeight: "64px",
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {video.videoName}
                  </Typography>
                  <Typography
                    className="inactiveVideoDesc"
                    sx={{
                      fontFamily: "DM Sans",
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "25px",
                      color: "#0B0F11",
                      marginTop: "10px",
                      maxHeight: "44px",
                      overflow: "hidden",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {video.videoDesc}
                  </Typography>
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

export default CommonVideos;
