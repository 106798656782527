import React, { useEffect } from "react";
import CommonFooter from "./CommonFooter";
import LayoutComponent from "../../Layout/LayoutComponent";
import { Box, Typography } from "@mui/material";

const PrivacyPolicy = () => {
  useEffect(() => {
    if (!sessionStorage.getItem("hasRefreshed")) {
      sessionStorage.setItem("hasRefreshed", "true");
      window.location.reload();
    }
  }, []);
  return (
    <LayoutComponent>
      <Box
        sx={{
          width: "100%",
          height: "auto",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          fontFamily: "DM Sans",
        }}
      >
        <Box
          className="main-war-state-box"
          sx={{
            width:'100%',
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            boxSizing: "border-box",
            padding: "5%",
            lexWrap: "wrap",
          }}
        >
          {/* warranty statement heading */}
          <Box
            className="warr-head-box"
            sx={{ marginTop: "2%", height: "42px" }}
          >
            <Typography
              className="head-typo-access"
              sx={{
                fontFamily: "DM Sans",
                fontSize: "60px",
                fontWeight: 800,
                lineHeight: "50px",
                color: "#000000",
                textDecoration: "underline",
                textUnderlineOffset: "14px",
                textDecorationColor: "#E30000",
                textDecorationThickness: "3.5px",
              }}
            >
              Privacy Policy
            </Typography>
            <hr
              className="war-hr-line"
              style={{ width: "100%", marginTop: "2%" }}
            />
          </Box>
          <Box
            className="privacy-content-main"
            sx={{ width: "100%", marginTop: "4%" }}
          >
            <Box>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight:'25px',
                  color: "#000000",
                }}
              >
                At PWR Steer, accessible from www.pwrsteer.com, one of our main
                priorities is the privacy of our visitors. This Privacy Policy
                document contains types of information that is collected and
                recorded by PWR Steer and how we use it. If you have additional
                questions or require more information about our Privacy Policy,
                do not hesitate to contact us. This Privacy Policy applies only
                to our online activities and is valid for visitors to our
                website with regards to the information that they shared and/or
                collect in PWR Steer. This policy is not applicable to any
                information collected offline or via channels other than this
                website.
              </Typography>
            </Box>
            <Box marginTop={2}>
              <Typography
                className="state-head-typo"
                sx={{
                  width: "100%",
                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 600,
                  fontSize: "25px",
                  lineHeight: "27px",
                  color: "#55120B",
                  padding: "10px 0px",
                }}
              >
                Consent
              </Typography>
            </Box>
            <Box>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                              lineHeight:'25px',
                  color: "#000000",
                }}
              >
                By using our website, you hereby consent to our Privacy Policy
                and agree to its terms.
              </Typography>
            </Box>

            <Box marginTop={2}>
              <Typography
                className="state-head-typo"
                sx={{
                  width: "100%",
                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 600,
                  fontSize: "25px",
                  lineHeight: "27px",
                  color: "#55120B",
                  padding: "10px 0px",
                }}
              >
                Information we collect
              </Typography>
            </Box>
            <Box>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight:'25px',
                  color: "#000000",
                }}
              >
                The personal information that you are asked to provide, and the
                reasons why you are asked to provide it, will be made clear to
                you at the point we ask you to provide your personal
                information. If you contact us directly, we may receive
                additional information about you such as your name, email
                address, phone number, the contents of the message and/or
                attachments you may send us, and any other information you may
                choose to provide. When you register for an Account, we may ask
                for your contact information, including items such as name,
                company name, address, email address, and telephone number.
              </Typography>
            </Box>
            <Box marginTop={2}>
              <Typography
                className="state-head-typo"
                sx={{
                  width: "100%",
                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 600,
                  fontSize: "25px",
                  lineHeight: "27px",
                  color: "#55120B",
                  padding: "10px 0px",
                }}
              >
                How we use your information
              </Typography>
            </Box>
            <Box>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                              lineHeight:'25px',
                  color: "#000000",
                }}
              >
                We use the information we collect in various ways, including to:
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight:'25px',
                  color: "#000000",
                }}
              >
                {" "}
                • Provide, operate, and maintain our website
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight:'25px',
                  color: "#000000",
                }}
              >
                {" "}
                • Improve, personalize, and expand our website
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                              lineHeight:'25px',
                  color: "#000000",
                }}
              >
                {" "}
                • Understand and analyze how you use our website
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                              lineHeight:'25px',
                  color: "#000000",
                }}
              >
                {" "}
                • Develop new products, services, features, and functionality
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                              lineHeight:'25px',
                  color: "#000000",
                }}
              >
                {" "}
                • Communicate with you, either directly or through one of our
                partners, including for customer service, to provide you with
                updates and other information relating to the website, and for
                marketing and promotional purposes
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                              lineHeight:'25px',
                  color: "#000000",
                }}
              >
                {" "}
                • Send you emails
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight:'25px',
                  color: "#000000",
                }}
              >
                {" "}
                •Find and prevent fraud
              </Typography>
            </Box>
            <Box marginTop={2}>
              <Typography
                className="state-head-typo"
                sx={{
                  width: "100%",
                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 600,
                  fontSize: "25px",
                  lineHeight: "27px",
                  color: "#55120B",
                  padding: "10px 0px",
                }}
              >
                Log Files
              </Typography>
            </Box>
            <Box>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight:'25px',
                  color: "#000000",
                }}
              >
                PWR Steer follows a standard procedure of using log files. These
                files log visitors when they visit websites. All hosting
                companies do this and are a part of hosting services' analytics.
                The information collected by log files include internet protocol
                (IP) addresses, browser type, Internet Service Provider (ISP),
                date and time stamp, referring/exit pages, and possibly the
                number of clicks. These are not linked to any information that
                is personally identifiable. The purpose of the information is
                for analyzing trends, administering the site, tracking users'
                movement on the website, and gathering demographic information.
              </Typography>
            </Box>
            <Box marginTop={2}>
              <Typography
                className="state-head-typo"
                sx={{
                  width: "100%",
                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 600,
                  fontSize: "25px",
                  lineHeight: "27px",
                  color: "#55120B",
                  padding: "10px 0px",
                }}
              >
                Cookies and Web Beacons
              </Typography>
            </Box>
            <Box>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                              lineHeight:'25px',
                  color: "#000000",
                }}
              >
                Like any other website, PWR Steer uses 'cookies'. These cookies
                are used to store information including visitors' preferences,
                and the pages on the website that the visitor accessed or
                visited. The information is used to optimize the users'
                experience by customizing our web page content based on
                visitors' browser type and/or other information.
              </Typography>
            </Box>
            <Box marginTop={2}>
              <Typography
                className="state-head-typo"
                sx={{
                  width: "100%",
                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 600,
                  fontSize: "25px",
                  lineHeight: "27px",
                  color: "#55120B",
                  padding: "10px 0px",
                }}
              >
                Advertising Partners Privacy Policies
              </Typography>
            </Box>
            <Box>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                              lineHeight:'25px',
                  color: "#000000",
                }}
              >
                You may consult this list to find the Privacy Policy for each of
                the advertising partners of PWR Steer.
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                              lineHeight:'25px',
                  color: "#000000",
                }}
              >
                Third-party ad servers or ad networks uses technologies like
                cookies, JavaScript, or Web Beacons that are used in their
                respective advertisements and links that appear on PWR Steer,
                which are sent directly to users' browser. They automatically
                receive your IP address when this occurs. These technologies are
                used to measure the effectiveness of their advertising campaigns
                and/or to personalize the advertising content that you see on
                websites that you visit.
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight:'25px',
                  color: "#000000",
                }}
              >
                Note that PWR Steer has no access to or control over these
                cookies that are used by third-party advertisers.
              </Typography>
            </Box>
            <Box marginTop={2}>
              <Typography
                className="state-head-typo"
                sx={{
                  width: "100%",
                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 600,
                  fontSize: "25px",
                  lineHeight: "27px",
                  color: "#55120B",
                  padding: "10px 0px",
                }}
              >
                Third Party Privacy Policies
              </Typography>
            </Box>
            <Box>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                              lineHeight:'25px',
                  color: "#000000",
                }}
              >
                PWR Steer's Privacy Policy does not apply to other advertisers
                or websites. Thus, we are advising you to consult the respective
                Privacy Policies of these third-party ad servers for more
                detailed information. It may include their practices and
                instructions about how to opt-out of certain options.
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight:'25px',
                  color: "#000000",
                }}
              >
                You can choose to disable cookies through your individual
                browser options. To know more detailed information about cookie
                management with specific web browsers, it can be found at the
                browsers' respective websites.
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                              lineHeight:'25px',
                  color: "#000000",
                }}
              >
                CCPA Privacy Rights (Do Not Sell My Personal Information) Under
                the CCPA, among other rights, California consumers have the
                right to:
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight:'25px',
                  color: "#000000",
                }}
              >
                {" "}
                • Request that a business that collects a consumer's personal
                data disclose the categories and specific pieces of personal
                data that a business has collected about consumers.
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                              lineHeight:'25px',
                  color: "#000000",
                }}
              >
                {" "}
                • Request that a business delete any personal data about the
                consumer that a business has collected.
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight:'25px',
                  color: "#000000",
                }}
              >
                {" "}
                • Request that a business that sells a consumer's personal data,
                not sell the consumer's personal data.
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight:'25px',
                  color: "#000000",
                }}
              >
                {" "}
                • If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us.
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                              lineHeight:'25px',
                  color: "#000000",
                }}
              >
                GDRP Data Protection Rights We would like to make sure you are
                fully aware of all of your data protection rights. Every user is
                entitled to the following:
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight:'25px',
                  color: "#000000",
                }}
              >
                {" "}
                • The right to access – You have the right to request copies of
                your personal data. We may charge you a small fee for this
                service.
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                              lineHeight:'25px',
                  color: "#000000",
                }}
              >
                {" "}
                • The right to rectification – You have the right to request
                that we correct any information you believe is inaccurate. You
                also have the right to request that we complete the information
                you believe is incomplete.
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                              lineHeight:'25px',
                  color: "#000000",
                }}
              >
                {" "}
                • The right to erasure – You have the right to request that we
                erase your personal data, under certain conditions.
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight:'25px',
                  color: "#000000",
                }}
              >
                {" "}
                • The right to restrict processing – You have the right to
                request that we restrict the processing of your personal data,
                under certain conditions.
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight:'25px',
                  color: "#000000",
                }}
              >
                {" "}
                • The right to object to processing – You have the right to
                object to our processing of your personal data, under certain
                conditions.
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight:'25px',
                  color: "#000000",
                }}
              >
                {" "}
                • The right to data portability – You have the right to request
                that we transfer the data that we have collected to another
                organization, or directly to you, under certain conditions.
              </Typography>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                              lineHeight:'25px',
                }}
              >
                {" "}
                • If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us.
              </Typography>
            </Box>

            <Box marginTop={2}>
              <Typography
                className="state-head-typo"
                sx={{
                  width: "100%",
                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 600,
                  fontSize: "25px",
                  lineHeight: "27px",
                  color: "#55120B",
                  padding: "10px 0px",
                }}
              >
                Children's Information
              </Typography>
            </Box>
            <Box>
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight:'25px',
                  color: "#000000",
                }}
              >
                Another part of our priority is adding protection for children
                while using the internet. We encourage parents and guardians to
                observe, participate in, and/or monitor and guide their online
                activity.
              </Typography> <br />
              <Typography
                className="content-first-second-row-typo"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                              lineHeight:'25px',
                  color: "#000000",
                }}
              >
                PWR Steer does not knowingly collect any Personal Identifiable
                Information from children under the age of 13. If you think that
                your child provided this kind of information on our website, we
                strongly encourage you to contact us immediately and we will do
                our best efforts to promptly remove such information from our
                records.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* </Box> */}
      <CommonFooter />
    </LayoutComponent>
  );
};

export default PrivacyPolicy;
