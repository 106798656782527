import React, { useEffect, useState } from "react";
import LayoutComponent from "../../Layout/LayoutComponent";
import CommonFooter from "../Footer/CommonFooter";
import { Box, Typography } from "@mui/material";
import bgImg from "../../Assets/Rack&Pinions/rackBg.png";
import { useNavigate } from "react-router-dom";
import routes from "../../AppRoutes/routes.json";
import rackImg from "../../Assets/Rack&Pinions/rack1.png";
import gearIcon from "../../Assets/Rack&Pinions/gearIcon.png";
import GearBox from "../../Assets/Rack&Pinions/gearBox.png";
import GearBoxFig from "../../Assets/Rack&Pinions/PWR_Gear_Details.jpg";
import MaskGroup from "../../Assets/Rack&Pinions/Gear_Box.jpeg";
import rack2 from "../../Assets/Rack&Pinions/gear_box_3.jpg";
import Video from "../CommonComponent/CommonVideos";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const SteeringGearBox = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!sessionStorage.getItem("hasRefreshed")) {
      sessionStorage.setItem("hasRefreshed", "true"); // Mark as refreshed
      window.location.reload();
    }
  }, []);

  const GEAR_BOX_FEATURES = [
    "100% New Components: Built entirely with new materials, ensuring reliability and eliminating risks of remanufactured parts.",
    "Corrosion-Resistant Coating: Protects against environmental wear for long-lasting performance.",
    "Pre-Installed Input & Output Shaft Spline Protectors: Prevents contamination during shipping and installation.",
    "Port Thread Protective Screw-In Caps: Shields critical ports from debris before installation.",
    "Precision-Machined Internal Components: Designed to meet or exceed OE standards for performance and durability.",
    "Pre-Attached Pitman Shaft Nut: Simplifies installation and ensures proper fitment.",
    "No Turn Radius Consolidation: Maintains original steering characteristics and precision.",
    "Industrial-Grade Packaging: Custom-designed packaging safeguards parts during transit.",
  ];

  <Box component="ul">
    {GEAR_BOX_FEATURES.map((feature, index) => (
      <li key={index} style={{ marginBottom: "10px" }}>
        {feature}
      </li>
    ))}
  </Box>;

  return (
    <LayoutComponent>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* 1st Row */}
        <Box className="bgSection" sx={{ position: "relative", zIndex: 1 }}>
          <Box
            className="bgSubSection"
            sx={{
              position: "relative",
              width: "100%",
              height: "auto",
              overflow: "hidden",
            }}
          >
            {/* Image */}
            <LazyLoadImage
              src={bgImg}
              className="bgImage"
              alt="Steering Gear Box"
              style={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
                filter: "brightness(0.7)",
              }}
            />
            {/* Dark Overlay */}
            <Box
              className="darkOverlay"
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              }}
            ></Box>
          </Box>

          {/* Navigation Box */}
          <Box
            className="navBox"
            sx={{
              position: "absolute",
              top: "10%",
              left: "8%",
            }}
          >
            <Typography
              className="navTypography"
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                display: "flex",
                alignItems: "center",
                gap: "12px",
                color: "#FFFFFF",
                fontFamily: "DM Sans",
              }}
            >
              <Typography
                className="navLink"
                onClick={() => {
                  sessionStorage.clear();
                  navigate(routes.Home);
                }}
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    sessionStorage.clear();
                    navigate(routes.Home);
                    window.scrollTo({ top: 0 });
                  }
                }}
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  lineHeight: "27px",
                  textAlign: "center",
                  color: "#FFFFFF",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                Home
              </Typography>
              <span
                style={{
                  color: "#EC1B24",
                }}
              >
                &gt;
              </span>

              <Typography
                className="navLink"
                sx={{
                  color: "#FFFFFF",
                  fontWeight: 700,
                  fontFamily: "DM Sans",
                  textDecoration: "none",
                  lineHeight: "27px",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                Gear Boxes
              </Typography>
            </Typography>
          </Box>
          {/* end of navigation */}

          {/* Floating Box */}
          <Box
            className="floatMainBox steer-gear-floatMainBox"
            id="gearbox_float"
            sx={{
              position: "absolute",
              top: "95%",
              left: "50%",
              transform: "translate(-50%, -90%)",
              width: "80%",
              height: "auto",
              backgroundColor: "white",
              borderRadius: "13px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              border: "0.7px solid #808080",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "40px",
              gap: '15px',
            }}
          >
            {/* left box */}

            <Box
              className="floatLeftBox"
              sx={{
                width: "30%",
                // height: "102px",
              }}
            >
              <Box sx={{ width: "fit-content" }}>
                <Typography
                  className="floatHeading rack-pinion-head-typo"
                  sx={{
                    // width:'fit-content',
                    fontFamily: "DM Sans",
                    fontWeight: 700,
                    fontSize: "51px",
                    lineHeight: "50px",
                    letterSpacing: "1%",
                    color: "#0E1415",
                    // textDecoration: 'underline',
                    // textDecorationColor: 'red',
                    // textUnderlineOffset: '15px'
                  }}
                >
                  Power Steering Gear
                                    <hr className="right-heading-hr-line" style={{border:'2px solid #E30000', borderRadius:'10px', margin:0,marginTop:'5px',}} />

                  {/* <hr
                    style={{
                      color: "#E30000",
                      borderRadius: "5px",
                      border: "2px solid #E30000",
                    }}
                  /> */}
                </Typography>
              </Box>
            </Box>

            {/* right side box */}

            <Box
              className="floatImageBox"
              sx={{
                width: "70%",
                height: "100%",
                //  display:'flex',
                // justifyContent:'flex-end'
                // display:'contents',

                // height: "auto",
                // marginTop:'100px'
              }}
            >
              <Box sx={{ width: "100%", height: "90%", display:'flex', justifyContent:'flex-end' }}>
                <LazyLoadImage
                  src={GearBoxFig}
                  style={{
                    width: "90%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                  alt="Power Steering Gear"
                  className="rightImgBox"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          className="fourth-fifth-sixth-main"
          sx={{
            padding: "60px",
            padding: "60px",
            maxWidth: "100vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* 2nd Row */}
          <Box
            className="keyFeatMain"
            sx={{
              position: "relative",
              marginTop: "8%",
              display: "flex",
              // flexWrap: 'wrap',
              flexDirection: "row",
              justifyContent: "center",
              // alignItems: "center",
              gap: 6,
              width: "100%",
            }}
          >
            {/* Left Box */}
            <Box
              className="leftMain_container"
              sx={{ width: "50%", height: "100%" }}
            >
              <Box
                className="rightMainSection"
                sx={{
                  width: "100%",
                  // marginTop: "24px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Box
                  className="rightMainHead"
                  sx={{
                    width: "100%",
                    // height: "102px",
                  }}
                >
                  <Typography
                    className="rightHeading"
                    sx={{
                      fontFamily: "DM Sans",
                      fontWeight: 700,
                      fontSize: "50px",
                      lineHeight: "50px",
                      color: "#000000",
                    }}
                  >
                    Performance and Reliability You Can Count On
                  </Typography>
                  <hr className="right-heading-hr-line" style={{border:'2px solid #E30000', borderRadius:'10px', margin:0,marginTop:'15px',}} />

                </Box>
                <Box
                  className="rightContentSection"
                  sx={{
                    width: "100%",
                  }}
                >
                  <Typography
                    className="rightContent"
                    sx={{
                      fontFamily: "DM Sans",
                      fontWeight: "400px",
                      fontSize: "14px",
                      lineHeight: "25px",
                      color: "#252525",
                    }}
                  >
                    PWR Gear Boxes are engineered to deliver the durability and
                    precision required for seamless operation. Every unit is
                    100% new, eliminating the challenges associated with
                    remanufactured products, such as inconsistent performance
                    and early failure rates. PWR delivers the confidence
                    technicians and drivers demand.
                  </Typography>
                </Box>
                <Box
                  className="third-box-main"
                  sx={{
                    width: "100%",
                    height: "auto",
                    // backgroundColor: "#FAFAFA",
                    // marginTop: 5,
                  }}
                >
                  <Box
                    className="third-box-sub"
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <LazyLoadImage
                      className="steerPump3img"
                      src={GearBox}
                      alt="Power Steering Gear"
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* Right Box */}

            <Box
              className="leftMain_container"
              sx={{
                width: "50%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Box
                className="leftMain"
                sx={{
                  width: "100%",
                  height: "auto",

                  borderRadius: "19px",
                  backgroundColor: "#F0F3F3",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "40px",
                }}
              >
                <Box
                  className="Left-sub-section"
                  sx={{
                    width: "90%",
                    // height: "553px",
                    height: "auto",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "40px",
                    // marginTop: "38px",
                  }}
                >
                  <Box
                    className="leftHeadingBox"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      gap: "10px",
                      width: "100%",
                    }}
                  >
                    <img
                      className="gearIconImg"
                      src={gearIcon}
                      alt="key points"
                      style={{
                        width: "32px",
                        height: "32px",
                      }}
                    />
                    <Typography
                      className="leftHeadingText"
                      sx={{
                        fontFamily: "DM Sans",
                        fontWeight: 700,
                        fontSize: "19px",
                        lineHeight: "27px",
                        color: "#000",
                      }}
                    >
                      Key Features
                    </Typography>
                  </Box>

                  <Box
                    className="listSection"
                    sx={{
                      // height: "461px",
                      color: "#252525",
                    }}
                  >
                    <ul className="leftList">
                      {GEAR_BOX_FEATURES.map((feature, index) => {
                        const [boldText, ...rest] = feature.split(": ");
                        return (
                          <li
                            className="listItem"
                            key={index}
                            style={{
                              width: "auto",
                              height: "auto",
                              color: "#252525",
                              marginBottom: "10px",
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "22px",
                              fontFamily: "DM Sans",
                            }}
                          >
                            <strong>{boldText}</strong>
                            {rest.length > 0 && `: ${rest.join(": ")}`}
                          </li>
                        );
                      })}
                    </ul>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* 3rd Row */}

          {/* 4th row */}
          <Box
            className="fourth-box-main"
            sx={{
              width: "100%",
              height: "auto",
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap:'2%',
              marginTop: "4%",
            }}
          >
            {/* left box */}
            <Box
              className="fourth-left-box"
              sx={{
                // width: "591px",
                width: "49%",
                // height: "547px",
              }}
            >
                                          <Box sx={{maxWidth:"97%"}}>
              
              <Typography
                className="fourth-left-heading"
                sx={{
                  // width: "553px",
                  width: "100%",
                  height: "auto",
                  color: "#55120B",
                  fontFamily: "DM Sans",
                  fontWeight: 700,
                  fontSize: "24px",
                  lineHeight: "30px",
                  marginBottom:'2%'

                }}
              >
                Why Choose PWR Steer Gear Boxes?
              </Typography>

              <Typography
                className="fourth-left-content"
                sx={{
                  // width: "591px",
                  width: "100%",

                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "25px",
                  color: "#0B0F11",
                  marginBottom:'1%'

                }}
              >
                At PWR Steer, we understand the challenges of replacing or
                upgrading a gear box, which is why our products are designed to
                ensure precise fitment and consistent performance. Built from
                100% new materials, PWR gear boxes are engineered to outperform
                remanufactured alternatives.
              </Typography>

              <Typography
                className="fourth-question"
                sx={{
                  // width: "516px",
                  width: "100%",
                  height: "auto",
                  fontFamily: "DM Sans",
                  fontWeight: 700,
                  fontSize: "20px",
                  lineHeight: "27px",
                  color: "#55120B",
                  marginTop: "10px",
                }}
              >
                What Sets Us Apart?
              </Typography>
              <ol>
                <li className="fouth-list-item">
                  <Typography
                    className="list-item"
                    sx={{
                      // width: "561px",
                      width: "100%",

                      // height: "224px",
                      fontFamily: "DM Sans",
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "25px",
                      color: "#000000",
                    }}
                  >
                    Decades of Collective Automotive Expertise
                    <br />
                    <span
                      className="list-item-content"
                      style={{
                        fontFamily: "DM Sans",
                        fontWeight: 400,
                        fontSize: "16px",
                      lineHeight: "25px",
                        color: "#000000",
                      }}
                    >
                      Our engineering and manufacturing teams bring extensive
                      experience across the automotive industry, specializing in
                      power steering systems, product development, and quality
                      assurance.
                    </span>
                  </Typography>
                </li>
                <li className="fouth-list-item">
                  <Typography
                    className="list-item"
                    sx={{
                      // width: "561px",
                      width: "100%",

                      marginTop: "10px",
                      fontFamily: "DM Sans",
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "25px",
                      color: "#000000",
                    }}
                  >
                    Advanced Quality Standards
                    <br />
                    <span
                      className="list-item-content"
                      style={{
                        fontFamily: "DM Sans",
                        fontWeight: 400,
                        fontSize: "16px",
                      lineHeight: "25px",
                        color: "#000000",
                      }}
                    >
                      ISO/TS 16949-compliant processes ensure that every product
                      meets or exceeds industry standards for durability,
                      performance, and safety.
                    </span>
                  </Typography>
                </li>

                <li className="fouth-list-item">
                  <Typography
                    className="list-item"
                    sx={{
                      // width: "561px",
                      width: "100%",

                      marginTop: "10px",
                      fontFamily: "DM Sans",
                      fontWeight: 600,
                      fontSize: "16px",
                      lineHeight: "25px",
                      color: "#000000",
                    }}
                  >
                    Design Innovation
                    <br />
                    <span
                      className="list-item-content"
                      style={{
                        fontFamily: "DM Sans",
                        fontWeight: 400,
                        fontSize: "16px",
                      lineHeight: "25px",
                        color: "#000000",
                      }}
                    >
                      Each product is engineered to address common OE design
                      flaws, delivering improved durability and long-lasting
                      performance.
                    </span>
                  </Typography>
                </li>
              </ol>
              <Typography
                className="list-item-content1"
                sx={{
                  // width: "591px",
                  width: "100%",

                  height: "60px",
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                      lineHeight: "25px",
                  color: "#000000",
                }}
              >
                With PWR gear boxes, you’re investing in a product that delivers
                exceptional performance, minimizes downtime, and is backed by a
                trusted warranty.
              </Typography>
            </Box>
            </Box>
            {/* right box */}
            <Box
              className="fourth-right-box"
              sx={{ width: "49%", display: "flex", justifyContent: "center" }}
            >
              <Box
                className="fourth-right-box-main rack-pinion-fourth-box-main"
                sx={{
                  borderRadius: "19px",
                  backgroundColor: "#F0F3F3",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "30px",
                  width: "85%",
                }}
              >
                <Box
                  className="fourth-right-box-sub"
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LazyLoadImage
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "contain",
                      display: "block",
                      borderRadius: "12px",
                    }}
                    className="fourth-right-image"
                    src={MaskGroup}
                  />
                </Box>
              </Box>
            </Box>
          </Box>

          {/* 5th row */}
          <Box
            className="fifth-box-main"
            sx={{
              // width: "95%",
              width:'100%',
              height: "auto",
              flexWrap: "wrap",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap:'2%',
              marginTop: "5%",
            }}
          >
            {/* right box */}
            <Box className="fifth-image-right-box" sx={{ width: "49%" }}>
              <Box
                className="fourth-right-box-main"
                sx={{
                  borderRadius: "19px",
                  backgroundColor: "#F0F3F3",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "85%",
                  padding: "30px",
                }}
              >
                <Box
                  className="fourth-right-box-sub"
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                  }}
                >
                  <LazyLoadImage
                    className="fourth-right-image"
                    id="reservoir-fourth-right-image"
                    style={{
                      width: "100%",
                      height: "400px",
                      maxWidth: "100%",
                      objectFit: 'fill',
                      borderRadius: "12px",
                      display: "block",
                    }}
                    src={rack2}
                    alt="Reservoirs"
                  />
                </Box>
              </Box>
            </Box>

            {/* left box */}
            <Box
              className="fourth-left-box"
              sx={{
                width: "49%",
                height: "auto",
              }}
            >
              <Typography
                className="fourth-left-heading"
                sx={{
                  width: "100%",
                  // width: "553px",
                  // height: "17px",
                  color: "#55120B",
                  fontFamily: "DM Sans",
                  fontWeight: 700,
                  fontSize: "20px",
                  lineHeight: "27px",
                  // marginBottom: "30px",
                }}
              >
                New Product Development Quality Control
              </Typography>

              <ol>
                <li className="fouth-list-item">
                  <Typography
                    className="list-item-content"
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "25px",
                      color: "#000000",
                      // marginTop: "20px",
                    }}
                  >
                    <strong>Built-In Quality from the Start:</strong> Every
                    product is developed using our Advanced Product Quality
                    Planning (APQP) process, ensuring a flawless launch.
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="list-item-content"
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "25px",
                      color: "#000000",
                      marginTop: "20px",
                    }}
                  >
                    <strong>Collaborative Engineering:</strong> Our teams work
                    directly with manufacturers to develop products that meet
                    OE-level standards for fit, form, and function.
                  </Typography>
                </li>

                <li>
                  <Typography
                    className="list-item-content"
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "25px",
                      color: "#000000",
                      marginTop: "20px",
                    }}
                  >
                    <strong>Comprehensive Validation:</strong> Each component
                    undergoes extensive validation and testing for real-world
                    performance and reliability.
                  </Typography>
                </li>
                <li>
                  <Typography
                    className="list-item-content"
                    style={{
                      fontFamily: "DM Sans",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "25px",
                      color: "#000000",
                      marginTop: "20px",
                    }}
                  >
                    <strong >Customer-First Approach:</strong> Designed with
                    technicians and end-users in mind, PWR products combine ease
                    of installation, durability, and exceptional value, backed
                    by industry-leading warranties.
                  </Typography>
                </li>
              </ol>
            </Box>
          </Box>

          {/* 6th row */}
          <Box
            className="sixth-box-main"
            sx={{
              width: "100%",
              boxSizing: "border-box",
              marginTop:'3%'
              // marginTop: "25px",
            }}
          >
            <Video />
          </Box>
        </Box>
      </Box>
      <CommonFooter />
    </LayoutComponent>
  );
};
