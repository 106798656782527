import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import AboutUsSlider from "../../Assets/About/aboutSlider.png";
import Vector from "../../Assets/About/Vector.png";
import LayoutComponent from "../../Layout/LayoutComponent";
import DownArrow from "@mui/icons-material/KeyboardArrowDown";
import ViewIcon from "../../Assets/About/Group 5925.png";
import CommonFooter from "../Footer/CommonFooter";
import Repair1 from "../../Assets/jkl-transformed.png";
import Repair2 from "../../Assets/About/image.png";
import "../AboutUs/About.css";
import routes from "../../AppRoutes/routes.json";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getNewRealses } from "../ProductPages/ClassicSearchAction";
import { LazyLoadImage } from "react-lazy-load-image-component";

const AboutPwr = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(()=>{
    if (!sessionStorage.getItem("hasRefreshed")) {
      sessionStorage.setItem("hasRefreshed", "true"); // Mark as refreshed
      window.location.reload();
    }
  },[])
  return (
    <LayoutComponent>
      <Box
        className="about_main_container"
        sx={{ position: "relative", width: "100%", height: "auto" }}
      >
        {/* Image */}
        <img
          className="about_main_image"
          src={AboutUsSlider}
          alt="About Us"
          style={{}}
        />

        <Box
          className="about_main_text_container"
          sx={{
            width: "80%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            maxWidth: "100%",
            position: "absolute",
            top: 200,
            left: "5%",
            color: "white",
            textAlign: "left",
          }}
        >
          <Box className="about-pwr-head-box">
            <Typography
              variant="h2"
              sx={{
                fontWeight: 700,
                fontSize: { xs: "32px", sm: "60px", md: "70px" },
                lineHeight: { xs: "40px", sm: "27px" },
                fontFamily: "DM Sans",
                textDecoration: 'underline',
                textDecorationColor: 'white',
                textUnderlineOffset: '20px',
                textDecorationThickness:'3.5px',
              }}
            >
              About <span style={{ color: "#EC1B24",fontFamily: "DM Sans",  }}> PWR </span>
            </Typography>
          </Box>
          {/* <Box className="about-divider">
            <img src={Vector} className="divider_img" style={{}} alt="Vector" />
          </Box> */}
          <Box className="about-pwr-content">
            <Typography className="about-pwr-content-typo"
              variant="body2"
              sx={{
                width: "66%",
                // maxWidth: "335px",
                marginTop: "55px",
                textAlign: "left",
                fontSize: { xs: "14px", sm: "16px" },
                fontFamily: "DM Sans",
              }}
            >
              Built to Last, Driven to Lead: Innovation, Precision, and Power
              Steering Excellence
            </Typography>
          </Box>
        </Box>

        <Box
          className="about_arrow_box"
          sx={{
            top: "420px",
            position: "absolute",
            left: "97%",
            transform: "translateX(-50%)",
            width: "34px",
            height: "60px",
            boxSizing: "border-box",
            border: "1px solid #FFFFFF",
            borderRadius: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DownArrow
            sx={{
              paddingTop: "90%",
              border: "1.6px",
              color: "#EC1B24",
            }}
          ></DownArrow>
        </Box>
        <Box className="about-pwr-main-content" sx={{display:'flex', flexDirection:'column', gap:'40px', margin: "70px",marginBottom:'7%' }}>
          {/* 2nd box(row) */}

          <Box
            className="about_second_box"
            sx={{
              width: "100%",
              height: "auto",
              boxSizing: "border-box",

              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              gap: '20px',
            }}
          >
            {/* Left side content box */}
            <Box
              className="about_sideContent_box"
              sx={{
                width: "38%",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <Typography
                className="about_side_content_text1"
                sx={{
                  width: "80%",
                  fontWeight: 600,
                  fontSize: "30px",
                  lineHeight: "40px",
                  color: "#EC1B24",
                  fontFamily: "DM Sans",
                }}
              >
                When Your Reputation is on the Line,
              </Typography>

              <Typography
                className="about_side_content_text1"
                sx={{
                  // width: "454px",

color:'#363735',
                  fontWeight: 600,
                  fontSize: "30px",
                  lineHeight: "40px",
                  fontFamily: "DM Sans",
                  marginTop: "10px",
                }}
              >
                Choose ALL NEW Installation Ready Power Steering
              </Typography>

              <Typography
                className="about_side_content_text11"
                sx={{
                 width:'75%',
                  top: "914px",
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "25px",
                  color: "#8A8A8A",
                  marginTop: "15px",
                  fontFamily: "DM Sans",
                }}
              >
                For unmatched reliability and easy installation, choose the
                all-new power steering solution.
              </Typography>
              <Box
                className="about_View_products_btn"
                sx={{
                  boxSizing: "border-box",
                  width: "182px",
                  height: "50px",
                  marginTop: "40px",
                  borderRadius: "6100px",
                  backgroundColor: "#0E1415",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  '&:hover':{
                    backgroundColor:'#474747'
                  }
                }}
              >
                <Button
                  onClick={() => {
                    dispatch(getNewRealses(1));
                    sessionStorage.clear()
                    navigate(routes.Ecatlog);

                    window.scrollTo({ top: 0 });
                  }}
                  sx={{
                    textTransform: "none",
                    display: "flex",
                    alignItems: "center",
                    padding: 0,
                    
                  }}
                >
                  <img
                    src={ViewIcon}
                    alt="View Icon"
                    style={{
                      width: "16px",
                      height: "16px",
                      color: "#EC1B24",
                      marginRight: "10px",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "12px",
                      color: "#D5D7D6",
                      fontFamily: "DM Sans",
                    }}
                  >
                    View Products
                  </span>
                </Button>
              </Box>
            </Box>

            {/* Right Content box */}
            <Box
              className="about_right_Content"
              sx={{
                width: "60%",
              }}
            >
              <Typography
              className="Right_content_text"
                sx={{
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "25px",
                  fontFamily: "DM Sans",
                  // textAlign: "justify",
                }}
              >
                PWR Steer, a division of Premium Guard Inc., was established in
                2018 with a clear vision: to set a new standard for aftermarket
                power steering solutions by providing innovative, high-quality
                products engineered for reliability and seamless performance. As
                a pioneer in introducing 100% new, first-to-market power
                steering products, PWR Steer eliminates the common issues
                associated with remanufactured parts, offering a dependable
                alternative that technicians and customers can trust.
              </Typography>
              <br />
              <Typography
               className="Right_content_text"
                sx={{
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "25px",
                  fontFamily: "DM Sans",
                  // textAlign: "justify",
                }}
              >
                Our commitment to excellence is reflected in every aspect of our
                product line, which includes power steering pumps, racks,
                pinions, gearboxes, and reservoirs, each designed and
                manufactured to meet or exceed OEM specifications. Through
                strategic collaborations with Tier 1 OE manufacturers and a
                dedication to rigorous quality control standards, PWR Steer
                products are crafted to ensure high durability, reliability, and
                ease of installation. Utilizing advanced manufacturing processes
                that adhere to ISO/TS/OHSA certifications, PWR Steer guarantees
                that each part upholds the highest industry standards, reducing
                failure rates and installation times significantly.
              </Typography>
              <br />
              <Typography
               className="Right_content_text"
                sx={{
                  fontWeight: 400,
                  fontSize: "16px",
                  lineHeight: "25px",
                  fontFamily: "DM Sans",
                  //textAlign: "justify",
                }}
              >
                Our exclusive features, such as integral and remote reservoirs,
                OE-approved sensors, and pre-installed pulleys, cater to the
                demands of modern vehicles. These precision-engineered
                components help reduce labor costs and provide a secure fit,
                while our specially designed packaging minimizes damage during
                transit. With a return rate consistently at or below 3%, PWR
                Steer’s products underscore our commitment to quality,
                delivering solutions that reduce warranty claims and enhance
                customer satisfaction.
              </Typography>
            </Box>
          </Box>

          {/* 3rd row */}
          <Box
            className="about_third_box"
            sx={{
              width: "100%",
              display:'flex',
              flexDirection:'column',
              gap:'60px',
              backgroundColor: "#F9F9F9",
              paddingBottom: "5px",
              boxSizing: "border-box",
            }}
          >
            <Box
              className="about_third_box_sub"
              sx={{
                margin: "30px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                paddingTop: "40px",
                gap: 10,
                marginTop:0,
                marginBottom:0
              }}
            >
              {/* image box */}
              <Box
                className="about_thirdbox_img_container"
                sx={{
                  width: "50%",
                }}
              >
                <LazyLoadImage
                  className="repair_image"
                  src={Repair1}
                  alt="About Us"
                  style={{
                    borderRadius: "13px",
                    height: "auto",
                  }}
                />
              </Box>

              {/* content box */}
              <Box
                className="about_thirdbox_textcontentbox"
                sx={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  gap:'40px'
                }}
              >
                <Box
                  className="about_thirdbox_textcontentbox_sub"
                  sx={{
                    width: "100%",

                    color: "#0B0F11",
                  }}
                >
                  <Typography
                    className="about_textcontent_heading"
                    sx={{
                     

                      fontWeight: "700",
                      fontSize: "24px",
                      lineHeight: "22px",
                      fontFamily: "DM Sans",
                      color: "#55120B",

                    }}
                  >
                    Innovative Product Line
                  </Typography>
                  <br />
                  <Box
                    className="about_text"
                    sx={{
                      width: "100%",

                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "25px",
                      fontFamily: "DM Sans",
                    }}
                  >
                    <Typography
                     className="About_text_innovative_product"
                      sx={{
                        fontFamily: "DM Sans",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "25px",
                        //textAlign: "justify",
                      }}
                    >
                      Every PWR Steer product is 100% new, eliminating the
                      quality risks associated with remanufactured parts. Our
                      pumps, for example, come equipped with OE-specific
                      pulleys, reservoirs, and sensors where applicable,
                      ensuring that each part is tailored to meet precise
                      vehicle requirements. PWR Steer racks and pinions are
                      manufactured with OE-grade materials and include integral
                      mounting bushings and exact transfer tubes, delivering the
                      closest fit to original parts available in the
                      aftermarket.
                    </Typography>
                    <br />
                    <Typography
                       className="About_text_innovative_product"
                      sx={{
                        fontFamily: "DM Sans",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "25px",
                        //textAlign: "justify",
                      }}
                    >
                      We continue to lead with product innovations, such as our
                      Exclusive PR and PXR Pump models, which include
                      pre-installed or external mounted reservoirs and pulleys
                      designed to simplify installation. These products offer
                      unique value by providing technicians with all necessary
                      components in one box, minimising the need for additional
                      parts and ensuring a smoother installation process. Our
                      gearboxes feature 100% new housings and internal
                      components, backed by corrosion-resistant coatings and
                      protective packaging, making them as durable as they are
                      dependable.
                    </Typography>
                  </Box>
                </Box>

                <Box
                  className="about_text"
                  sx={{
                    width: "100%",

                    color: "#0B0F11",
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "25px",
                    // marginTop: "20px",
                    fontFamily: "DM Sans",
                  }}
                >
                  <Typography
                    className="about_textcontent_heading"
                    sx={{
                     

                      fontWeight: 700,
                      fontSize: "24px",
                      lineHeight: "22px",
                      fontFamily: "DM Sans",
                      color: "#55120B",

                    }}
                  >
                    About Premium Guard Inc.
                  </Typography>
                  <br />
                  <Typography
                     className="About_text_innovative_product"
                    sx={{
                      fontFamily: "DM Sans",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "25px",
                      //textAlign: "justify",
                    }}
                  >
                    Premium Guard Inc. (PGI), founded in 1996, is a respected
                    name in the automotive aftermarket, known for pioneering
                    quality manufacturing and product excellence. PGI’s mission
                    is to provide turnkey solutions with comprehensive
                    application coverage, enabling our partners to succeed in
                    all segments of the automotive aftermarket across North
                    America. Leveraging over 25 years of expertise, PGI
                    specialises in private label programs of the highest
                    quality, delivering performance and reliability that
                    customers depend on.
                  </Typography>
                  <br />
                  <Typography
                     className="About_text_innovative_product"
                    sx={{
                      fontFamily: "DM Sans",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "25px",
                      //textAlign: "justify",
                    }}
                  >
                    With a strong focus on integrity, team unity, and
                    operational excellence, PGI fosters a culture of continuous
                    improvement that drives our commitment to customers. This
                    philosophy extends to our rigorous ISO 9001:2015 certified
                    quality management system, which emphasises defect
                    prevention and minimises waste and inefficiencies throughout
                    the production and distribution process. From manufacturing
                    to delivery, PGI’s high standards help ensure that every
                    product embodies our dedication to quality.
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              className="about_fourthbox"
              sx={{
                margin: "0 30px 30px 30px",
                height: "auto",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                gap:'40px'
              }}
            >
              {/* content box */}
              <Box
                className="fourth_box_sub"
                sx={{
                  width: "50%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  gap:'40px'
                }}
              >
                <Box
                  sx={{
                    width: "100%",

                    color: "#0B0F11",
                  }}
                >
                  <Typography
                    className="about_textcontent_heading"
                    sx={{
                      width: "100%",
                      fontWeight: "700",
                      fontSize: "24px",
                      lineHeight: "22px",
                      fontFamily: "DM Sans",
                      color: "#55120B",

                    }}
                  >
                    Our Values and Vision
                  </Typography>
                  <br />
                  <Box
                    className="fourthbox_text"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Typography
                       className="About_text_innovative_product"
                      sx={{
                        fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "25px",
                        fontFamily: "DM Sans",
                        //textAlign: "justify",
                      }}
                    >
                      Premium Guard, Inc. is built on a foundation of core
                      values that guide everything we do: integrity,
                      customer-centricity, and commitment to operational
                      excellence. We strive to build a high-performance culture
                      that challenges us to improve constantly, go the extra
                      mile, and lead by example. PGI’s vision is to offer our
                      customers unmatched service, industry-leading product
                      coverage, and best-in-class quality at competitive prices,
                      ensuring they have a distinct advantage in today’s
                      demanding market.
                    </Typography>
                  </Box>
                </Box>

                <Box
                  className="about_text1"
                  sx={{
                    width: "100%",

                    color: "#0B0F11",
                    // marginTop: "50px",
                  }}
                >
                  <Typography
                    className="about_textcontent_heading"
                    sx={{
                      width: "100%",

                      fontWeight: "700",
                      fontSize: "24px",
                      lineHeight: "22px",
                      fontFamily: "DM Sans",
                      color: "#55120B",

                    }}
                  >
                    Customer Commitment and Support
                  </Typography>
                  <br />
                  <Box
                    className="fourthbox_text"
                    sx={{
                      width: "100%",

                    }}
                  >
                    <Typography
                       className="About_text_innovative_product"
                      sx={{
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "25px",
                        fontFamily: "DM Sans",
                        //textAlign: "justify",
                      }}
                    >
                      Customer satisfaction is central to PWR Steer's values and
                      to PGI’s longstanding commitment to the aftermarket
                      industry. Our ASE-certified support team is readily
                      available by phone and online, offering expert assistance
                      with technical inquiries and product guidance. This
                      dedication to service makes PWR Steer the trusted choice
                      of professionals and DIY enthusiasts alike. With product
                      coverage spanning over 74% of vehicles on the road today,
                      PWR Steer enables customers to expand their market
                      presence, optimize their offerings, and enhance
                      profitability with confidence.
                      <br />
                      <br />
                      For premium quality, ease of installation, and dependable
                      customer support, choose PWR Steer – the power steering
                      solution trusted by industry professionals and backed by
                      PGI’s legacy of excellence.
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {/* image box */}
              <Box
                className="about_thirdbox_img_container1"
                sx={{
                  width: "50%",
                  marginLeft: "6%",
                }}
              >
                <LazyLoadImage
                  className="repair_image1"
                  src={Repair2}
                  alt="About Us"
                  style={{
                    borderRadius: "13px",
                    height: "auto",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <CommonFooter />
    </LayoutComponent>
  );
};

export default AboutPwr;
