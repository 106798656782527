import {
  Box,
  Typography,
  Button,
  TextField,
  Checkbox,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import React, { useState, useRef, useEffect, } from "react";
import Question1 from "../../Assets/Home/question1.png";
import GetInTouchImg from "../../Assets/Home/GetInTouch.png";
import Vector from "../../Assets/Home/VectorArrow.png";
import InputAdornment from "@mui/material/InputAdornment";
import USAFlag from "../../Assets/Home/usaFlag.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ApiLink } from "../Utils/Apilink";
import { useSelector, useDispatch } from "react-redux";
import { CommonSetupActions } from "../../Slices/Commonslice";

export const FrequentlyAskedQuest = () => {
  const [question, setQuestion] = useState([
    {
      question:
        "What sets PWR Steer apart from other power steering part providers?",
      answer:
        "PWR Steer products are 100% new, engineered to meet or exceed Original Equipment (OE) quality standards, and are designed for long-lasting performance. Our parts come installation-ready with everything needed for a hassle-free fit, which helps save time and money, ensures the job is done right the first time. Additionally, our low warranty rates reflect our commitment to reliability and quality.",
    },
    {
      question: "Are PWR Steer products remanufactured?",
      answer:
        "No, PWR Steer specializes exclusively in brand-new power steering parts. Unlike remanufactured options, which may have a higher failure rate, our products are designed and built from scratch to meet stringent quality standards and deliver dependable performance every time.",
    },
    {
      question:
        "Does PWR Steer provide support for installation or technical questions?",
      answer:
        "Yes, PWR Steer offers dedicated technical support to assist with installation and any product-related questions. Our ASE-certified support team is available by phone and online to provide guidance and ensure a smooth installation process. Additionally, our YouTube channel, under the Steering Solutions section, offers instructional videos and tips.",
    },
    {
      question: "Do PWR Steer parts come with a warranty?",
      answer:
        "Yes, all PWR Steer products come with a limited lifetime warranty that reflects our confidence in their quality and durability. Our low warranty rates demonstrate our commitment to delivering reliable parts, so you can install with peace of mind knowing we stand behind our products.",
    },
    {
      question:
        "Why should I choose 100% new parts over remanufactured options?",
      answer:
        "Choosing 100% new parts, like those offered by PWR Steer, eliminates the risks associated with remanufactured products, which often have higher failure rates. Technicians prefer working with new parts because they provide a reliable, first-time fit and consistent performance, reducing installation time and minimizing the chance of returns. Our parts are designed to meet OE standards, delivering superior reliability and peace of mind that remanufactured parts simply can’t match.",
    },
  ]);
  const [loader, setLoader] = useState(false);
  const {scrollAutomatic, scrollSamePage}=useSelector((state)=>state.CommonStore)
  const getInTouchRef = useRef(null);
  const dispatch=useDispatch()

  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, SetMessage] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleClose = () => {
    setOpen(false);
  };

 const scrollToGetInTouch = () => {
    if (getInTouchRef?.current) {
      getInTouchRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
            dispatch(CommonSetupActions.SetscrollSamepage(false))
      
    }
  };

  useEffect(() => {
    if ((scrollAutomatic && getInTouchRef?.current)|| scrollSamePage) {
      scrollToGetInTouch();
    }
  }, [scrollAutomatic, getInTouchRef, scrollSamePage]);

  const submitGetintouchForm = () => {
    try {
      let data = {
        name: formFields.first_name + " " + formFields.last_name,
        phone: formFields.phone,
        email: formFields.email,
        company: formFields.company,
        message: formFields.message,
        terms: 1,
      };
      if (
        formFields.terms !== false &&
        formFields.last_name !== "" &&
        formFields.last_name !== "" &&
        formFields.email !== "" &&
        formFields.phone !== "" &&
        formFields.message !== ""
      ) {
        setLoader(true);
        fetch(ApiLink + "contactForm", {
          method: "POST",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(data),
        })
          .then((res) => res.json())
          .then((response) => {
            setLoader(false);
            setFormFields({
              first_name: "",
              last_name: "",
              phone: "",
              email: "",
              company: "",
              message: "",
              terms: false,
            });
            SetMessage(response.message);
            setOpen(true);
          });
      } else {
        if (formFields.first_name === "") {
          SetMessage("Please enter your first name.");
        } else if (formFields.last_name === "") {
          SetMessage("Please enter your last name.");
        } else if (formFields.email === "") {
          SetMessage("Please enter your email.");
        } else if (formFields.phone === "") {
          SetMessage("Please enter your mobile number.");
        } else if (formFields.terms === false) {
          SetMessage("Please accept terms and condition");
        }
        setOpen(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [formFields, setFormFields] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    company: "",
    message: "",
    terms: false,
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormFields({
      ...formFields,
      [name]: type === "checkbox" ? checked : value,
    });

  };

  return (
    <Box
      className="faq_main_container"
      sx={{
        width: "100%",
        height: "auto",
        boxSizing: "border-box",
        marginTop: "100px",
        marginBottom: "60px",
      }}
    >
      {/* freq. ask question title box */}
      

      {/* Main box -- freq. ask quest and get in touch form  */}
      <Box
        className="faq_container"
        sx={{
          width: "100%",
          marginTop: "60px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          boxSizing: "border-box",

          padding: "20px",
          gap: 2,
        }}
      >
        {/* questions */}
        <Box
          className="faq_q_container"
          sx={{
            width: "50%",
            display:'flex',
            flexDirection:"column",
            justifyContent:'space-between'

            // height: "668px",
          }}


        >
           <Box
        sx={{
          width: "90%",
        
          textAlign: "center",
          marginBottom:'32px'
        }}
      >
        <Typography
        className="freq_ask_ques_title_typo"
          sx={{
            fontWeight: 600,
            fontSize: "36px",
            fontFamily: "DM Sans",
            lineHeight:'50px',
            color: "#000000",
            textDecoration:'underline',
            textDecorationColor:'#E30000',
            textUnderlineOffset: '10px',

          }}
        >
          Frequently Asked Questions
        </Typography>
      </Box>
          
          {question.map((ques, index) => {
            return (
              <Accordion
                className="faq_accordian_main"
                expanded={expanded === index}
                onChange={handleChange(index)}
                key={index}
                sx={{
                  marginBottom: "20px",
                  width: "90%",
                  height: "auto",
                  backgroundColor: "#FAFAFA",
                  // padding: "5px",
                  borderRadius: "9px",
                  border: "1px solid #A6A6A6",
                  padding: "10px",
                  overflow:'hidden'
                }}
              >
                <AccordionSummary
                  className="faq_accordian_question"
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    height:'auto',
                    color: "#000000",
                    fontFamily: "DM Sans",
                    fontWeight: 600,
                    fontSize: "18px",
                    lineHeight: "25px",
                    textAlign: "left",
                  }}
                >
                  {ques.question}
                </AccordionSummary>
                <AccordionDetails
                  className="faq_accordian_details"
                  sx={{
                    width: "90%",
                    height: "auto",
                    fontFamily: "DM Sans",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "22px",
                    color: "#3A3A3A",
                    textAlign: "left",
                  }}
                >
                  {ques.answer}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>

        {/* get in touch main box */}
        <Box
        ref={getInTouchRef}
          className="getintouch_container"
          sx={{
            width: "650px",
            height: "100%",
            color: "#D9D9D9",
            borderRadius: "17px",
            backgroundImage: `url(${GetInTouchImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "30px 10px",
                    }}
        >
          {/* title */}
          <Box
            className="getintouch_title"
            sx={{
              width: "600px",
              height: "65px",
              justifyContent: "flex-start",
              marginLeft: 0,
            }}
          >
            <Typography
              sx={{
                fontFamily: "DM Sans",
                fontWeight: 900,
                fontSize: "34px",
                lineHeight: "44px",
                color: "#FFFFFF",
              }}
            >
              <span style={{ color: "#EC1B24", fontFamily: "DM Sans", }}>Contact </span>
              <span style={{ color: "#ffffff",fontFamily: "DM Sans", }}>Us</span>
            </Typography>
          </Box>

          {/* form */}
          <Box
            className="getintouch_form"
            sx={{
              width: "560px",
              height: "474px",
              border: "1px solid #A6A6A6",
              backgroundColor: "#FAFAFA",
              borderRadius: "13px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "20px",
              paddingTop: 0,
              paddingBottom: 0,

              overflow: "hidden",
            }}
          >
            {/* Input Fields */}
            <Box
              className="getintouch_input_fields1"
              sx={{
                marginTop: "15px",
                display: "flex",
                flexDirection: "row",
                gap: "20px",
              }}
            >
              <TextField
                className="mui-text-field"
                name="first_name"
                placeholder="First Name"
                value={formFields.first_name}
                onChange={handleInputChange}
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "7px",
                    height: "45px",
                    backgroundColor: "#FFFFFF",
                    fontFamily: "DM Sans",
                  },
                  // "& .Mui"
                  fontSize: "16px",
                  width: "48%",
                  height: "45px",
                }}
              />
              <TextField
                className="mui-text-field"
                name="last_name"
                placeholder="Last Name"
                value={formFields.last_name}
                onChange={handleInputChange}
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "7px",
                    height: "45px",
                    backgroundColor: "#FFFFFF",
                    fontFamily: "DM Sans",
                  },
                  fontSize: "16px",
                  width: "48%",
                  height: "45px",
                }}
              />
            </Box>

            <Box
              className="getintouch_input_fields2"
              sx={{
                marginTop: "15px",
                display: "flex",
                flexDirection: "row",
                gap: "20px",
              }}
            >
              <TextField
                className="mui-text-field"
                name="phone"
                placeholder="Mobile"
                value={formFields.phone}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          backgroundColor: "#FFFFFF",
                          fontFamily: "DM Sans",
                        }}
                      >
                        <img
                          src={USAFlag}
                          alt="USA Flag"
                          style={{
                            width: "20px",
                            height: "12px",
                            borderRadius: "1px",
                            backgroundColor: "#FFFFFF",
                            fontFamily: "DM Sans",
                          }}
                        />
                        <span
                          style={{
                            fontSize: "16px",
                            fontWeight: 400,
                            backgroundColor: "#FFFFFF",
                            fontFamily: "DM Sans",
                          }}
                        >
                          +1
                        </span>
                        <Box
                          sx={{
                            width: "1px",
                            height: "46px",
                            backgroundColor: "#ccc",
                            marginLeft: "8px",
                          }}
                        />
                      </Box>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "7px",
                    height: "45px",
                    backgroundColor: "#FFFFFF",
                    fontFamily: "DM Sans",
                  },
                  fontSize: "16px",
                  width: "48%",
                  height: "45px",
                }}
              />
              <TextField
                className="mui-text-field"
                name="email"
                placeholder="Email"
                value={formFields.email}
                onChange={handleInputChange}
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "7px",
                    height: "45px",
                    backgroundColor: "#FFFFFF",
                    fontFamily: "DM Sans",
                  },
                  fontSize: "16px",
                  width: "48%",
                  height: "45px",
                }}
              />
            </Box>

            <Box sx={{ marginTop: "15px" }}>
              <TextField
                className="mui-text-field"
                name="company"
                placeholder="Company"
                value={formFields.company}
                onChange={handleInputChange}
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "7px",
                    height: "45px",
                    backgroundColor: "#FFFFFF",
                    fontFamily: "DM Sans",
                  },
                  fontSize: "16px",
                  height: "45px",
                }}
              />
            </Box>

            <Box sx={{ marginTop: "15px" }}>
              <TextField
                className="mui-text-field"
                name="message"
                placeholder="Message"
                value={formFields.message}
                onChange={handleInputChange}
                multiline
                rows={4}
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "7px",
                    height: "140px",
                    backgroundColor: "#FFFFFF",
                    fontFamily: "DM Sans",
                  },
                  fontSize: "16px",
                  height: "140px",
                }}
              />
            </Box>

            {/* Checkbox */}
            <Box
              sx={{
                width: "100%",
                height: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: "20px",
                gap:"10px"
              }}
            >
              <Checkbox
                name="terms"
                checked={formFields.terms}
                onChange={handleInputChange}
                size="small"
                sx={{
                  color: "#348D2D",
                  "&.Mui-checked": {
                    color: "#348D2D",
                  },
                  "&.MuiCheckbox-root":{
                   padding:0
                  }
                }}
              />
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#7C7C7C",
                  lineHeight: "17px",
                  fontFamily: "DM Sans",
                  textAlign:'flex-start'
                }}
              >
                I confirm that I have read, understand, and agree to PWR
                Steering.
              </Typography>
            </Box>

            {/* Submit Button */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "15px",
              }}
            >
              <Button
                variant="contained"
                onClick={() => submitGetintouchForm()}
                sx={{
                  width: "126px",
                  height: "45px",
                  borderRadius: "100px",
                  backgroundColor: "#EC1B24",
                  color: "#FFFFFF",
                  textTransform: "none",
                  borderRadius: "50px",
                  padding: "10px 20px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  "&:hover":{
                    backgroundColor:'#a51219'
                  }
                }}
              >
                {loader ? (
                  <CircularProgress color="#ffffff" size="1rem" />
                ) : (
                  "Submit"
                )}
                <img
                  src={Vector}
                  sx={{
                    width: "27.5px",
                    border: "1px solid #55120B",
                  }}
                />
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Snackbar
        autoHideDuration={6000}
        open={open}
        onClose={handleClose}
        message={message}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: '#EC1B24', // Change to your desired color
            color: 'white', // Optional: Change text color
          },
        }}
      />
    </Box>
  );
};
