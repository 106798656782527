import React, { useState, useRef, useEffect } from "react";
import LayoutComponent from "../../Layout/LayoutComponent";
import CommonFooter from "../Footer/CommonFooter";
import { Box, Typography, InputBase, ListItem, List } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ApiLink } from "../Utils/Apilink";
import CommonLoader from "../CommonComponent/CommonLoader";
import "./techInfo.css";

export const TechnicalInfo = () => {
  const [searchItem, setSearchItem] = useState("");
  const [pdfList, setPdfList] = useState([]);
  const controllerRef = useRef(null);
  const [loading, setLoading] = useState(false);
useEffect(()=>{
      
    // dispatch(CommonSetupActions.resetState())

      if (!sessionStorage.getItem("hasRefreshed")) {
        sessionStorage.setItem("hasRefreshed", "true"); // Mark as refreshed
        window.location.reload();
      };
     
    },[])
  const fetchBlog = (searchitem) => {
    setLoading(true);

    if (controllerRef.current) {
      controllerRef.current.abort();
    }

    controllerRef.current = new AbortController();

    const link2 =
      searchitem || searchItem !== ""
        ? `${ApiLink}tech-info/${searchItem}}`
        : `${ApiLink}tech-info`;
    fetch(link2, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      signal: controllerRef.current.signal,
    })
      .then((resp) => {
        return resp.json();
      })
      .then((response) => {
        if (response.length === 0) {
          setSearchItem("");
        }
        setPdfList(response);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchBlog();
  }, []);
  const handleSearchChange = (e) => {
    setSearchItem(e.target.value);
  };

  const downloadPDF = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
    // const a = document.createElement("a");
    // a.href = link;
    // a.download = link.split("/").pop();
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
  };

  return (
    <LayoutComponent>
      <Box
        sx={{
          width: "100%",
          // height: "100vh",
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          className="main-tech-info-box"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            boxSizing: "border-box",
            margin: "70px",
            flexWrap: "wrap",
          }}
        >
          {/* technical info heading */}
          <Box
            className="tech-info_heading_box"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/* blogs heading */}
            <Box>
              <Typography
                className="technical-head-typo "
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 800,
                  fontSize: "60px",
                  color: "#000000",
                  lineHeight: "50px",
                  textDecoration: "underline",
                  textUnderlineOffset: "14px",
                  textDecorationColor: "#E30000",
                  textDecorationThickness: "3.5px",
                }}
              >
                Technical Resources
              </Typography>
            </Box>

            {/* Autocomplete search bar */}
            <Box
              className="auto-articles-search-section"
              sx={{
                // width: "350px",
                width:'30%',
                height: "30px",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#EDEDED",
                borderRadius: "44px",
                padding: "5px 10px",
              }}
            >
              {/* Search Icon */}
              <SearchIcon
                sx={{
                  color: "black",
                  marginRight: "8px",
                  fontSize: "18px",
                  height: "25px",
                  width: "25px",
                }}
              />

              {/* Input Field */}
              <InputBase
                placeholder="Search for anything"
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "12px",
                  color: "#646464",
                  width: "100%",
                }}
                value={searchItem}
                onChange={handleSearchChange}
              />
            </Box>
          </Box>
          <hr
            className="war-hr-line"
            style={{
              width: "100%",
              marginTop: "2%",
            }}
          />

          <Box
            className="technical-info-body-main"
            sx={{
              width: "100%",
              marginTop: 5,
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <Box className="tech-info-desc-box">
              <Typography
                className="tech-info-desc-typo"
                sx={{ fontFamily: "DM Sans", fontSize: "16px", fontWeight:400, lineHeight:'25px' }}
              >
                At PWR Steer, we're dedicated to keeping our customers up to
                date on the latest technologies and products. Through our
                comprehensive resources—including technical service bulletins,
                installation instructions, and expert videos—we ensure you have
                the tools to get the job done right the first time.
              </Typography>
            </Box>
            {loading ? (
              // Loader
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <CommonLoader
                  open={loading}
                  handleClose={() => setLoading(false)}
                />
              </Box>
            ) : pdfList ? (
              <Box>
                <List
                  className="tech-info-list"
                  component="div"
                  sx={{ pl: 4, width: "90%" }}
                >
                  {pdfList
                    .filter(
                      (pdf) =>
                        pdf.resources_path &&
                        pdf.resources_path
                          .toLowerCase()
                          .includes(searchItem.toLowerCase())
                    )
                    .map((pdf, index) => (
                      <ListItem className="tech-info-list-item" key={index}>
                        <Typography sx={{ fontSize: "30px", fontFamily: "DM Sans", }}>•</Typography>
                        <Typography className="tech-info-list-content"
                          onClick={() => downloadPDF(pdf.newpath)}
                          sx={{
                            fontFamily: "DM Sans",
                            fontSize: "18px",
                            fontWeight: 500,
                            color: "#000",
                            "&:hover": { color: "#E71b2c" },
                            cursor: "pointer",
                            textDecoration: "underline",
                            textUnderlineOffset: "5px",
                            textAlign: "justify",
                            width: "97%",
                            overflowWrap: "break-word",
                            marginLeft: "20px",
                          }}
                        >
                          {pdf.resources_path}
                        </Typography>
                      </ListItem>
                    ))}
                </List>
              </Box>
            ) : (
              <Typography></Typography>
            )}
          </Box>
        </Box>
      </Box>
      <CommonFooter />
    </LayoutComponent>
  );
};
