import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import Play from "../../Assets/HeaderIcon/youTubeN.svg";
import "../Home/Css/Home.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const VideoPlayer = ({ videoUrl,title, thumbnail, index, isPlaying, onPlay }) => {
  //   const [isPlaying, setIsPlaying] = useState(false);
  const [clickedIndex, setClickedindex] = useState();

  // Extract YouTube video ID from the URL
  const videoId = videoUrl.split("v=")[1]?.split("&")[0];
 

  return (
    <div
      className="vedioplayer_container_main"
      style={{
       width:'100%',
        padding: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {!isPlaying ? (
        <div                         tabIndex={0}

          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            position: "relative",
          }}
          onClick={onPlay}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            onPlay()
          }
        }}
        >
          <LazyLoadImage
            className="img_vedio"
            src={
              thumbnail ||
              `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
            }
            alt={title}
            style={{
              width: "100%",
              borderRadius:'12px',
              // borderTopLeftRadius: index === 0 ? "12px" : "0px",
              // borderBottomLeftRadius: index === 0 ? "12px" : "0px",
              // borderTopRightRadius: index === 2 ? "12px" : "0px",
              // borderBottomRightRadius: index === 2 ? "12px" : "0px",
              height: "auto",
              filter: "grayscale(100%)",
            }}
          />
          <div style={{ position: "absolute" }}>
            <img src={Play} />
          </div>
        </div>
      ) : (
        <iframe
          className="iframe_div"
          width="100%"
          // height="300px"
          height="300px"
          style={{ borderRadius: "12px" }}
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
          title="YouTube video player"
          tabIndex={0}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
    </div>
  );
};

const ExpertTips = () => {
  const [playingIndex, setPlayingIndex] = useState(null);
  function truncateString(str, maxLength) {
    if (str.length > maxLength) {
        return str.substring(0, maxLength - 3) + '...';
    }
    return str;
}

  const vedioArr = [
    {
      url: "https://www.youtube.com/watch?v=KUidgBJVmRk&t=42s",
      title: "Installing Rack and Pinion",
      description: `• Check belts for cracks or oil contamination
• Check hoses for leaks or wear and replacing them if they are older than 5 years
• Inspect the power steering system and the suspension system
• Always install a new remote reservoir, if applicable
• Flush the system with recommended fluid type
• Compare the new product to the old product
• Every part should be installed to factory torque specs
• Vacuum bleed the system every time a line is open `,
    },
    {
      url: "https://www.youtube.com/watch?v=blVFoA_iUhg",
      title: "Changing out power steering box",
      description: `• Inspect the power steering system and the suspension system
• Check belts and hoses for cracks, leaks, or oil contamination, and consider replacing them if they are 5 years or older
• Compare the new product to the old product
• Flush the system with recommended fluid type
• Always install a new remote reservoir, if applicable
• Always re-assemble using factory torque specs 
• Vacuum bleed the system every time a line is open `,
    },
    {
      url: "https://www.youtube.com/watch?v=gjeacN6sK78",
      title: "Replacing a power steering pump",
      description: `• Check belts for cracks or oil contamination
• Check hoses for leaks or wear and consider replacing them if they are older than 5 years
• Inspect the power steering system and suspension
• Compare the new product to the old product
• Always install a new reservoir, if applicable
• Every part should be installed to factory torque specs
• Flush the system with recommended fluid type
• Vacuum bleed the system every time a line is open `,
    },
  ];
  return (
    <Box className="expt_tip_section1"
      sx={{
        width: "100%",
        display: "flex",

        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 10,
        gap: 4,
      }}
    >
      <Box>
        <Typography
        className="expert-tip-head-typo"
          sx={{
            fontWeight: 600,
            fontSize: "36px",
            fontFamily: "DM Sans",
            lineHeight:'50px',
            color: "#000000",
            textAlign:'center',
            textDecoration:'underline',
            textDecorationColor:'#E30000',
            textUnderlineOffset: '10px',

          }}
        >
          Expert Tips & Tutorials
        </Typography>
      </Box>
      <Box
        className="expert_tips_video_container"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "stretch", 
          width: "100%",
          gap:2
        }}
      >
        {vedioArr.map((video, index) => {
          return (
            <div
              className="vedio_div"
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                height: "100%", 
                "&:hover":{
                 boxShadow:'1px 1px 1px 1px red'
                }
              }}
            >
              <VideoPlayer
                videoUrl={video.url}
                title={video.title}
                index={index}
                isPlaying={playingIndex === index}
                onPlay={() => setPlayingIndex(index)}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  width: '90%',
                  textAlign: 'center',
                  height: 'auto', // Ensures description doesn't affect layout
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "18px",
                    fontFamily: "DM Sans",
                  }}
                >
                  {video.title}
                </Typography>
             
              </Box>
            </div>
          );
        })}
      </Box>
    </Box>
  );
};

export default ExpertTips;
