import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  darken,
  Grid,
  Grid2,
  InputAdornment,
  lighten,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import routes from "../../AppRoutes/routes.json";
import { useDispatch, useSelector } from "react-redux";
import { searchProduct } from "./AutocompleteAction";
import { CommonSetupActions } from "../../Slices/Commonslice";
import "../Home/Css/Home.css";

import "../ProductPages/Ecatlog.css";
import { ApiLink } from "../Utils/Apilink";
import styled from "@emotion/styled";

const AutoComplete = ({ onSearchComplete, setOpenModal }) => {
  const [searcheddata, setSearcheddata] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [debounceTimer, setDebounceTimer] = useState(null);
  const controllerRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedDropdownOpen, setSelectedDropdownOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedProduct = useSelector(
    (state) => state.CommonStore.selectedProduct
  );
  const [open, setOpen] = useState(false);

  const fetchProducts = (searchtextt) => {
    if (controllerRef.current) {
      controllerRef.current.abort();
      setSearcheddata([]);
    }

    controllerRef.current = new AbortController();

    if (!searchtextt.trim()) return;
    fetch(ApiLink + "websitedynamic/" + searchtextt, {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },

      signal: controllerRef.current.signal,
    })
      .then((resp) => {
        return resp.json();
      })
      .then((response) => {

        if (response.length > 0) {
        }
        const formattedData = response.map((item) => ({
          label: item.label, // Use 'label' for display
          id: item.id, // Keep 'id' if needed later
          value: item.value, // Add other properties if necessary
          type: response[0].label,
        }));
        if (response.length > 0) {
          formattedData.splice(0, 1);
          setSearcheddata(formattedData);
        } else {
          setSearcheddata([{ id: 1, label: " " }]);
        }

        setOpen(formattedData.length > 0);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        controllerRef.current = null;
      });
  };

  useEffect(() => {
    if (Object.keys(selectedProduct).length === 0) {
      setSelectedValue(null);
      setSearchText("");
      setSearcheddata([]);
    } else {
      setSelectedValue(selectedProduct);
    }
  }, [selectedProduct]);

  const handleInputChange = (event, newInputValue) => {
    if (debounceTimer) clearTimeout(debounceTimer);
    setSearchText(newInputValue);
    const timer = setTimeout(() => {
      if (newInputValue.trim().length >= 2) {
        fetchProducts(newInputValue);
      }
    }, 600);

    setDebounceTimer(timer);
    setSearchText(newInputValue);
    if (!newInputValue.trim()) {
      setSearcheddata([]);
      setSelectedValue(null);
      setOpen(false);
      dispatch(CommonSetupActions.setQuallifierCollection({}));
      dispatch(CommonSetupActions.setSubModel({}));
      dispatch(CommonSetupActions.setProductList([]));
      dispatch(CommonSetupActions.setProductlistMessage(false));
    }
  };

  const handleOptionSelect = (event, newValue) => {
    if (newValue) {
      setSearcheddata([]); // Clear options
      setSearchText(""); // Clear the input field
      setLoading(true); // Show loading indicator
      setOpen(false);

      // Dispatch actions or call the desired function
      dispatch(CommonSetupActions.setSelectedProduct(newValue));
      dispatch(
        searchProduct(
          newValue,
          navigate,
          setSearcheddata,
          setLoading,
          onSearchComplete,
          setOpenModal
        )
      );
      setTimeout(() => {
        setOpen(false);
        setSearcheddata([]);
      }, 0);
    }
  };

  const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    // top: '-8px',
    padding: '4px 10px',
    color: "grey",
    fontWeight:600
   
  }));
  
  const GroupItems = styled('div')({
    position: 'sticky',
    padding: '4px 10px !important',
  });

  return (
    // <Loader />
    <Autocomplete
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
       
      }}
      groupBy={(option) => option.type}
      clearOnBlur={false}
      getOptionLabel={(option) => option?.label || "No result found"}
      value={selectedValue || null}
      inputValue={searchText || ""}
      filterOptions={(options) => options}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      // open={searcheddata.length > 0}

      // filterSelectedOptions
      blurOnSelect={false}
      // open={open}
      open={selectedDropdownOpen && searcheddata.length > 0}
      onOpen={() => setSelectedDropdownOpen(true)}
      onClose={() => setSelectedDropdownOpen(false)}
      onChange={handleOptionSelect}
      // onInputChange={(params: { inputValue: string }) => handleInputChange(params)}

      onInputChange={handleInputChange}
      options={searcheddata}
      openOnFocus={false}
      renderInput={(params) => (
        <TextField
          className="textfield_pwr_search"
          {...params}
          variant="filled"
          // label="Type Year,Make,Model,Engine,Part Number,VIN"
          // placeholder="Type Year, Make, Model, Engine, Part Number, VIN"
          placeholder="Search by Application, Vin Number, Parts, Part Number"
          sx={{
            backgroundColor: "#FFFFFFED",
            width: "50%",
            height: "47px",
            borderRadius: "50px",
            justifyContent: "center",

            "& .MuiFilledInput-root": {
              display: "flex",
              justifyContent: "center",
              backgroundColor: "white",
              width: "100%",
              height: "100%",
              borderRadius: "50px",
              paddingRight: "4px!important",
              "& input": {
                marginBottom: 2,
              },
            },
            "& input": {
              marginBottom: 2,
            },
            "&:before": {
              borderBottom: "none", // Removes the blue underline on focus
            },
            "&:after": {
              borderBottom: "none", // Removes the blue underline on focus
            },
            "& .MuiFilledInput-underline:before": {
              borderBottom: "none", // Removes the blue underline on blur
            },
            "& .MuiFilledInput-underline:after": {
              borderBottom: "none", // Removes the blue underline on focus
            },
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  marginBottom: 4,
                  position: "relative",
                  left: "2px",
                }}
              >
                <SearchIcon
                  style={{ color: "black" }}
                  sx={{ height: "35px", width: "35px" }}
                />
              </InputAdornment>
            ),
            endAdornment: loading ? (
              <CircularProgress size="1rem" sx={{ marginRight: "10px" }} />
            ) : null,
          }}
        />
      )}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
    />
  );
};

export default AutoComplete;
