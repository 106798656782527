import React from "react";
import {
  Box,
  Button,
  FormControl,
  OutlinedInput,
  Typography,
} from "@mui/material";
import "../Post/Post.css";

export const ResVideos = () => {
  return (
    <Box
      className="bulletins-main-cont"
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 5,
      }}
    >
      <Box
        className="res-videos-url-cont"
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          alignItems: "center",
        }}
      >
        <Box className="res-video-url-box" sx={{ width: "30%" }}>
          <FormControl sx={{ width: "100%" }}>
            <OutlinedInput
              placeholder="Please enter youtube video url"
              sx={{ height: "45px" }}
            />
          </FormControl>
        </Box>{" "}
        <Box className="res-video-name-box" sx={{ width: "30%" }}>
          <FormControl sx={{ width: "100%" }}>
            <OutlinedInput
              placeholder="Please enter video name"
              sx={{ height: "45px" }}
            />
          </FormControl>
        </Box>{" "}
        <Box className="res-video-desc-box" sx={{ width: "30%" }}>
          <FormControl sx={{ width: "100%" }}>
            <OutlinedInput
              placeholder="Please enter video description"
              sx={{ height: "45px" }}
            />
          </FormControl>
        </Box>
        <Box className="res-video-button-box" sx={{ width: "17%" }}>
          <Button
            variant="contained"
            color="error"
            sx={{ width: "120px", textTransform: "none" }}
          >
            Add Video
          </Button>
        </Box>
      </Box>

      <Box
        className="res-videos-cont"
        sx={{ display: "flex", flexDirection: "column", gap:5 }}
      >
        <Box className="video-title-box" sx={{}}>
          <Typography
            className="video-title-typo"
            sx={{
              fontSize: "18px",
              fontWeight: 500,
              fontFamily: "DM Sans",
              textDecoration: "underline",
              textDecorationColor: "#E71B2C",
              textUnderlineOffset: "10px",
              textDecorationThickness: "3.5px",
            }}
          >
            PWR Steer- Gear Box
          </Typography>
        </Box>
        <Box
          className="video-play-box"
          sx={{
            display: "flex",
            flexDirection: "row",
          justifyContent:'center'
          }}
        >
          <Box
            className="res-activeVideoMain"
            sx={{
              width: "50%",
              height: "auto",
              borderRadius: "13px",
            }}
          >
            <iframe
              className="res-iframe"
              width="100%"
              height="300px"
              style={{ borderRadius: "12px" }}
              src="https://www.youtube.com/embed/olzqADHAeK0"
              alt="video"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Box>
        </Box>
        <Box
          className="video-edit-delete-box"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Button
            className="video-edit-btn"
            variant="contained"
            color="error"
            sx={{ width: "70px", borderRadius: "17px", textTransform: "none" }}
          >
            Edit
          </Button>
          <Button
            className="video-edit-btn"
            variant="contained"
            color="error"
            sx={{ width: "90px", borderRadius: "17px", textTransform: "none" }}
          >
            Delete
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
