import routes from "../../AppRoutes/routes.json";
import { CommonSetupActions } from "../../Slices/Commonslice";
import { getProductAppData } from "../ProductPages/ProductDisplay/GearBoxAction";
import { ApiLink } from "../Utils/Apilink";
// import {showLoader, hideLoader} from "../../Slices/Commonslice";

export const searchProduct = (
  selectedProduct,
  navigate,
  setSearcheddata,
  setLoading,
  onSearchComplete,
  setOpenModal
) => {
  return (dispatch, getState) => {
    // dispatch(CommonSetupActions.showLoader());
    try {
      if (setOpenModal) {
        setOpenModal(true);
      }
      dispatch(CommonSetupActions.setProductlistMessage(false));
      dispatch(CommonSetupActions.setProductList());
      dispatch(CommonSetupActions.setSelectedPart({}));
      dispatch(CommonSetupActions.setQuallifierCollection({}));
      dispatch(CommonSetupActions.setSubModel({}));
      dispatch(CommonSetupActions.setProductList({}));
      dispatch(CommonSetupActions.setRadioValue(null));
      dispatch(CommonSetupActions.setCurrentSelectedYear(null));
      dispatch(CommonSetupActions.setCurrentSelectedEngine(""));
      dispatch(CommonSetupActions.setCurrentSelectedMake(""));
      dispatch(CommonSetupActions.setCurrentSelectedModel(""));
      dispatch(CommonSetupActions.setCurrentSelectedSubModelValue(null));

      const product = getState().CommonStore.selectedProduct;

      // const baseVehicleID=
      setLoading(true);
      if (
        selectedProduct.type === "Applications" ||
        selectedProduct.type === "VIN"
      ) {
        let baseVehicleID =
          selectedProduct.type === "VIN"
            ? selectedProduct.value.split("!!")[0]
            : selectedProduct.value.split("===")[0];
        let engineID =
          selectedProduct.type === "VIN"
            ? selectedProduct.value.split("!!")[1]
            : selectedProduct.id.split("!")[3];
        fetch(
          `${ApiLink}qualifierCollection/${baseVehicleID}/${engineID}/noqualifier/0/0`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
          }
        )
          .then((response) => {
            return response.json();
          })
          .then((res) => {
            setLoading(false);
            if (res === "fail") {
              sessionStorage.clear();
              // navigate(routes.Ecatlog);
              dispatch(
                getProductList(
                  ApiLink,
                  baseVehicleID,
                  engineID,
                  "noqualifier",
                  "noqualifier",
                  onSearchComplete,
                  setOpenModal,
                  navigate
                )
              );
            } else {
              if (res.values.length === 1) {
                fetch(
                  `${ApiLink}qualifierCollection/${baseVehicleID}/${engineID}/${res.values[0].id}/0/0`,
                  {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json; charset=utf-8",
                    },
                  }
                )
                  .then((response12) => {
                    return response12.json();
                  })
                  .then((res1) => {

                    if (res1 === "fail") {
                      dispatch(
                        getProductList(
                          ApiLink,
                          baseVehicleID,
                          engineID,
                          "noqualifier",
                          "noqualifier",
                          onSearchComplete,
                          setOpenModal,
                          navigate
                        )
                      );
                    } else {
                      dispatch(CommonSetupActions.setSubModel(res1));
                      if (setOpenModal) {
                        setOpenModal(false);
                      }
                    }

                   
                  });
              }
              dispatch(CommonSetupActions.setQuallifierCollection(res));
              sessionStorage.clear();
           
              if (setOpenModal) setOpenModal(false);
            }
            // setSearcheddata([]);
          });
      } else {
        fetch(`${ApiLink}premiumCollection/${selectedProduct.label}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        })
          .then((responsee) => {
            return responsee.json();
          })
          .then((ress) => {
            setLoading(false);
            dispatch(CommonSetupActions.setSelectedPart(ress[0]));
            dispatch(
              getProductAppData(
                selectedProduct.label,
                navigate,
                selectedProduct,
                ress[0].ymm,
                setOpenModal
              )
            );
          });
      }
    } catch (err) {
    } finally {
      // dispatch(CommonSetupActions.hideLoader());
    }
  };
};

export const getProductList = (
  link,
  baseVehicleID,
  engineID,
  qualifier_id,
  qualifier_name,
  onSearchComplete,
  setOpenModal,
  navigate
) => {
  return (dispatch, getState) => {
    if (setOpenModal) setOpenModal(true);
    try {
      dispatch(CommonSetupActions.setProductlistMessage(false));

      fetch(
        `${link}websiteProductList/${baseVehicleID}/${engineID}/${qualifier_id}/${qualifier_name}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      )
        .then((response) => {
          // onSearchComplete();

          return response.json();
        })
        .then((res) => {
          if (setOpenModal) {
            setOpenModal(false);
          }
          dispatch(CommonSetupActions.setProductlistMessage(true));
          dispatch(CommonSetupActions.setProductList(res.partTypeArray));
          navigate(routes.Ecatlog, {
            state: { baseVehicleID: baseVehicleID, engineID: engineID },
          });
          if (onSearchComplete) {
            onSearchComplete();
          }
        })
        .catch((err) => console.log(err));
    } catch (err) {}
  };
};
